import { useTheme } from "@emotion/react";
import { Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { CustomButton, CustomInput, Header } from "../components";
import FooterHome from "../components/FooterHome";
import { useLoader } from "../context/loaderContext";
import "../index.css";
import { contactUs } from "../services/contactUsService";
import { quoteProjectBackground } from "../staticData/images";
import PROJECTS from "../staticData/quoteProjects.json";
const Projects = () => {
  useEffect(() => {
    // window.scrollTo(0, 0);
  }, []);
  const { palette, ...theme } = useTheme();
  return (
    <Box
      sx={{
        minHeight: "100vh",
      }}
    >
      <Header hamburger={true} />
      <HeroSlider />
      <Box
        sx={{
          zIndex: 100,
          position: "relative",
          marginTop: "-140px",
          width: "80%",
          marginInline: "auto",
          [theme.breakpoints.down("sm")]: {
            width: "90%",
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: "400",
            fontFamily: "var(--font-i_r)",
            fontSize: "22px",
            textAlign: "left",
            color: palette.font.default,
            [theme.breakpoints.down("sm")]: {
              fontSize: "14px",
            },
          }}
        >
          Lorem ipsum dolor sit amet consectetur. Nam eget imperdiet elementum
          neque sit sociis tortor. Magna ultricies est et commodo. Quam vitae
          lacinia aliquam tortor sit rhoncus. Nulla ac convallis aliquet
          posuere. Habitasse elit id aliquam sodales in sed egestas. Porta et
          volutpat felis nulla convallis porttitor eu. Tellus ipsum iaculis
          quisque lobortis diam eu convallis. Bibendum arcu est iaculis est quis
          vel.
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          marginBlock: "150px",
          width: "80%",
          paddingInline: "25px",
          paddingBlock: "35px",
          borderRadius: "10px",
          marginInline: "auto",
          backgroundImage: `url(${quoteProjectBackground})`,
          [theme.breakpoints.down("sm")]: {
            width: "90%",
            marginBlock: "20px",
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: "700",
            fontFamily: "var(--font-f_r)",
            fontSize: "30px",
            textTransform: "capitalize",
            color: palette.font.primary,
            [theme.breakpoints.down("sm")]: {
              fontSize: "16px",
            },
          }}
        >
          Choose Project:
        </Typography>
        <Typography
          sx={{
            fontWeight: "400",
            fontFamily: "var(--font-i_r)",
            fontSize: "22px",
            textAlign: "left",
            marginTop: "2rem",
            color: palette.font.default,
            [theme.breakpoints.down("sm")]: {
              fontSize: "12px",
            },
          }}
        >
          Lorem ipsum dolor sit amet consectetur. Nam eget imperdiet elementum
          neque sit sociis tortor. Magna ultricies est et commodo. Quam vitae
          lacinia aliquam tortor sit rhoncus. Nulla ac convallis aliquet
          posuere.
        </Typography>
        <Box
          sx={{
            marginTop: "5rem",
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            [theme.breakpoints.down("sm")]: {
              flexWrap: "wrap",
            },
          }}
        >
          {PROJECTS.map((proj) => {
            return <ProjectButton project={proj} />;
          })}
        </Box>
      </Box>
      <ContactForm />

      {/* <FooterHome /> */}
    </Box>
  );
};

export default Projects;

const ContactForm = () => {
  const { palette, ...theme } = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { showLoader, hideLoader } = useLoader();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
  });
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "Name is required";
    }
    if (!formData.subject) {
      newErrors.subject = "Subject is required";
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)
    ) {
      newErrors.email = "Invalid email address";
    }

    setErrors(newErrors);
    return newErrors;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      showLoader();
      const res = await contactUs(formData);
      if (res?.contact) {
        enqueueSnackbar("Form Submitted", {
          variant: "success",
        });
        setFormData({
          name: "",
          email: "",
          subject: "",
        });
      }
      hideLoader();
    }
  };

  return (
    <Box
      sx={{
        marginBlock: "150px",
        width: "70%",
        paddingInline: "25px",
        paddingBlock: "35px",
        borderRadius: "10px",
        marginInline: "auto",
        [theme.breakpoints.down("sm")]: {
          width: "90%",
          marginBlock: "20px",
        },
        background: `linear-gradient(180deg, rgba(234, 211, 12, 0.16) 0%, rgba(234, 211, 12, 0.00) 100%)`,
      }}
    >
      <Typography
        sx={{
          fontWeight: "700",
          fontFamily: "var(--font-f_r)",
          fontSize: "30px",
          textTransform: "capitalize",
          color: palette.font.primary,
          [theme.breakpoints.down("sm")]: {
            fontSize: "20px",
          },
        }}
      >
        contact:
      </Typography>
      <Typography
        sx={{
          marginTop: "40px",
          fontWeight: "400",
          fontFamily: "var(--font-i_r)",
          fontSize: "22px",

          textTransform: "capitalize",
          color: palette.font.default,
          [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
          },
        }}
      >
        Reach out and we’ll get in touch.
      </Typography>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          marginTop: "40px",
          justifyContent: "space-between",
          [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
          },
        }}
      >
        <CustomInput
          width={onlySmallScreen ? "100%" : null}
          label={"Name"}
          value={formData.name}
          required={true}
          error={Boolean(errors.name)}
          helperText={errors.name}
          onChange={(e) => {
            setFormData({ ...formData, name: e.target.value });
          }}
          placeholder={"Enter name"}
        />
        <CustomInput
          width={onlySmallScreen ? "100%" : null}
          label={"Email"}
          required={true}
          value={formData.email}
          helperText={errors.email}
          error={Boolean(errors.email)}
          onChange={(e) => {
            setFormData({ ...formData, email: e.target.value });
          }}
          placeholder={"Enter Email"}
        />
      </Box>
      <Box
        sx={{
          marginTop: "20px",
        }}
      >
        <CustomInput
          label={"Subject"}
          placeholder={"Enter Subject"}
          required={true}
          helperText={errors.subject}
          error={Boolean(errors.subject)}
          value={formData.subject}
          onChange={(e) => {
            setFormData({ ...formData, subject: e.target.value });
          }}
          width="100%"
          multiline={true}
        />
      </Box>
      <Box
        sx={{
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          marginBlock: "20px",
        }}
      >
        <CustomButton
          onClick={handleSubmit}
          sx={{
            marginInline: "20px",
            background:
              "linear-gradient(95deg, #B19513 -2.12%, #BF5C00 116.91%)",
          }}
          title="SEND"
        />
      </Box>
    </Box>
  );
};
const HeroSlider = () => {
  var settings = {
    adaptiveHeight: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    style: {
      padding: "0px !important",
      margin: "0px !important",
    },
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <Slider className="slick-slidd" {...settings}>
      {PROJECTS.map((project) => {
        return (
          <Box
            sx={{
              // minHeight: "80vh",
              // placeItems: "center",
              // width: "100%",

              background:
                "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(55,55,55,0.6811099439775911) 8%, rgba(99,99,99,0) 23%, rgba(255,255,255,0) 50%, rgba(119,119,119,0) 77%, rgba(51,51,51,0.7455357142857143) 92%, rgba(0,0,0,1) 100%, rgba(0,212,255,1) 100%)",

              "& img": {
                position: "relative",
                // left: "0",
                // top: "0",
                height: "90vh",
                width: "100vw",
                objectFit: "cover",
                zIndex: -10000,
              },
            }}
          >
            <img src={project.image} />
          </Box>
        );
      })}
    </Slider>
  );
};

const ProjectButton = ({ project }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Box
      sx={{
        cursor: "pointer",
      }}
      onClick={() => navigate(`/project/${project.slug}`)}
    >
      <Box
        sx={{
          width: "80px",
          height: "28px",
          border: "1px solid #94E161",
          borderRadius: "8px",
          alignItems: "center",
          display: "flex",
          position: "absolute",
          marginTop: "-12px",
          marginLeft: "10vw",
          justifyContent: "center",
          backgroundColor: "#000",
          [theme.breakpoints.down("lg")]: {
            marginLeft: "7vw",
            marginTop: "-8px",
            width: "60px",
            height: "20px",
            borderRadius: "3px",
          },
          [theme.breakpoints.down("sm")]: {
            marginLeft: "20vw",
            marginTop: "-8px",
            width: "45px",
            height: "15px",
            borderRadius: "3px",
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: "700",
            fontFamily: "var(--font-f_r)",
            fontSize: "12px",
            textTransform: "capitalize",
            textAlign: "center",
            color: project.type === "comming soon" ? "#F85B03" : "#94E161",
            [theme.breakpoints.down("lg")]: {
              fontSize: "10px",
            },
            [theme.breakpoints.down("sm")]: {
              fontSize: "7px",
            },
          }}
        >
          {project.type}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "15vw",
          border: "1px solid #94E161",
          borderRadius: "10px",
          height: "75px",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          background:
            "linear-gradient(150deg, rgba(92, 32, 32, 0.92) 0%, rgba(79, 0, 0, 0.08) 100%)",
          [theme.breakpoints.down("sm")]: {
            width: "90%",
            marginBottom: "25px",
            maxWidth: "180px",
            minWidth: "130px",
            height: "45px",
            borderRadius: "8px",
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: "700",
            fontFamily: "var(--font-f_r)",
            fontSize: "22px",
            textTransform: "capitalize",
            textAlign: "center",
            color: "#fff",
            [theme.breakpoints.down("lg")]: {
              fontSize: "16px",
            },
            [theme.breakpoints.down("sm")]: {
              fontSize: "12px",
            },
          }}
        >
          {project.name}
        </Typography>
      </Box>
    </Box>
  );
};
