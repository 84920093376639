import * as React from "react";
const Hamburger = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={46}
    height={32}
    fill="none"
    style={{
      "&:hover": {
        backgroundColor: "red",
      },
    }}
    {...props}
  >
    <path
      stroke="#94E161"
      strokeWidth={2}
      d="M8.6 1h37.2M0 16h37.2M8.6 31h37.2"
    />
  </svg>
);
export default Hamburger;
