import React from "react";
import { Button, CircularProgress, Typography } from "@mui/material";
import styled from "@emotion/styled";

const Root = styled("div")(({ theme, width, full, logo }) => {
  return {
    alignItems: "center",
    paddingInline: "20px",
    [theme.breakpoints.down("sm")]: {
      paddingInline: "5px",
    },
    "& .MuiButton-root": {
      borderRadius: "10px",
      fontWeight: "500",
      width: full ? "100%" : width ? width : "127px",
      textTransform: "capitalize",
      fontFamily: "var(--font-p_sb)",
      justifyContent: logo ? "space-around" : "center",
      color: theme.palette.grey[200],
      [theme.breakpoints.down("sm")]: {
        width: full ? "100%" : width ? width : "90px",
      },
      "&:hover": {
        backgroundColor: theme.palette.primary[100],
        borderColor: theme.palette.primary[100],
      },
    },
    "& .MuiButton-sizeLarge": {
      fontSize: "18px",
      padding: "12px 35px",
      [theme.breakpoints.up("md")]: {
        padding: "12px 90px",
        fontSize: "20px",
      },
    },
    "& .MuiButton-sizeSmall": {
      fontSize: "14px",
      [theme.breakpoints.up("sm")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "16px",
      },
    },
    "& .MuiButton-outlined": {
      background: "transparent",
      borderColor: theme.palette.secondary[300],
      color: theme.palette.secondary[900],
    },
    "& .MuiTypography-root": {
      fontWeight: "700",
      textTransform: "capitalize",
      fontFamily: "var(--font-f_r)",
      fontSize: "25px",
      color: theme.palette.grey[200],
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
  };
});

const CustomButton = ({
  title,
  onClick,
  className,
  type,
  size,
  full,
  outline,
  logoPosition = "right",
  logo,
  loading,
  width,
  style,
  ...rest
}) => {
  return (
    <Root
      style={{}}
      full={full}
      width={width}
      className={className}
      logo={logo}
    >
      <Button
        variant={`${outline ? "outlined" : "contained"}`}
        type={type}
        // size={size}
        onClick={onClick}
        {...rest}
      >
        {logo && logoPosition === "left" && logo}
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <Typography sx={{ fontSize: "1vw" }}>{title}</Typography>
        )}
        {logo && logoPosition === "right" && logo}
      </Button>
    </Root>
  );
};

export default CustomButton;
