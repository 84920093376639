import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  animations: [],
};

const ModelSlice = createSlice({
  name: "3dModelSlice",
  initialState,
  reducers: {
    setAnimations: (state, { payload }) => {
      debugger;
      state.animations = payload;
    },
  },
});

export const { setAnimations } = ModelSlice.actions;

const { reducer } = ModelSlice;
export default reducer;

export const setModelAnimations = (body) => {
  return (dispatch) => {
    dispatch(setAnimations(body));
  };
};
