const CONTAINER = "https://ishrostorage.blob.core.windows.net/mutant-3d/";

export const quoteProjectBackground =
  "https://ishrostorage.blob.core.windows.net/container-3d/Rectangle%20251.png";
export const footerLogo1 = CONTAINER + "footer-logo-1.png";
export const footerLogo2 = CONTAINER + "footer-logo-2.png";
export const footerLogo3 = CONTAINER + "footer-logo-3.png";
export const homeBanner = CONTAINER + "home-banner.png";

export const homeFooterInceptive =
  "https://ishrostorage.blob.core.windows.net/container-3d/MAYC%20banner.png";
export const apeCoinOnlyLogo =
  "https://ishrostorage.blob.core.windows.net/container-3d/footerLogo2.png";
export const Logo =
  "https://ishrostorage.blob.core.windows.net/container-3d/Logo.png";

// Dimension Template Panel

export const dimensionTC =
  "https://ishrostorage.blob.core.windows.net/container-3d/dimension-tc.png";
export const dimensionTP =
  "https://ishrostorage.blob.core.windows.net/container-3d/dimension-tp.png";
export const dimensionFD =
  "https://ishrostorage.blob.core.windows.net/container-3d/dimension-fd.png";
export const dimensionFP =
  "https://ishrostorage.blob.core.windows.net/container-3d/dimension-fp.png";
export const dimensionIP =
  "https://ishrostorage.blob.core.windows.net/container-3d/dimension-ip.png";
export const dimensionIS =
  "https://ishrostorage.blob.core.windows.net/container-3d/dimension-is.png";

// Crypto Panel Coin

export const coin1 =
  "https://ishrostorage.blob.core.windows.net/container-3d/coin1.svg";
export const coin2 =
  "https://ishrostorage.blob.core.windows.net/container-3d/coin2.svg";
export const coin3 =
  "https://ishrostorage.blob.core.windows.net/container-3d/coin3.svg";
export const coin4 =
  "https://ishrostorage.blob.core.windows.net/container-3d/coin4.svg";

// Sticker Panel

export const sticker1 =
  "https://ishrostorage.blob.core.windows.net/container-3d/sticker1.svg";
export const sticker2 =
  "https://ishrostorage.blob.core.windows.net/container-3d/sticker2.svg";
export const sticker3 =
  "https://ishrostorage.blob.core.windows.net/container-3d/sticker3.svg";
export const sticker4 =
  "https://ishrostorage.blob.core.windows.net/container-3d/sticker4.svg";
export const sticker5 =
  "https://ishrostorage.blob.core.windows.net/container-3d/sticker5.svg";
export const sticker6 =
  "https://ishrostorage.blob.core.windows.net/container-3d/sticker6.svg";
export const sticker7 =
  "https://ishrostorage.blob.core.windows.net/container-3d/sticker7.svg";
export const sticker8 =
  "https://ishrostorage.blob.core.windows.net/container-3d/sticker8.svg";
export const sticker9 =
  "https://ishrostorage.blob.core.windows.net/container-3d/sticker9.svg";

// NFT page icons

export const library =
  "https://ishrostorage.blob.core.windows.net/mutant-3d/library.svg";
export const file =
  "https://ishrostorage.blob.core.windows.net/mutant-3d/file.svg";
export const image =
  "https://ishrostorage.blob.core.windows.net/mutant-3d/image.svg";
export const still =
  "https://ishrostorage.blob.core.windows.net/mutant-3d/still-pose.svg";
export const animation =
  "https://ishrostorage.blob.core.windows.net/mutant-3d/animation.svg";
