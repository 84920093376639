import instance from "./config";

class Axios {
  post(url, body, headers = {}) {
    return instance.post(url, body, headers);
  }
  get(url) {
    return instance.get(url);
  }
  put(url, body) {
    return instance.put(url, body);
  }
  delete(url) {
    return instance.delete(url);
  }
}

export default new Axios();
