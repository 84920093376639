import { Box, Button, CircularProgress, Typography } from "@mui/material";
import * as React from "react";
import Header from "../../../Header";
import { useTheme } from "@emotion/react";
import DashboardLayout from "../../layout";
import ApeUnity from "../../../3dModels/models/ApeUnity";
import BackButton from "../../../BackButton";
import { useNavigate, useParams } from "react-router-dom";
import FooterHome from "../../../FooterHome";

function StillPose({ style = {}, type = "3d" }) {
  const { palette, ...theme } = useTheme();
  const navigate = useNavigate();
  const UnityUI = () => {
    const { id } = useParams();
    const [envloaded, setEnvloaded] = React.useState(true);
    const [localEnvArray, setLocalEnvArray] = React.useState(["Studio"]);
    const [mode, setMode] = React.useState("animate");
    const [modelJson, setModelJson] = React.useState({});
    const getModelJson = async () => {
      const response = await fetch(
        `https://storage.googleapis.com/mutant-honds/modelFiles.json`
      );
      const json = await response.json();
      const selectedJson = json.find((e) => Object.keys(e)[0] === id);
      const allTraits = selectedJson[id];
      let finalTrait = {};
      allTraits?.attributes?.map((trait) => {
        const key = trait["trait_type"]?.replaceAll(" ", "_");
        if (trait["trait_type"] === "Background") {
        } else {
          finalTrait = {
            ...finalTrait,
            [key]: trait?.value === "0" ? "Empty" : trait?.value,
          };
        }
      });
      setModelJson({ ...allTraits, attributes: finalTrait } || {});
    };
    React.useEffect(() => {
      getModelJson();
    }, []);
    const [buttonType, setButtonType] = React.useState([
      {
        name: "Pose",
        selected: true,
        items: [
          {
            style: "tPose",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/tPose.png",
            selected: true,
          },
          {
            style: "posewalkForward",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/animate_4.png",
            selected: true,
          },
          {
            style: "posestretch",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/posestretch.png",
            selected: false,
          },
          {
            style: "poselookRight",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/poselookRight.png",
            selected: false,
          },

          {
            style: "posestretchBack",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/posestretchBack.png",
            selected: false,
          },
          {
            style: "posesit",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/posesit.png",
            selected: false,
          },
          {
            style: "posebasking",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/posebasking.png",
            selected: false,
          },
          {
            style: "posewalkRight",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/posewalkRight.png",
            selected: false,
          },
          {
            style: "posewalkForward",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/posewalkForward.png",
            selected: false,
          },
          {
            style: "LookLeftPose",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/LookLeftPose.png",
            selected: false,
          },
        ],
      },
      {
        name: "Environment",
        selected: false,
        items: [
          {
            style: "Studio",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/env/Studio.png",
            selected: true,
          },
          {
            style: "Docks",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/env/Docks.png",
            selected: false,
          },
          {
            style: "Hideout",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/env/Hideout.png",
            selected: false,
          },

          {
            style: "Jungle",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/env/Jungle.png",
            selected: false,
          },
          {
            style: "Lab",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/env/Lab.png",
            selected: false,
          },
          {
            style: "Shipwreck",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/env/Shipwreck.png",
            selected: false,
          },
          {
            style: "HoundLair",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/env/HoundLair.png",
            selected: false,
          },
          {
            style: "CaveEntrance",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/env/CaveEntrance.png",
            selected: false,
          },
          {
            style: "TempleRoom",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/env/TempleRoom.png",
            selected: false,
          },
          {
            style: "Warehouse",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/env/Warehouse.png",
            selected: false,
          },
        ],
      },
    ]);

    const RenderItems = ({ items }) => {
      return (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            height: "80%",
            overflowY: "scroll",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          {items.map((item) => {
            return (
              <Box
                onClick={() => {
                  const updated = items.map((it) => {
                    if (it.style === item.style) {
                      return {
                        ...it,
                        selected: true,
                      };
                    }
                    return {
                      ...it,
                      selected: false,
                    };
                  });
                  let arr = buttonType;
                  if (mode === "animate") {
                    arr[0].items = updated;
                  } else {
                    arr[1].items = updated;
                  }
                  if (!envloaded) return; // Ensure envloaded is true before updating state
                  setButtonType([...arr]);
                }}
                sx={{
                  cursor: "pointer",
                  border: mode === "env" ? "" : "1px solid #474747",
                  padding: mode === "env" ? "" : "15px",
                  margin: "10px",
                  borderRadius: "15px",
                  position: "relative", // Add relative positioning to the box
                }}
              >
                {/* Render background image */}
                {mode === "env" && !localEnvArray.includes(item.style) && (
                  <div
                    style={{
                      position: "absolute",
                      top: "5%",
                      right: "6%",
                      background: "#2A1B3EF0",
                      width: "17%",
                      display: "flex",
                      borderRadius: "5px",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "3px",
                      height: "15%",
                    }}
                  >
                    {item.selected && !envloaded ? (
                      <CircularProgress color="secondary" size={18} />
                    ) : (
                      <img
                        style={{
                          width: "70%",
                          height: "70%",
                        }}
                        src="/downloadLogo.png"
                        alt="download-img"
                      />
                    )}
                  </div>
                )}
                {/* Render image inside the box */}
                <img
                  style={{
                    width: mode === "env" ? "7vw" : "6vw",
                    borderRadius: "10px",
                  }}
                  src={item.image}
                  alt={item.alt}
                />
              </Box>
            );
          })}
        </Box>
      );
    };
    return (
      <Box
        sx={{
          width: "100%",
          height: "90%",
          // padding: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {modelJson?.name && (
          <ApeUnity
            animation={buttonType[0].items.find((e) => e.selected)?.style}
            environment={buttonType[1].items.find((e) => e.selected)?.style}
            jsonObject={modelJson}
            envloaded={envloaded}
            localEnvArray={localEnvArray}
            setLocalEnvArray={setLocalEnvArray}
            setEnvloaded={setEnvloaded}
          />
        )}
        <Box
          sx={{
            width: "30%",
            position: "absolute",
            top: 30,
            right: 30,
            height: "90%",
            borderRadius: "1vw",
            display: "flex",
            flexDirection: "column",
            paddingInline: "20px",
            // overflowY: "scroll",
            border: "2px solid #53703F",
            alignItems: "center",
            // justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              paddingBlock: "20px",
              justifyContent: "space-around",
            }}
          >
            {buttonType.map((button, i) => {
              return (
                <ProjectButton project={button} mode={mode} setMode={setMode} />
              );
            })}
          </Box>
          <RenderItems
            items={
              mode === "animate" ? buttonType[0].items : buttonType[1].items
            }
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        padding: 3,
        height: "100%",

        overflow: "hidden",
      }}
    >
      <Header />
      <Box
        sx={{
          border: "1px solid #2C3B22",
          height: "77vh",
          position: "absolute",
          borderRadius: "0.5vw",
          marginTop: "10vh",
          // bottom: "20px",
          width: "96%",
          overflow: "hidden",
        }}
      >
        <div style={{ position: "absolute", padding: "20px" }}>
          <BackButton
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
        <UnityUI />
      </Box>
      {/* <FooterHome /> */}
    </Box>
  );
}

export default StillPose;

const ProjectButton = ({ project, mode, setMode }) => {
  const theme = useTheme();
  const isSelected =
    project.name === "Environment"
      ? project.name === "Environment" && mode === "env"
      : project.name === "Animation" && mode === "animate";
  return (
    <Box
      sx={{
        cursor: "pointer",
      }}
      onClick={() => {
        setMode(project.name === "Environment" ? "env" : "animate");
      }}
    >
      <Box
        sx={{
          width: "12vw",
          border: "1px solid #7D1BEB",
          borderRadius: "10px",
          height: "3.5vw",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          background: isSelected ? "#7D1BEB" : "#301254",
          [theme.breakpoints.down("sm")]: {
            width: "90%",
            marginBottom: "25px",
            maxWidth: "180px",
            minWidth: "130px",
            height: "45px",
            borderRadius: "8px",
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: "700",
            fontFamily: "var(--font-f_r)",
            fontSize: "1vw",
            textTransform: "capitalize",
            textAlign: "center",
            color: "#fff",
            [theme.breakpoints.down("lg")]: {
              fontSize: "16px",
            },
            [theme.breakpoints.down("sm")]: {
              fontSize: "12px",
            },
          }}
        >
          {project.name}
        </Typography>
      </Box>
    </Box>
  );
};
