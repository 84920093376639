import { Box, Button, Typography } from "@mui/material";
import * as React from "react";
import Header from "../../Header";
import { useTheme } from "@emotion/react";
import DashboardLayout from "../layout";
import { coin1 } from "../../../staticData/images";
import { useNavigate } from "react-router-dom";
import { BiDownload } from "react-icons/bi";

function Merchandise({ style = {} }) {
  const { palette, ...theme } = useTheme();
  const [step, setStep] = React.useState(0);
  const navigate = useNavigate();
  const RenderRiggedItem = ({ name, img, showButton = false }) => {
    return (
      <Box>
        <Typography
          sx={{
            fontWeight: "700",
            fontFamily: "var(--font-f_r)",
            fontSize: "1.2vw",
            textAlign: "start",
            width: "100%",
            marginLeft: "16px",
            textTransform: "capitalize",
            color: "#fff",
            [theme.breakpoints.down("lg")]: {
              fontSize: "16px",
            },
            [theme.breakpoints.down("sm")]: {
              fontSize: "12px",
            },
          }}
        >
          {name}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Box
            sx={{
              cursor: "pointer",
              border: "1px solid #474747",
              padding: "15px",
              height: "20vh",
              width: "13vw",
              margin: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "15px",
            }}
          >
            <img style={{ width: "100%" }} src={img} />
            {showButton && (
              <Typography
                onClick={() => navigate("images")}
                sx={{
                  fontWeight: "700",
                  fontFamily: "var(--font-f_r)",
                  fontSize: "0.8vw",
                  textAlign: "end",
                  marginTop: "10px",
                  width: "100%",

                  textTransform: "capitalize",
                  color: "#94E161",
                  [theme.breakpoints.down("lg")]: {
                    fontSize: "16px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "12px",
                  },
                }}
              >
                Add Template
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    );
  };
  const Animations = () => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          padding: 3,
          display: "flex",
        }}
      >
        <RenderRiggedItem
          name="Animations"
          img="https://ishrostorage.blob.core.windows.net/mutant-3d/Houndinlab.png"
        />
      </Box>
    );
  };
  const StillPose = () => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          padding: 3,
          display: "flex",
        }}
      >
        <RenderRiggedItem
          name="Still Pose"
          img="https://ishrostorage.blob.core.windows.net/mutant-3d/Houndinlab.png"
          showButton={true}
        />
      </Box>
    );
  };
  const Apparel = () => {
    const [buttonType, setButtonType] = React.useState([
      {
        name: "Clothing",
        selected: true,
        image:
          "https://ishrostorage.blob.core.windows.net/container-3d/apparel.png",
        items: [
          {
            image:
              "https://ishrostorage.blob.core.windows.net/container-3d/cloth1.png",
          },
          {
            image:
              "https://ishrostorage.blob.core.windows.net/container-3d/cloth2.png",
          },
        ],
      },

      {
        name: "Sticker",
        selected: false,
        image:
          "https://ishrostorage.blob.core.windows.net/container-3d/merchandise_sticker_2.png",
        items: [
          {
            image:
              "https://ishrostorage.blob.core.windows.net/container-3d/merchandise_sticker_1.png",
          },
          {
            image:
              "https://ishrostorage.blob.core.windows.net/container-3d/merchandise_sticker_2.png",
          },
          {
            image:
              "https://ishrostorage.blob.core.windows.net/container-3d/merchandise_sticker_3.png",
          },
        ],
      },
    ]);

    const RenderItems = ({ items }) => {
      return (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          {items.map((item) => {
            return (
              <Box
                sx={{
                  cursor: "pointer",
                  border: "1px solid #474747",
                  padding: "15px",
                  margin: "10px",
                  borderRadius: "15px",
                }}
              >
                <img style={{ width: "6vw" }} src={item.image} />
              </Box>
            );
          })}
        </Box>
      );
    };
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          padding: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "70%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ width: "30vw" }}
            src={buttonType.find((type) => type.selected).image}
          />
          <Box
            sx={{
              width: "60%",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <BottomButton name="share" />
            <BottomButton name="Download" />
          </Box>
        </Box>
        <Box
          sx={{
            width: "30%",
            height: "100%",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            border: "1px solid #94E161",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              padding: "20px",
              justifyContent: "space-between",
            }}
          >
            {buttonType.map((button) => {
              return (
                <ProjectButton
                  project={button}
                  projects={buttonType}
                  setProject={setButtonType}
                />
              );
            })}
          </Box>
          <RenderItems items={buttonType.find((type) => type.selected).items} />
        </Box>
      </Box>
    );
  };
  const layoutOptions = [
    {
      name: "Animations",
      children: Animations(),
    },
    {
      name: "Still Pose",
      children: StillPose(),
    },
    {
      name: "images",
      navigate: "images",
      children: StillPose(),
    },
  ];
  return (
    <DashboardLayout layoutOptions={layoutOptions} title={"Merchandise"} />
  );
}

export default Merchandise;

const ProjectButton = ({ project, setProject, projects }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        cursor: "pointer",
      }}
      onClick={() => {
        const updated = projects.map((proj) => {
          if (proj.name === project.name) {
            return {
              ...proj,
              selected: true,
            };
          }
          return {
            ...proj,
            selected: false,
          };
        });
        setProject(updated);
      }}
    >
      <Box
        sx={{
          width: "10vw",
          border: "1px solid #94E161",
          borderRadius: "10px",
          height: "75px",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          background: project.selected
            ? "linear-gradient(150deg, rgba(92, 32, 32, 0.92) 0%, rgba(79, 0, 0, 0.08) 100%)"
            : "linear-gradient(150deg, rgba(92, 32, 32, 0.32) 0%, rgba(79, 0, 0, 0.08) 100%)",
          [theme.breakpoints.down("sm")]: {
            width: "90%",
            marginBottom: "25px",
            maxWidth: "180px",
            minWidth: "130px",
            height: "45px",
            borderRadius: "8px",
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: "700",
            fontFamily: "var(--font-f_r)",
            fontSize: "1vw",
            textTransform: "capitalize",
            textAlign: "center",
            color: "#fff",
            [theme.breakpoints.down("lg")]: {
              fontSize: "16px",
            },
            [theme.breakpoints.down("sm")]: {
              fontSize: "12px",
            },
          }}
        >
          {project.name}
        </Typography>
      </Box>
    </Box>
  );
};

const BottomButton = ({ name }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        cursor: "pointer",
      }}
      // onClick={() => {
      //   const updated = projects.map((proj) => {
      //     if (proj.name === project.name) {
      //       return {
      //         ...proj,
      //         selected: true,
      //       };
      //     }
      //     return {
      //       ...proj,
      //       selected: false,
      //     };
      //   });
      //   setProject(updated);
      // }}
    >
      <Box
        sx={{
          width: "13vw",
          border: "1px solid #94E161",
          borderRadius: "10px",
          height: "70px",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          background:
            "linear-gradient(150deg, rgba(92, 32, 32, 0.92) 0%, rgba(79, 0, 0, 0.08) 100%)",
          [theme.breakpoints.down("sm")]: {
            width: "90%",
            marginBottom: "25px",
            maxWidth: "180px",
            minWidth: "130px",
            height: "45px",
            borderRadius: "8px",
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: "700",
            fontFamily: "var(--font-f_r)",
            fontSize: "1vw",
            textTransform: "capitalize",
            textAlign: "center",
            color: "#fff",
            [theme.breakpoints.down("lg")]: {
              fontSize: "16px",
            },
            [theme.breakpoints.down("sm")]: {
              fontSize: "12px",
            },
          }}
        >
          {name}
        </Typography>
      </Box>
    </Box>
  );
};
