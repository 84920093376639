import { Header } from "../../components";
import { useNavigate } from "react-router-dom";

import Footer from "../../components/FooterHome";
import { BackButton } from "../../components";
import "./features.css";

const Features = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="header">
        <Header />
      </div>
      <div className="body">
        <div className="back-button">
          <BackButton backgroundColor="#94E161" onClick={() => navigate(-1)} />
        </div>
        <div className="first-title">
          <span className="first">3D </span>{" "}
          <span className="second">animation</span>
        </div>
        <div className="section-one">
          <div className="description">
            <p>
              Lorem ipsum dolor sit amet consectetur. Tincidunt odio cras sed
              purus. Morbi congue mollis nunc donec odio. Eleifend diam
              malesuada in elit dictum. Mattis massa sagittis consectetur et
              cursus sodales erat turpis tristique. At a maecenas lectus mauris
              viverra. Elit vestibulum nec ac ac dui donec fusce nullam aliquet.
              Ipsum dui ut enim elit arcu hac tortor. Id posuere urna sem tortor
              sed neque eros.
            </p>
          </div>
          <div className="section-one-img">
            <img
              width="60%"
              height="60%"
              src="https://ishrostorage.blob.core.windows.net/mutant-3d/first.webp"
              alt=""
              srcSet=""
            />
          </div>
        </div>

        <div className="section-two">
          <div className="second-title">
            <span className="first">Rigged </span>{" "}
            <span className="second">model</span>
          </div>
          <div className="description">
            <p>
              Lorem ipsum dolor sit amet consectetur. Tincidunt odio cras sed
              purus. Morbi congue mollis nunc donec odio. Eleifend diam
              malesuada in elit dictum. Mattis massa sagittis consectetur et
              cursus sodales erat turpis tristique. At a maecenas lectus mauris
              viverra. Elit vestibulum nec ac ac dui donec fusce nullam aliquet.
              Ipsum dui ut enim elit arcu hac tortor. Id posuere urna sem tortor
              sed neque eros.
            </p>
          </div>
          <div className="section-two-img">
            <img
              width="60%"
              height="60%"
              src="https://ishrostorage.blob.core.windows.net/mutant-3d/third.webp"
              alt=""
              srcSet=""
            />
          </div>
        </div>
        <div className="section-third">
          <div className="third-title">
            <span className="first">use your models on </span>{" "}
            <span className="second">socials</span>
          </div>
          <div className="description">
            <p>
              Lorem ipsum dolor sit amet consectetur. Tincidunt odio cras sed
              purus. Morbi congue mollis nunc donec odio. Eleifend diam
              malesuada in elit dictum. Mattis massa sagittis consectetur et
              cursus sodales erat turpis tristique. At a maecenas lectus mauris
              viverra. Elit vestibulum nec ac ac dui donec fusce nullam aliquet.
              Ipsum dui ut enim elit arcu hac tortor. Id posuere urna sem tortor
              sed neque eros.
            </p>
          </div>
          <div className="section-third-img">
            <img
              width="60%"
              height="60%"
              src="https://ishrostorage.blob.core.windows.net/mutant-3d/second.webp"
              alt=""
              srcSet=""
            />
          </div>
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default Features;
