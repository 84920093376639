import { useTheme } from "@emotion/react";
import {
  Box,
  LinearProgress,
  Typography,
  linearProgressClasses,
  styled,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Unity, useUnityContext } from "react-unity-webgl";
import { htmlToImageConverter, startRecording } from "../../../utils";
import ShareButton from "../../ShareButton";
import "./ModelLoader.css";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "20px",
  border: "5px solid #58803d",
  width: "40%",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 3,
    backgroundColor: "#94E161",
  },
}));

function ApeUnity({
  id = "canvas",
  mode = "3d",
  animation = "",
  environment = "",
  jsonObject = {},
  envloaded,
  setEnvloaded,
  localEnvArray,
  setLocalEnvArray,
}) {
  const [modelLoaded, setModelLoaded] = useState(false);
  const shareUrl =
    "https://ishrostorage.blob.core.windows.net/mutant-3d/Mutant-video.mp4";
  const title = "Mutant Hound";
  const [openShare, setOpenShare] = useState(false);
  const [openDowload, setOpenDownload] = useState(false);
  const [recording, setRecording] = useState(false);
  const canvasRef = useRef(null);
  function handleCacheControl(url) {
    if (url.match(/\.data/) || url.match(/\.bundle/)) {
      return "must-revalidate";
    }
    if (url.match(/\.data/)) {
      return "immutable";
    }
    if (url.match(/\.mp4/) || url.match(/\.wav/)) {
      return "immutable";
    }
    return "no-store";
  }

  const unity = useUnityContext({
    dataUrl:
      "https://storage.googleapis.com/3d-container/mutant-3d/build/webgl.data",
    codeUrl:
      "https://storage.googleapis.com/3d-container/mutant-3d/build/build.wasm",
    loaderUrl:
      "https://storage.googleapis.com/3d-container/mutant-3d/build/MutantHound_Build.loader.js",
    frameworkUrl:
      "https://storage.googleapis.com/3d-container/mutant-3d/build/build.framework.js",
    // loaderUrl: "/MutantHound_Build.loader.js",
    // frameworkUrl: "/build.framework.js",
    // dataUrl: "/webgl.data",
    // codeUrl: "/build.wasm",
    cacheControl: handleCacheControl,
    streamingAssetsUrl: "/webgl/StreamingAssets",
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  });
  const screendimension = window.screen;
  const modelHeight =
    screendimension?.height - screendimension?.height * (27 / 100);
  const modalContainWidth =
    screendimension.width - screendimension.width * (35 / 100);
  const modalWidth = modelHeight * 1.85;

  useEffect(() => {
    unity?.addEventListener("EnvlLoaded", (e) => {
      if (e[0] == 1) {
        setLocalEnvArray([...localEnvArray, environment]);
        if (environment == e.slice(1)) {
          unity?.sendMessage("Manager", "ChangeEnviroment", environment);
        }
        return setEnvloaded(true);
      }
      setEnvloaded(false);
    });
    unity?.addEventListener("NFTLoaded", (e) => {
      if (e === 1) {
        setModelLoaded(true);
      }
    });
    return () => {
      unity?.removeEventListener("EnvlLoaded", () => {});
      unity?.removeEventListener("NFTLoaded", () => {});
    };
  }, [
    unity?.addEventListener,
    unity?.removeEventListener,
    window,
    environment,
  ]);

  useEffect(() => {
    if (unity?.isLoaded && animation && modelLoaded) {
      unity?.sendMessage("Manager", "ChangeAnimation", animation);
    }
  }, [unity?.isLoaded, animation, modelLoaded]);

  useEffect(() => {
    if (unity?.isLoaded && environment && modelLoaded) {
      if (localEnvArray.includes(environment)) {
        unity?.sendMessage("Manager", "ChangeEnviroment", environment);
      } else {
        envloaded &&
          unity?.sendMessage("Manager", "LoadEnviroment", environment);
      }
    }
    // eslint-disable-next-line
  }, [unity?.isLoaded, environment, modelLoaded]);

  useEffect(() => {
    console.log("jsonobject value=>", jsonObject);
    if (unity?.isLoaded && jsonObject) {
      unity?.sendMessage(
        "Manager",
        "ReceiveJsonValues",
        JSON.stringify({
          ...jsonObject,
        })
      );
    }
    console.log("ReceiveJsonValues=>", jsonObject);
    // eslint-disable-next-line
  }, [unity?.isLoaded, jsonObject]);

  const handleShare = () => {
    setOpenShare(!openShare);
    setOpenDownload(false);
  };
  const handleDownload = () => {
    setOpenShare(false);
    setOpenDownload(!openDowload);
  };
  return (
    <Box
      width={"100%"}
      sx={{
        display: "flex",
        margin: "auto",
        alignItems: "center",
      }}
    >
      {!unity?.isLoaded && (
        <Box
          sx={{
            width: "70vw",
            position: "absolute",
            display: unity?.isLoaded ? "none" : "flex",
            height: "70vh",
            alignItems: "center",
            // margin: "auto",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img
            width="140px"
            className="blnking-animation"
            src="https://ishrostorage.blob.core.windows.net/mutant-3d/model-loader.png"
          />
        </Box>
      )}
      <div
        style={{
          width: `65vw`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          ref={canvasRef}
          style={{
            width: `${
              modalWidth > modalContainWidth ? modalContainWidth : modalWidth
            }px`,
            border: recording ? "4px solid red" : "",
            height: `${modelHeight}px`,
            padding: "14px",
            borderRadius: "0.8vw",
          }}
          id={id}
        >
          <Unity
            style={{
              width: "100%",
              borderRadius: "0.8vw",
              // zIndex: -1,
              // marginTop: "-3vw",
              height: "100%",
            }}
            unityProvider={unity?.unityProvider}
          />
        </div>
      </div>

      {recording && (
        <Box
          sx={{
            display: "flex",
            position: "absolute",
            top: 50,
            width: "70%",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "700",
              fontFamily: "var(--font-f_r)",
              fontSize: "1vw",
              cursor: "pointer",
              textTransform: "capitalize",
              textAlign: "center",
              transition: "all 0.1s ease-in-out",
              color: "#fff",
              "&:hover": {
                color: "#94E161",
                transform: "scale(1.02)",
              },
            }}
          >
            Recording Start, You can animate the model during the recording and
            the recording will be ended after 10 seconds.
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          position: "absolute",
          bottom: 50,
          width: "70%",
          justifyContent: "center",
        }}
      >
        {openShare && (
          <div
            style={{
              position: "absolute",
              margin: "-3.5vw",
              backgroundColor: "#1b1c1a",
              padding: "8px",
              boxShadow: "#1b1c1a",
              display: "flex",
              justifyContent: "space-between",
              borderRadius: "4vw",
              width: "14vw",
              alignItems: "center",
              height: "2.5vw",
            }}
          >
            <ShareButton platform="twitter" shareUrl={shareUrl} title={title} />
            <ShareButton
              platform="facebook"
              shareUrl={shareUrl}
              title={title}
            />
            <ShareButton
              platform="instagram"
              shareUrl={shareUrl}
              title={title}
            />
            <ShareButton
              platform="whatsapp"
              shareUrl={shareUrl}
              title={title}
            />
          </div>
        )}
        {openDowload && (
          <div
            style={{
              position: "absolute",
              margin: "-3.5vw",
              backgroundColor: "#1b1c1a",
              padding: "8px",
              boxShadow: "#1b1c1a",
              display: "flex",
              justifyContent: "space-around",
              borderRadius: "4vw",
              width: "14vw",
              alignItems: "center",
              height: "2.5vw",
            }}
          >
            <Typography
              onClick={() => {
                //  downloadFileFromUrl(
                //   "https://ishrostorage.blob.core.windows.net/mutant-3d/mutant.fbx",
                //   "mutant"
                // )
              }}
              sx={{
                fontWeight: "700",
                fontFamily: "var(--font-f_r)",
                fontSize: "1vw",
                cursor: "pointer",
                textTransform: "capitalize",
                textAlign: "center",
                transition: "all 0.1s ease-in-out",
                color: "#fff",
                "&:hover": {
                  color: "#94E161",
                  transform: "scale(1.02)",
                },
              }}
            >
              Glb
            </Typography>
            <Typography
              sx={{
                fontWeight: "700",
                cursor: "pointer",
                fontFamily: "var(--font-f_r)",
                fontSize: "1vw",
                textTransform: "capitalize",
                textAlign: "center",
                transition: "all 0.1s ease-in-out",
                color: "#fff",
                "&:hover": {
                  color: "#94E161",
                  transform: "scale(1.02)",
                },
              }}
            >
              Gltf
            </Typography>
            {mode === "3d" ? (
              <Typography
                onClick={() => {
                  startRecording(id, "mutantVideo", setRecording);
                  setOpenDownload(false);
                }}
                sx={{
                  fontWeight: "700",
                  fontFamily: "var(--font-f_r)",
                  fontSize: "1vw",
                  cursor: "pointer",
                  textTransform: "capitalize",
                  textAlign: "center",
                  transition: "all 0.1s ease-in-out",
                  "&:hover": {
                    color: "#94E161",
                  },
                  color: "#fff",
                  transform: "scale(1.02)",
                }}
              >
                MP4
              </Typography>
            ) : (
              <Typography
                onClick={() => {
                  htmlToImageConverter(canvasRef, "MutantImage.png");
                  setOpenDownload(false);
                }}
                sx={{
                  fontWeight: "700",
                  fontFamily: "var(--font-f_r)",
                  fontSize: "1vw",
                  cursor: "pointer",
                  textTransform: "capitalize",
                  textAlign: "center",
                  transition: "all 0.1s ease-in-out",
                  "&:hover": {
                    color: "#94E161",
                  },
                  color: "#fff",
                  transform: "scale(1.02)",
                }}
              >
                PNG
              </Typography>
            )}
          </div>
        )}
        <ProjectButton name="Share" onClick={handleShare} />
        <ProjectButton name="Download" onClick={handleDownload} />
      </Box>
      {/* <FooterHome /> */}
    </Box>
  );
}

export default ApeUnity;

const ProjectButton = ({ name, onClick }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        cursor: "pointer",
        marginInline: "0.5vw",
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          width: "12vw",
          border: "1px solid #1e2e13",
          borderRadius: "10px",
          height: "65px",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          background: "",
          color: "white",
          "&:hover": {
            color: "#000",
            background: "#94E161",
          },
          [theme.breakpoints.down("sm")]: {
            width: "90%",
            marginBottom: "25px",
            maxWidth: "180px",
            minWidth: "130px",
            height: "45px",
            borderRadius: "8px",
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: "700",
            fontFamily: "var(--font-f_r)",
            fontSize: "1vw",
            textTransform: "capitalize",
            textAlign: "center",
            [theme.breakpoints.down("lg")]: {
              fontSize: "16px",
            },
            [theme.breakpoints.down("sm")]: {
              fontSize: "12px",
            },
            userSelect: "none",
            MozUserSelect: "none",
            WebkitUserSelect: "none",
            msUserSelect: "none",
          }}
        >
          {name}
        </Typography>
      </Box>
    </Box>
  );
};
