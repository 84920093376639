import { useTheme } from "@emotion/react";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Button, Toolbar, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { IoWallet } from "react-icons/io5";
import Sidebar from "./sidebar";
import { useNavigate } from "react-router-dom";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import Hamburger from "../assets/images/icons/hamburger";
const Header = ({ hamburger = false }) => {
  const { palette, ...theme } = useTheme();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const handleSidebarOpen = () => {
    setSidebarOpen(true);
  };
  const { openConnectModal } = useConnectModal();
  const isMobile = useMediaQuery("(max-width:599px)");
  // const connectLogo =
  //   "https://ishrostorage.blob.core.windows.net/mutant-3d/wallet-logo.png";
  const navigate = useNavigate();
  const handleSidebarClose = () => {
    setSidebarOpen(false);
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        "& .MuiPaper-root": {
          boxShadow: "none",
          // top: "10px",
          backgroundColor: "transparent",
          [theme.breakpoints.down("sm")]: {
            top: "8px",
          },
        },
        "& .MuiToolbar-root": {
          justifyContent: "center",
        },
      }}
    >
      <AppBar position="absolute">
        <Toolbar
          sx={{
            borderBottom: "1px solid #94E16140",
            backdropFilter: "blur(3px)",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          }}
          variant="dense"
        >
          <img
            onClick={() => navigate("/")}
            style={{
              width: isMobile ? "150px" : "13vw",
              userSelect: "none",
              MozUserSelect: "none",
              WebkitUserSelect: "none",
              msUserSelect: "none",
            }}
            src="https://ishrostorage.blob.core.windows.net/mutant-3d/logo.png"
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",

              "& button": { color: palette.grey[200] },
              [theme.breakpoints.down("sm")]: {
                width: "100%",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                [theme.breakpoints.down("sm")]: {
                  width: "70%",
                  justifyContent: "flex-end",
                },
              }}
            >
              {isMobile ? (
                <IoWallet
                  color="#7D1BEB"
                  size={28}
                  cursor="pointer"
                  onClick={openConnectModal}
                />
              ) : (
                <Button
                  onClick={openConnectModal}
                  variant="contained"
                  color="primary"
                  // onClick={handleConnect}
                  sx={{
                    borderRadius: "100px", // Adjust the value as needed
                    fontWeight: "900",
                    fontFamily: "F37-Regular",
                    fontSize: "1.25rem",
                    py: "0.3vw",
                    px: "1.5vw",
                    "&:hover": {
                      backgroundColor: "#fff",
                      color: "#000",
                    },
                  }}
                >
                  CONNECT WALLET
                </Button>
              )}

              <Box
                onClick={handleSidebarOpen}
                sx={{
                  // paddingInline: "10px",
                  borderRadius: "10px",
                  marginX: "2vw",
                  cursor: "pointer",
                  objectFit: "contain",
                  [theme.breakpoints.down("sm")]: {
                    border: "none",
                  },
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Hamburger />
                {/* <MenuIcon
                  sx={{
                    fontSize: "44px",
                    cursor: "pointer",
                    color: palette.secondary[100],
                    transition: "all .2s ease-in-out",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "38px",
                    },
                    "&:hover": {
                      color: palette.primary[100],
                    },
                  }}
                /> */}
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Sidebar isOpen={sidebarOpen} onClose={handleSidebarClose} />
      {sidebarOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            width: "100%",
            height: "100%",
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zIndex: 999,
          }}
          onClick={handleSidebarClose}
        ></div>
      )}
    </Box>
  );
};

export default Header;
