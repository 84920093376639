import * as React from "react";
export const ThreeD = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={33}
    fill="none"
    {...props}
  >
    <g filter="url(#a)">
      <rect width={32} height={33} fill="#353535" fillOpacity={0.59} rx={6} />
    </g>
    <g clipPath="url(#b)">
      <path
        fill={props?.color}
        d="m26.154 22.615-.502-1.877a.343.343 0 0 0-.663.175l.289 1.08-1.028-.595v-8.93a.343.343 0 0 0-.172-.3l-7.734-4.465V6.517l.787.79a.348.348 0 0 0 .488 0 .346.346 0 0 0 0-.488l-1.375-1.375a.346.346 0 0 0-.488 0L14.381 6.82a.345.345 0 1 0 .488.488l.787-.79v1.186l-7.734 4.465a.343.343 0 0 0-.172.3v8.93l-1.028.594.289-1.079a.344.344 0 0 0-.663-.175l-.502 1.877a.344.344 0 0 0 .03.26.363.363 0 0 0 .21.162l1.88.502a.344.344 0 0 0 .175-.663l-1.075-.289 1.028-.595 7.734 4.466a.33.33 0 0 0 .344 0l7.734-4.466 1.028.595-1.076.289a.344.344 0 0 0 .175.663l1.88-.502a.363.363 0 0 0 .21-.161.344.344 0 0 0 .031-.261Zm-10.498 2.952-6.875-3.97 2.926-1.688a.347.347 0 0 0 .127-.47.345.345 0 0 0-.471-.128l-2.925 1.691v-7.926l7.218 4.341v8.15ZM16 16.822l-7.229-4.348 6.885-3.977v3.379a.343.343 0 1 0 .688 0v-3.38l6.885 3.978L16 16.822Zm7.563 4.18-2.926-1.69a.345.345 0 1 0-.343.597l2.925 1.688-6.875 3.97v-8.15l7.218-4.342v7.927Z"
      />
    </g>
    <defs>
      <clipPath id="b">
        <path fill={props?.color} d="M5 5h22v22H5z" />
      </clipPath>
      <filter
        id="a"
        width={61.8}
        height={62.8}
        x={-14.9}
        y={-14.9}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={7.45} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_4128_24923"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_4128_24923"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const TwoD = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={33}
    fill="none"
    {...props}
  >
    <rect width={32} height={33} fill="#131313" rx={6} />
    <path
      fill="url(#a)"
      fillRule="evenodd"
      d="M5.3 12.21a.294.294 0 0 1-.3-.3V8.3c0-.171.129-.3.3-.3h3.653c.172 0 .3.129.3.3 0 .173-.128.345-.3.345H5.645v3.265c0 .172-.13.3-.344.3Z"
      clipRule="evenodd"
    />
    <path
      fill="url(#b)"
      fillRule="evenodd"
      d="M26.7 12.21c-.216 0-.345-.128-.345-.3V8.645H23.09a.37.37 0 0 1-.344-.344c0-.172.172-.301.344-.301h3.61c.171 0 .3.129.3.3v3.61c0 .172-.129.3-.3.3Z"
      clipRule="evenodd"
    />
    <path
      fill="url(#c)"
      fillRule="evenodd"
      d="M26.7 24.242h-3.61c-.172 0-.344-.129-.344-.3a.37.37 0 0 1 .344-.344h3.265v-3.223c0-.172.13-.344.344-.344.172 0 .301.172.301.344v3.566c0 .172-.129.301-.3.301Z"
      clipRule="evenodd"
    />
    <path
      fill="url(#d)"
      fillRule="evenodd"
      d="M8.953 24.242H5.301a.294.294 0 0 1-.301-.3v-3.567c0-.172.129-.344.3-.344.216 0 .345.172.345.344v3.266h3.308c.172 0 .3.129.3.3 0 .215-.128.301-.3.301Z"
      clipRule="evenodd"
    />
    <path
      fill="url(#e)"
      d="M8.743 19.258c2.24 1.893 4.708 2.894 7.14 2.894 2.431 0 4.9-1 7.14-2.894 1.677-1.419 2.63-2.822 2.67-2.881a.43.43 0 0 0 0-.48c-.04-.059-.993-1.463-2.67-2.881-2.24-1.894-4.709-2.895-7.14-2.895-2.432 0-4.9 1.001-7.14 2.895-1.678 1.418-2.63 2.822-2.67 2.88a.43.43 0 0 0 0 .48c.04.06.992 1.463 2.67 2.882Zm.555-5.586c1.452-1.228 3.773-2.692 6.585-2.692 2.803 0 5.118 1.456 6.567 2.676a15.663 15.663 0 0 1 2.356 2.481 15.613 15.613 0 0 1-2.338 2.465c-1.452 1.227-3.773 2.691-6.585 2.691-2.804 0-5.119-1.455-6.567-2.676a15.652 15.652 0 0 1-2.356-2.48 15.588 15.588 0 0 1 2.338-2.465Z"
    />
    <path
      fill="url(#f)"
      d="M15.883 20.004a3.867 3.867 0 1 0-1.384-7.48.43.43 0 0 0-.245.557c.08.208.307.34.598.23a3.009 3.009 0 1 1 1.03 5.834 3.011 3.011 0 0 1-2.957-3.557.429.429 0 1 0-.845-.158 3.872 3.872 0 0 0 3.803 4.574Z"
    />
    <path
      fill="url(#g)"
      d="M17.602 16.137a1.72 1.72 0 0 0-1.72-1.719 1.72 1.72 0 0 0-1.718 1.719 1.72 1.72 0 0 0 1.719 1.718 1.72 1.72 0 0 0 1.719-1.718Zm-2.579 0a.86.86 0 0 1 1.72 0 .86.86 0 0 1-1.72 0Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={7.127}
        x2={7.127}
        y1={8}
        y2={12.211}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props?.color} stopOpacity={0.4} />
        <stop offset={1} stopColor={props?.color} stopOpacity={0.38} />
      </linearGradient>
      <linearGradient
        id="b"
        x1={24.873}
        x2={24.873}
        y1={8}
        y2={12.211}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props?.color} stopOpacity={0.4} />
        <stop offset={1} stopColor={props?.color} stopOpacity={0.38} />
      </linearGradient>
      <linearGradient
        id="c"
        x1={24.873}
        x2={24.873}
        y1={20.031}
        y2={24.242}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props?.color} stopOpacity={0.4} />
        <stop offset={1} stopColor={props?.color} stopOpacity={0.38} />
      </linearGradient>
      <linearGradient
        id="d"
        x1={7.127}
        x2={7.127}
        y1={20.031}
        y2={24.242}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props?.color} stopOpacity={0.4} />
        <stop offset={1} stopColor={props?.color} stopOpacity={0.38} />
      </linearGradient>
      <linearGradient
        id="e"
        x1={15.883}
        x2={15.883}
        y1={10.121}
        y2={22.152}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props?.color} stopOpacity={0.4} />
        <stop offset={1} stopColor={props?.color} stopOpacity={0.38} />
      </linearGradient>
      <linearGradient
        id="f"
        x1={15.883}
        x2={15.883}
        y1={12.27}
        y2={20.004}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props?.color} stopOpacity={0.4} />
        <stop offset={1} stopColor={props?.color} stopOpacity={0.38} />
      </linearGradient>
      <linearGradient
        id="g"
        x1={15.883}
        x2={15.883}
        y1={14.418}
        y2={17.855}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props?.color} stopOpacity={0.4} />
        <stop offset={1} stopColor={props?.color} stopOpacity={0.38} />
      </linearGradient>
    </defs>
  </svg>
);
