// import Config from 'react-native-config'
import axios from "axios";
import { getToken } from "../authService";

var instance = axios.create({
  baseURL: "https://three-nodejs.azurewebsites.net/v1/",
  timeout: 120000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
  },
});

// Request interceptor
instance.interceptors.request.use(
  async (config) => {
    const token = getToken();
    if (config.headers === undefined) {
      config.headers = {};
    }
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (request) => request,
  (error) => new Error(error)
);

// Response interceptor
instance.interceptors.response.use(
  (response) => response,
  (error) => error.response
);

export default instance;
