import { Box, Button, Typography } from "@mui/material";
import * as React from "react";
import Header from "../../Header";
import { useTheme } from "@emotion/react";
import DashboardLayout from "../layout";
import { BiDownload } from "react-icons/bi";
import { downloadFileFromUrl } from "../../../utils";
import lookup_table from "../../../constants/lookup_table.json";
import { useParams } from "react-router-dom";

function MetaGameVR({ style = {} }) {
  const { palette, ...theme } = useTheme();

  const RenderRiggedItem = ({ data, margin = false }) => {
    return (
      <Box sx={{ marginTop: margin ? "4vw" : "" }}>
        <Typography
          sx={{
            fontWeight: "700",
            fontFamily: "var(--font-f_r)",
            fontSize: "1.2vw",
            textAlign: "start",
            width: "100%",
            marginLeft: "16px",
            textTransform: "capitalize",
            color: "#fff",
            [theme.breakpoints.down("lg")]: {
              fontSize: "16px",
            },
            [theme.breakpoints.down("sm")]: {
              fontSize: "12px",
            },
          }}
        >
          {data?.title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          {data.items.map((mod) => {
            return (
              <Box
                sx={{
                  cursor: "pointer",
                  border: "1px solid #474747",
                  padding: "15px",
                  height: "20vh",
                  width: "13vw",
                  margin: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "15px",
                }}
              >
                <img
                  style={{ height: "70%", marginBottom: "20px" }}
                  src={mod.image}
                />
                <Typography
                  onClick={() =>
                    mod?.file
                      ? downloadFileFromUrl(mod.file, "mutant")
                      : alert("No File Available")
                  }
                  sx={{
                    fontWeight: "700",
                    fontFamily: "var(--font-f_r)",
                    fontSize: "1vw",
                    textAlign: "end",
                    // marginBottom: "-20px",
                    width: "100%",
                    alignItems: "center",
                    textTransform: "capitalize",
                    color: "#fff",
                    [theme.breakpoints.down("lg")]: {
                      fontSize: "16px",
                    },
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "12px",
                    },
                  }}
                >
                  Download
                  <BiDownload
                    color={"#94E161"}
                    style={{ marginLeft: "10px", marginBottom: "-5px" }}
                    size={25}
                  />
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  };
  const RiggedModel = () => {
    const { id } = useParams();
    const [riggedData, setRiggedData] = React.useState({
      title: "File format available :",
      items: [
        {
          name: "FBX",
          image:
            "https://ishrostorage.blob.core.windows.net/container-3d/fbxFile.png",
          file: "",
        },
        {
          name: "glb",
          image:
            "https://ishrostorage.blob.core.windows.net/container-3d/glbFile.png",
          file: "",
        },
        // {
        //   name: "vrm",
        //   image:
        //     "https://ishrostorage.blob.core.windows.net/container-3d/vrmlFile.png",
        // },
      ],
    });
    React.useEffect(() => {
      setRiggedData({
        ...riggedData,
        items: [
          {
            ...riggedData.items[0],
            file: lookup_table[id]?.fbxURL,
          },
          riggedData.items[1],
        ],
      });
    }, [id]);
    return (
      <Box sx={{ width: "100%", padding: 3 }}>
        <RenderRiggedItem data={riggedData} />
      </Box>
    );
  };

  const layoutOptions = [
    {
      name: "Rigged Model",
      children: RiggedModel(),
    },
  ];
  return (
    <DashboardLayout layoutOptions={layoutOptions} title={"Meta/Game/VR"} />
  );
}

export default MetaGameVR;
