import { useContractWrite } from "wagmi";
import MutantHoundsABI from "../constants/MutantHoundsABI.json";

export const useReclaimToken = () => {
  const { write, data, isLoading, isError, error } = useContractWrite({
    address: process.env.REACT_APP_HOUNDS_CONTRACT_ADDRESS,
    abi: MutantHoundsABI,
    functionName: "reclaim",
    args: [],
  });

  const reclaim = async (tokenId, mint) => {
    try {
      await write({ args: [tokenId, mint] });
      // Set transaction details in the `data` property
      return { success: true, transaction: data };
    } catch (error) {
      console.error("Error:", error);
      return { success: false, error };
    }
  };

  return { reclaim, data, isLoading, isError, error };
};
