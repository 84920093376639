import React, { useContext, useEffect, useMemo } from "react";
import { NFTCollectionContext } from "../context/NFTCollectionContext";
import { useQuery } from "@tanstack/react-query";
import { Alchemy, Network } from "alchemy-sdk";
import { useAccount } from "wagmi";
import { ethers } from "ethers";
import { JsonRpcProvider } from "@ethersproject/providers";
import SoulBoundNFTABI from "../constants/SoulBoundNFTABI.json";
import megaIds from "../constants/megaIds.json";

const checkSoulboundToken = async (tokenId, address) => {
  try {
    const soulboundAddress = process.env.REACT_APP_3DHOUNDS_CONTRACT_ADDRESS;
    const provider = new JsonRpcProvider(process.env.REACT_APP_DEV_RPC);
    const contract = new ethers.Contract(
      soulboundAddress,
      SoulBoundNFTABI,
      provider
    );

    const ownerAddress = await contract.ownerOf(tokenId);
    const isOwner = ownerAddress.toLowerCase() === address.toLowerCase();

    return {
      canMintSoulboundToken: false,
      canReclaimSoulboundToken: !isOwner,
    };
  } catch (error) {
    // Assuming that an error means the token is not minted yet
    // console.log('Error checking soulbound token', error);
    return {
      canMintSoulboundToken: true,
      canReclaimSoulboundToken: false,
    };
  }
};

export const useFetchNFTCollection = () => {
  const { address } = useAccount();
  const { ownedNFTs, setOwnedNFTs } = useContext(NFTCollectionContext);

  const collectionAddress = process.env.REACT_APP_HOUNDS_CONTRACT_ADDRESS;

  const client = useMemo(
    () =>
      new Alchemy({
        apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
        network: Network.ETH_MAINNET,
      }),
    []
  );

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ["nftCollection", address, collectionAddress],
    queryFn: async () => {
      if (!address) return [];
      const result = await client.nft.getNftsForOwner(address, {
        contractAddresses: [collectionAddress],
        omitMetadata: false,
      });

      const ownedNfts = await Promise.all(
        result.ownedNfts.map(async (nft) => {
          const { canMintSoulboundToken, canReclaimSoulboundToken } =
            await checkSoulboundToken(nft.tokenId, address);
          return {
            ...nft,
            canMintSoulboundToken,
            canReclaimSoulboundToken,
          };
        })
      );

      // Filter out NFTs with tokenIds listed in megaIds
      const filteredNfts = ownedNfts.filter(
        (nft) => !megaIds.includes(nft.tokenId)
      );

      return filteredNfts;
    },
    enabled: !!address,
  });

  useEffect(() => {
    if (data) {
      setOwnedNFTs(data);
    }
  }, [data]);

  return { ownedNFTs, isLoading, isError, refetch };
};
