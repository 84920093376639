import React from "react";
import { Snackbar, SnackbarContent } from "@mui/material";
import htmlToImage, { toPng } from "html-to-image";

export function capitalizeFirstWord(str) {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
}

export function showToast(type, message, setToast) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={type}
      autoHideDuration={5000}
      onClose={() => setToast(false)}
    >
      <SnackbarContent
        style={{
          backgroundColor: type === "success" ? "green" : "red",
        }}
        message={message}
      />
    </Snackbar>
  );
}
export const downloadFileFromUrl = (url, fileName) => {
  const anchorElement = document.createElement("a");
  anchorElement.href = url;
  const extension = url.split(".").pop();
  const fileNameExt = fileName + "." + extension;
  anchorElement.download = fileNameExt;
  anchorElement.click();
};
export const htmlToImageConverter = async (elementRef, fileName) => {
  const divElement = elementRef.current;

  if (!divElement) {
    return;
  }

  try {
    const dataUrl = await toPng(elementRef.current, { cacheBust: false });
    const link = document.createElement("a");
    link.download = fileName || "nft.png";
    link.href = dataUrl;
    link.click();
  } catch (error) {
    console.error("Error converting div to image:", error);
  }
};

export async function startRecording(id, fileName, setRecording) {
  setRecording(true);
  const chunks = [];
  let canvasElt = document.getElementById(id);
  let canvasElt1 = canvasElt?.firstChild;

  const stream = canvasElt1.captureStream();

  // Remove mimeType from options to use the browser's default, typically video/webm
  const options = { videoBitsPerSecond: 9000000 }; // Example bitrate, adjust as needed

  // The MediaRecorder is now constructed without specifying the mimeType.
  const rec = new MediaRecorder(stream, options);
  rec.ondataavailable = (e) => chunks.push(e.data);
  rec.onstop = async (e) => {
    // Since we're not specifying the type, the Blob type should match the MediaRecorder's default.
    const blob = new Blob(chunks, { type: rec.mimeType });
    exportVid(blob, fileName, setRecording);
  };
  rec.start(10000);
  setTimeout(() => {
    rec.stop();
  }, 10000);
}

export function exportVid(blob, fileName, setRecording) {
  let vid = document.createElement("video");
  vid.src = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.download = fileName + ".mp4";
  a.href = vid.src;
  // a.textContent = fileName;
  a.click();
  setRecording(false);
}

export const validatePhoneNumber = (e) => {
  if (!e?.includes("/")) {
    return true;
  }
  if (e && e.length > 0) {
    const diff = e.split("/");
    const targetValueLength = diff[0].length;
    const requiredLength = (diff[1]?.match(/\./g) || []).length;
    if (requiredLength !== targetValueLength) {
      return false;
    } else if (targetValueLength === requiredLength) {
      return true;
    }
  }
  return false;
};

export const legalStatusOptions = [
  { value: "Partnership", label: "Partnership" },
  { value: "Private Limited", label: "Private Limited" },
  { value: "Sole Proprietorship", label: "Sole Proprietorship" },
];

export const legalStatusOptionsAr = [
  { value: "Partnership", label: "شراكة" },
  { value: "Private Limited", label: "شركة ذات مسؤولية محدودة" },
  { value: "Sole Proprietorship", label: "ملكية فردية" },
];

export const legalStatusOptionsFr = [
  { value: "Partnership", label: "Partenariat" },
  { value: "Private Limited", label: "Société à Responsabilité Limitée" },
  { value: "Sole Proprietorship", label: "Entreprise Individuelle" },
];

export const affiliateOptions = [
  { value: "AVI", label: "Avifuel SAS" },
  { value: "OAFS", label: "OLA Aviation Fuels Supply DMCC." },
  { value: "OEHL", label: "OLA Energy Holding Ltd" },
  { value: "OSDMCC", label: "OLA Energy Services DMCC" },
  { value: "LOSU", label: "OLA Energy Supply DMCC" },
  { value: "OHL", label: "OLA Holding Limited" },
  { value: "LOBF", label: "OLA Energy Burkina S.A" },
  { value: "LOCA", label: "OLA Energy Cameroon S.A" },
  {
    value: "LOCI",
    label: "OLA Energy Cote d'Ivoire S.A.S.U",
  },
  { value: "LOGA", label: "OLA Energy Gabon S.A" },
  { value: "LOKL", label: "OLA Energy Kenya Limited" },
  { value: "LOML", label: "OLA Energy Mali S.A" },
  { value: "LOMA", label: "OLA Energy Maroc SAS" },
  { value: "LOEG", label: "OLA Energy Misr S.A.E" },
  { value: "LONE", label: "OLA Energy Niger S.A" },
  { value: "LORE", label: "OLA Energy Reunion S.A.S" },
  { value: "LOSE", label: "OLA Energy Senegal" },
  { value: "LOTN", label: "OLA Energy Tunisie" },
  { value: "LOUL", label: "OLA Energy Uganda Limited" },
  { value: "LOTD", label: "Libya Oil Chad" },
  { value: "LOER", label: "Libya Oil Eritrea Limited" },
  { value: "LOSL", label: "Libya Oil Sudan Limited" },
  { value: "LOEL", label: "Libya Oil Ethiopia Limited" },
];

export const affiliateOptionsAr = [
  { value: "AVI", label: "أفيفيول ساس" },
  { value: "OAFS", label: "أو.إل.أيه. لتوريد وقود الطيران (ذ.م.م)" },
  { value: "OEHL", label: "أو.إل.أيه. للطاقة القابضة المحدودة" },
  { value: "OSDMCC", label: "أو.إل.أيه. لخدمات الطاقة (ذ.م.م)" },
  { value: "LOSU", label: "أو.إل.أيه. لتوريد الطاقة (ذ.م.م)" },
  { value: "OHL", label: "أو.إل.أيه. للاستثمارات القابضة المحدودة" },
  { value: "LOBF", label: "أو.إل.أيه. لتوريد الطاقة في بوركينا فاسو ش.م.ب.م" },
  { value: "LOCA", label: "أو.إل.أيه. لتوريد الطاقة في الكاميرون ش.م.ب.م" },
  {
    value: "LOCI",
    label: "أو.إل.أيه. لتوريد الطاقة في ساحل العاج ش.ذ.م.م",
  },
  { value: "LOGA", label: "أو.إل.أيه. لتوريد الطاقة في الجابون ش.م.ب.م" },
  { value: "LOKL", label: "أو.إل.أيه. لتوريد الطاقة في كينيا المحدودة" },
  { value: "LOML", label: "أو.إل.أيه. لتوريد الطاقة في مالي ش.م.ب.م" },
  { value: "LOMA", label: "أو.إل.أيه. لتوريد الطاقة في المغرب ش.ذ.م.م" },
  { value: "LOEG", label: "أو.إل.أيه. لتوريد الطاقة في مصر ش.ع.م.م" },
  { value: "LONE", label: "أو.إل.أيه. لتوريد الطاقة في النيجر ش.م.ب.م" },
  { value: "LORE", label: "أو.إل.أيه. لتوريد الطاقة في ريونيون ش.ذ.م.م" },
  { value: "LOSE", label: "أو.إل.أيه. لتوريد الطاقة في السنغال" },
  { value: "LOTN", label: "أو.إل.أيه. لتوريد الطاقة في تونس" },
  { value: "LOUL", label: "أو.إل.أيه. لتوريد الطاقة في أوغندا المحدودة" },
  { value: "LOTD", label: "ليبيا أويل تشاد" },
  { value: "LOER", label: "ليبيا أويل إريتريا المحدودة" },
  { value: "LOSL", label: "ليبيا أويل السودان المحدودة" },
  { value: "LOEL", label: "ليبيا أويل إثيوبيا المحدودة" },
];

export const affiliateOptionsFr = [
  { value: "AVI", label: "Avifuel SAS" },
  { value: "OAFS", label: "OLA Aviation Fuels Supply DMCC." },
  { value: "OEHL", label: "OLA Energy Holding Ltd" },
  { value: "OSDMCC", label: "OLA Energy Services DMCC" },
  { value: "LOSU", label: "OLA Energy Supply DMCC" },
  { value: "OHL", label: "OLA Holding Limited" },
  { value: "LOBF", label: "OLA Energy Burkina S.A" },
  { value: "LOCA", label: "OLA Energy Cameroon S.A" },
  {
    value: "LOCI",
    label: "OLA Energy Cote d'Ivoire S.A.S.U",
  },
  { value: "LOGA", label: "OLA Energy Gabon S.A" },
  { value: "LOKL", label: "OLA Energy Kenya Limited" },
  { value: "LOML", label: "OLA Energy Mali S.A" },
  { value: "LOMA", label: "OLA Energy Maroc SAS" },
  { value: "LOEG", label: "OLA Energy Misr S.A.E" },
  { value: "LONE", label: "OLA Energy Niger S.A" },
  { value: "LORE", label: "OLA Energy Reunion S.A.S" },
  { value: "LOSE", label: "OLA Energy Senegal" },
  { value: "LOTN", label: "OLA Energy Tunisie" },
  { value: "LOUL", label: "OLA Energy Uganda Limited" },
  { value: "LOTD", label: "Libya Oil Chad" },
  { value: "LOER", label: "Libya Oil Eritrea Limited" },
  { value: "LOSL", label: "Libya Oil Sudan Limited" },
  { value: "LOEL", label: "Libya Oil Ethiopia Limited" },
];

export const countryOptions = [
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Albania", label: "Albania" },
  { value: "Aland Islands", label: "Aland Islands" },
  { value: "Algeria", label: "Algeria" },
  { value: "American Samoa", label: "American Samoa" },
  { value: "Andorra", label: "Andorra" },
  { value: "Angola", label: "Angola" },
  { value: "Anguilla", label: "Anguilla" },
  { value: "Antarctica", label: "Antarctica" },
  { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
  { value: "Argentina", label: "Argentina" },
  { value: "Armenia", label: "Armenia" },
  { value: "Aruba", label: "Aruba" },
  { value: "Australia", label: "Australia" },
  { value: "Austria", label: "Austria" },
  { value: "Azerbaijan", label: "Azerbaijan" },
  { value: "Bahamas", label: "Bahamas" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Barbados", label: "Barbados" },
  { value: "Belarus", label: "Belarus" },
  { value: "Belgium", label: "Belgium" },
  { value: "Belize", label: "Belize" },
  { value: "Benin", label: "Benin" },
  { value: "Saint Barthelemy", label: "Saint Barthelemy" },
  { value: "Bermuda", label: "Bermuda" },
  { value: "Bhutan", label: "Bhutan" },
  { value: "Bolivia", label: "Bolivia" },
  { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
  { value: "Botswana", label: "Botswana" },
  { value: "Bouvet Island", label: "Bouvet Island" },
  { value: "Brazil", label: "Brazil" },
  {
    value: "British Indian Ocean Territory",
    label: "British Indian Ocean Territory",
  },
  { value: "Brunei Darussalam", label: "Brunei Darussalam" },
  { value: "Bulgaria", label: "Bulgaria" },
  { value: "Burkina Faso", label: "Burkina Faso" },
  { value: "Burundi", label: "Burundi" },
  { value: "Cambodia", label: "Cambodia" },
  { value: "Cameroon", label: "Cameroon" },
  { value: "Canada", label: "Canada" },
  { value: "Cape Verde", label: "Cape Verde" },
  { value: "Cayman Islands", label: "Cayman Islands" },
  { value: "Central African Republic", label: "Central African Republic" },
  { value: "Chad", label: "Chad" },
  { value: "Chile", label: "Chile" },
  { value: "China", label: "China" },
  { value: "Christmas Island", label: "Christmas Island" },
  { value: "Cocos (Keeling) Islands", label: "Cocos (Keeling) Islands" },
  { value: "Colombia", label: "Colombia" },
  { value: "Comoros", label: "Comoros" },
  { value: "Congo", label: "Congo" },
  { value: "Cook Islands", label: "Cook Islands" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Croatia", label: "Croatia" },
  { value: "Cuba", label: "Cuba" },
  { value: "Cyprus", label: "Cyprus" },
  { value: "Curaçao", label: "Curaçao" },
  { value: "Czech Republic", label: "Czech Republic" },
  { value: "Denmark", label: "Denmark" },
  { value: "Djibouti", label: "Djibouti" },
  { value: "Dominica", label: "Dominica" },
  { value: "Dominican Republic", label: "Dominican Republic" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Egypt", label: "Egypt" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea" },
  { value: "Eritrea", label: "Eritrea" },
  { value: "Estonia", label: "Estonia" },
  { value: "Ethiopia", label: "Ethiopia" },
  {
    value: "Falkland Islands (Malvinas)",
    label: "Falkland Islands (Malvinas)",
  },
  { value: "Faroe Islands", label: "Faroe Islands" },
  { value: "Fiji", label: "Fiji" },
  { value: "Finland", label: "Finland" },
  { value: "France", label: "France" },
  { value: "French Guiana", label: "French Guiana" },
  { value: "French Polynesia", label: "French Polynesia" },
  {
    value: "French Southern and Antarctic Lands",
    label: "French Southern and Antarctic Lands",
  },
  { value: "Gabon", label: "Gabon" },
  { value: "Gambia", label: "Gambia" },
  { value: "FrGeorgiaance", label: "Georgia" },
  { value: "Germany", label: "Germany" },
  { value: "Ghana", label: "Ghana" },
  { value: "Gibraltar", label: "Gibraltar" },
  { value: "Guernsey", label: "Guernsey" },
  { value: "Greece", label: "Greece" },
  { value: "Greenland", label: "Greenland" },
  { value: "Grenada", label: "Grenada" },
  { value: "Guadeloupe", label: "Guadeloupe" },
  { value: "Guam", label: "Guam" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guinea", label: "Guinea" },
  { value: "Guinea-Bissau", label: "Guinea-Bissau" },
  { value: "Guyana", label: "Guyana" },
  { value: "Haiti", label: "Haiti" },
  {
    value: "Heard and Mc Donald Islands",
    label: "Heard and Mc Donald Islands",
  },
  { value: "Honduras", label: "Honduras" },
  { value: "Hong Kong", label: "Hong Kong" },
  { value: "Hungary", label: "Hungary" },
  { value: "Iceland", label: "Iceland" },
  { value: "India", label: "India" },
  { value: "Isle of Man", label: "Isle of Man" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Iran", label: "Iran" },
  { value: "Iraq", label: "Iraq" },
  { value: "Ireland", label: "Ireland" },
  { value: "Israel", label: "Israel" },
  { value: "Italy", label: "Italy" },
  { value: "Ivory Coast", label: "Ivory Coast" },
  { value: "Jersey", label: "Jersey" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Japan", label: "Japan" },
  { value: "Jordan", label: "Jordan" },
  { value: "Kazakhstan", label: "Kazakhstan" },
  { value: "Kenya", label: "Kenya" },
  { value: "Kiribati", label: "Kiribati" },
  { value: "Korea(North Korea)", label: "Korea(North Korea)" },
  { value: "Korea(South Korea)", label: "Korea(South Korea)" },
  { value: "Kosovo", label: "Kosovo" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan" },
  { value: "Lao PDR", label: "Lao PDR" },
  { value: "Latvia", label: "Latvia" },
  { value: "Lebanon", label: "Lebanon" },
  { value: "Lesotho", label: "Lesotho" },
  { value: "Liberia", label: "Liberia" },
  { value: "Libya", label: "Libya" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Macau", label: "Macau" },
  { value: "Macedonia", label: "Macedonia" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Malawi", label: "Malawi" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Maldives", label: "Maldives" },
  { value: "Mali", label: "Mali" },
  { value: "Malta", label: "Malta" },
  { value: "Marshall Islands", label: "Marshall Islands" },
  { value: "Martinique", label: "Martinique" },
  { value: "Mauritania", label: "Mauritania" },
  { value: "Mauritius", label: "Mauritius" },
  { value: "Mayotte", label: "Mayotte" },
  { value: "Mexico", label: "Mexico" },
  { value: "Micronesia", label: "Micronesia" },
  { value: "Moldova", label: "Moldova" },
  { value: "Monaco", label: "Monaco" },
  { value: "Mongolia", label: "Mongolia" },
  { value: "Montenegro", label: "Montenegro" },
  { value: "Montserrat", label: "Montserrat" },
  { value: "Morocco", label: "Morocco" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Myanmar", label: "Myanmar" },
  { value: "Namibia", label: "Namibia" },
  { value: "Nauru", label: "Nauru" },
  { value: "Nepal", label: "Nepal" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "Netherlands Antilles", label: "Netherlands Antilles" },
  { value: "New Caledonia", label: "New Caledonia" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Niger", label: "Niger" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "Niue", label: "Niue" },
  { value: "Norfolk Island", label: "Norfolk Island" },
  { value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
  { value: "Norway", label: "Norway" },
  { value: "Oman", label: "Oman" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Palau", label: "Palau" },
  { value: "Palestine", label: "Palestine" },
  { value: "Panama", label: "Panama" },
  { value: "Papua New Guinea", label: "Papua New Guinea" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Peru", label: "Peru" },
  { value: "Philippines", label: "Philippines" },
  { value: "Pitcairn", label: "Pitcairn" },
  { value: "Poland", label: "Poland" },
  { value: "Portugal", label: "Portugal" },
  { value: "Puerto Rico", label: "Puerto Rico" },
  { value: "Qatar", label: "Qatar" },
  { value: "Reunion Island", label: "Reunion Island" },
  { value: "Romania", label: "Romania" },
  { value: "Russian", label: "Russian" },
  { value: "Rwanda", label: "Rwanda" },
  { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
  { value: "Saint Martin (French part)", label: "Saint Martin (French part)" },
  { value: "Sint Maarten (Dutch part)", label: "Sint Maarten (Dutch part)" },
  { value: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon" },
  {
    value: "Saint Vincent and the Grenadines",
    label: "Saint Vincent and the Grenadines",
  },
  { value: "Samoa", label: "Samoa" },
  { value: "San Marino", label: "San Marino" },
  { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serbia", label: "Serbia" },
  { value: "Seychelles", label: "Seychelles" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "Singapore", label: "Singapore" },
  { value: "Slovakia", label: "Slovakia" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Solomon Islands", label: "Solomon Islands" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Somalia", label: "Somalia" },
  { value: "South Africa", label: "South Africa" },
  {
    value: "South Georgia and the South Sandwich",
    label: "South Georgia and the South Sandwich",
  },
  { value: "South Sudan", label: "South Sudan" },
  { value: "Spain", label: "Spain" },
  { value: "Saint Helena", label: "Saint Helena" },
  { value: "Sudan", label: "Sudan" },
  { value: "Suriname", label: "Suriname" },
  { value: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen" },
  { value: "Swaziland", label: "Swaziland" },
  { value: "Sweden", label: "Sweden" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "Syria", label: "Syria" },
  { value: "Taiwan", label: "Taiwan" },
  { value: "Tajikistan", label: "Tajikistan" },
  { value: "Tanzania", label: "Tanzania" },
  { value: "Thailand", label: "Thailand" },
  { value: "Timor-Leste", label: "Timor-Leste" },
  { value: "Togo", label: "Togo" },
  { value: "Tokelau", label: "Tokelau" },
  { value: "Tonga", label: "Tonga" },
  { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
  { value: "Tunisia", label: "Tunisia" },
  { value: "Turkey", label: "Turkey" },
  { value: "Turkmenistan", label: "Turkmenistan" },
  { value: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
  { value: "Tuvalu", label: "Tuvalu" },
  { value: "Uganda", label: "Uganda" },
  { value: "Ukraine", label: "Ukraine" },
  { value: "United Arab Emirates", label: "United Arab Emirates" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "United States", label: "United States" },
  { value: "US Minor Outlying Islands", label: "US Minor Outlying Islands" },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Uzbekistan", label: "Uzbekistan" },
  { value: "Vanuatu", label: "Vanuatu" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Vietnam", label: "Vietnam" },
  { value: "Virgin Islands (U.S.)", label: "Virgin Islands (U.S.)" },
  { value: "Vatican City", label: "Vatican City" },
  { value: "Wallis and Futuna Islands", label: "Wallis and Futuna Islands" },
  { value: "Yemen", label: "Yemen" },
  { value: "Zambia", label: "Zambia" },
  { value: "Zimbabwe", label: "Zimbabwe" },
];

export const countryOptionsFr = [
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Albania", label: "Albanie" },
  { value: "Aland Islands", label: "Îles d'Åland" },
  { value: "Algeria", label: "Algérie" },
  { value: "American Samoa", label: "Samoa américaines" },
  { value: "Andorra", label: "Andorre" },
  { value: "Angola", label: "Angola" },
  { value: "Anguilla", label: "Anguilla" },
  { value: "Antarctica", label: "Antarctique" },
  { value: "Antigua and Barbuda", label: "Antigua-et-Barbuda" },
  { value: "Argentina", label: "Argentine" },
  { value: "Armenia", label: "Arménie" },
  { value: "Aruba", label: "Aruba" },
  { value: "Australia", label: "Australie" },
  { value: "Austria", label: "Autriche" },
  { value: "Azerbaijan", label: "Azerbaïdjan" },
  { value: "Bahamas", label: "Bahamas" },
  { value: "Bahrain", label: "Bahreïn" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Barbados", label: "Barbade" },
  { value: "Belarus", label: "Biélorussie" },
  { value: "Belgium", label: "Belgique" },
  { value: "Belize", label: "Belize" },
  { value: "Benin", label: "Bénin" },
  { value: "Saint Barthelemy", label: "Saint-Barthélemy" },
  { value: "Bermuda", label: "Bermudes" },
  { value: "Bhutan", label: "Bhoutan" },
  { value: "Bolivia", label: "Bolivie" },
  { value: "Bosnia and Herzegovina", label: "Bosnie-Herzégovine" },
  { value: "Botswana", label: "Botswana" },
  { value: "Bouvet Island", label: "Île Bouvet" },
  { value: "Brazil", label: "Brésil" },
  {
    value: "British Indian Ocean Territory",
    label: "Territoire britannique de l'océan Indien",
  },
  { value: "Brunei Darussalam", label: "Brunéi Darussalam" },
  { value: "Bulgaria", label: "Bulgarie" },
  { value: "Burkina Faso", label: "Burkina Faso" },
  { value: "Burundi", label: "Burundi" },
  { value: "Cambodia", label: "Cambodge" },
  { value: "Cameroon", label: "Cameroun" },
  { value: "Canada", label: "Canada" },
  { value: "Cape Verde", label: "Cap-Vert" },
  { value: "Cayman Islands", label: "Îles Caïmans" },
  { value: "Central African Republic", label: "République centrafricaine" },
  { value: "Chad", label: "Tchad" },
  { value: "Chile", label: "Chili" },
  { value: "China", label: "Chine" },
  { value: "Christmas Island", label: "Île Christmas" },
  { value: "Cocos (Keeling) Islands", label: "Îles Cocos (Keeling)" },
  { value: "Colombia", label: "Colombie" },
  { value: "Comoros", label: "Comores" },
  { value: "Congo", label: "Congo" },
  { value: "Cook Islands", label: "Îles Cook" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Croatia", label: "Croatie" },
  { value: "Cuba", label: "Cuba" },
  { value: "Cyprus", label: "Chypre" },
  { value: "Curaçao", label: "Curaçao" },
  { value: "Czech Republic", label: "République tchèque" },
  { value: "Denmark", label: "Danemark" },
  { value: "Djibouti", label: "Djibouti" },
  { value: "Dominica", label: "Dominique" },
  { value: "Dominican Republic", label: "République dominicaine" },
  { value: "Ecuador", label: "Équateur" },
  { value: "Egypt", label: "Égypte" },
  { value: "El Salvador", label: "Salvador" },
  { value: "Equatorial Guinea", label: "Guinée équatoriale" },
  { value: "Eritrea", label: "Érythrée" },
  { value: "Estonia", label: "Estonie" },
  { value: "Ethiopia", label: "Éthiopie" },
  { value: "Falkland Islands (Malvinas)", label: "Îles Falkland (Malvinas)" },
  { value: "Faroe Islands", label: "Îles Féroé" },
  { value: "Fiji", label: "Fidji" },
  { value: "Finland", label: "Finlande" },
  { value: "France", label: "France" },
  { value: "French Guiana", label: "Guyane française" },
  { value: "French Polynesia", label: "Polynésie française" },
  {
    value: "French Southern and Antarctic Lands",
    label: "Terres australes et antarctiques françaises",
  },
  { value: "Gabon", label: "Gabon" },
  { value: "Gambia", label: "Gambie" },
  { value: "Georgia", label: "Géorgie" },
  { value: "Germany", label: "Allemagne" },
  { value: "Ghana", label: "Ghana" },
  { value: "Gibraltar", label: "Gibraltar" },
  { value: "Guernsey", label: "Guernesey" },
  { value: "Greece", label: "Grèce" },
  { value: "Greenland", label: "Groenland" },
  { value: "Grenada", label: "Grenade" },
  { value: "Guadeloupe", label: "Guadeloupe" },
  { value: "Guam", label: "Guam" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guinea", label: "Guinée" },
  { value: "Guinea-Bissau", label: "Guinée-Bissau" },
  { value: "Guyana", label: "Guyana" },
  { value: "Haiti", label: "Haïti" },
  { value: "Heard and Mc Donald Islands", label: "Îles Heard et McDonald" },
  { value: "Honduras", label: "Honduras" },
  { value: "Hong Kong", label: "Hong Kong" },
  { value: "Hungary", label: "Hongrie" },
  { value: "Iceland", label: "Islande" },
  { value: "India", label: "Inde" },
  { value: "Isle of Man", label: "Île de Man" },
  { value: "Indonesia", label: "Indonésie" },
  { value: "Iran", label: "Iran" },
  { value: "Iraq", label: "Irak" },
  { value: "Ireland", label: "Irlande" },
  { value: "Israel", label: "Israël" },
  { value: "Italy", label: "Italie" },
  { value: "Ivory Coast", label: "Côte d'Ivoire" },
  { value: "Jersey", label: "Jersey" },
  { value: "Jamaica", label: "Jamaïque" },
  { value: "Japan", label: "Japon" },
  { value: "Jordan", label: "Jordanie" },
  { value: "Kazakhstan", label: "Kazakhstan" },
  { value: "Kenya", label: "Kenya" },
  { value: "Kiribati", label: "Kiribati" },
  { value: "Korea(North Korea)", label: "Corée (Corée du Nord)" },
  { value: "Korea(South Korea)", label: "Corée (Corée du Sud)" },
  { value: "Kosovo", label: "Kosovo" },
  { value: "Kuwait", label: "Koweït" },
  { value: "Kyrgyzstan", label: "Kirghizistan" },
  { value: "Lao PDR", label: "Laos" },
  { value: "Latvia", label: "Lettonie" },
  { value: "Lebanon", label: "Liban" },
  { value: "Lesotho", label: "Lesotho" },
  { value: "Liberia", label: "Libéria" },
  { value: "Libya", label: "Libye" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Lithuania", label: "Lituanie" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Macau", label: "Macao" },
  { value: "Macedonia", label: "Macédoine" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Malawi", label: "Malawi" },
  { value: "Malaysia", label: "Malaisie" },
  { value: "Maldives", label: "Maldives" },
  { value: "Mali", label: "Mali" },
  { value: "Malta", label: "Malte" },
  { value: "Marshall Islands", label: "Îles Marshall" },
  { value: "Martinique", label: "Martinique" },
  { value: "Mauritania", label: "Mauritanie" },
  { value: "Mauritius", label: "Maurice" },
  { value: "Mayotte", label: "Mayotte" },
  { value: "Mexico", label: "Mexique" },
  { value: "Micronesia", label: "Micronésie" },
  { value: "Moldova", label: "Moldavie" },
  { value: "Monaco", label: "Monaco" },
  { value: "Mongolia", label: "Mongolie" },
  { value: "Montenegro", label: "Monténégro" },
  { value: "Montserrat", label: "Montserrat" },
  { value: "Morocco", label: "Maroc" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Myanmar", label: "Myanmar" },
  { value: "Namibia", label: "Namibie" },
  { value: "Nauru", label: "Nauru" },
  { value: "Nepal", label: "Népal" },
  { value: "Netherlands", label: "Pays-Bas" },
  { value: "Netherlands Antilles", label: "Antilles néerlandaises" },
  { value: "New Caledonia", label: "Nouvelle-Calédonie" },
  { value: "New Zealand", label: "Nouvelle-Zélande" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Niger", label: "Niger" },
  { value: "Nigeria", label: "Nigéria" },
  { value: "Niue", label: "Niue" },
  { value: "Norfolk Island", label: "Île Norfolk" },
  { value: "Northern Mariana Islands", label: "Îles Mariannes du Nord" },
  { value: "Norway", label: "Norvège" },
  { value: "Oman", label: "Oman" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Palau", label: "Palaos" },
  { value: "Palestine", label: "Palestine" },
  { value: "Panama", label: "Panama" },
  { value: "Papua New Guinea", label: "Papouasie-Nouvelle-Guinée" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Peru", label: "Pérou" },
  { value: "Philippines", label: "Philippines" },
  { value: "Pitcairn", label: "Pitcairn" },
  { value: "Poland", label: "Pologne" },
  { value: "Portugal", label: "Portugal" },
  { value: "Puerto Rico", label: "Porto Rico" },
  { value: "Qatar", label: "Qatar" },
  { value: "Reunion Island", label: "Île de la Réunion" },
  { value: "Romania", label: "Roumanie" },
  { value: "Russian", label: "Russie" },
  { value: "Rwanda", label: "Rwanda" },
  { value: "Saint Kitts and Nevis", label: "Saint-Christophe-et-Niévès" },
  {
    value: "Saint Martin (French part)",
    label: "Saint-Martin (partie française)",
  },
  {
    value: "Sint Maarten (Dutch part)",
    label: "Sint Maarten (partie néerlandaise)",
  },
  { value: "Saint Pierre and Miquelon", label: "Saint-Pierre-et-Miquelon" },
  {
    value: "Saint Vincent and the Grenadines",
    label: "Saint-Vincent-et-les Grenadines",
  },
  { value: "Samoa", label: "Samoa" },
  { value: "San Marino", label: "Saint-Marin" },
  { value: "Sao Tome and Principe", label: "Sao Tomé-et-Principe" },
  { value: "Saudi Arabia", label: "Arabie saoudite" },
  { value: "Senegal", label: "Sénégal" },
  { value: "Serbia", label: "Serbie" },
  { value: "Seychelles", label: "Seychelles" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "Singapore", label: "Singapour" },
  { value: "Slovakia", label: "Slovaquie" },
  { value: "Slovenia", label: "Slovénie" },
  { value: "Solomon Islands", label: "Îles Salomon" },
  { value: "Slovenia", label: "Slovénie" },
  { value: "Somalia", label: "Somalie" },
  { value: "South Africa", label: "Afrique du Sud" },
  {
    value: "South Georgia and the South Sandwich",
    label: "Géorgie du Sud et les îles Sandwich du Sud",
  },
  { value: "South Sudan", label: "Soudan du Sud" },
  { value: "Spain", label: "Espagne" },
  { value: "Saint Helena", label: "Sainte-Hélène" },
  { value: "Sudan", label: "Soudan" },
  { value: "Suriname", label: "Suriname" },
  { value: "Svalbard and Jan Mayen", label: "Svalbard et Jan Mayen" },
  { value: "Swaziland", label: "Eswatini" },
  { value: "Sweden", label: "Suède" },
  { value: "Switzerland", label: "Suisse" },
  { value: "Syria", label: "Syrie" },
  { value: "Taiwan", label: "Taïwan" },
  { value: "Tajikistan", label: "Tadjikistan" },
  { value: "Tanzania", label: "Tanzanie" },
  { value: "Thailand", label: "Thaïlande" },
  { value: "Timor-Leste", label: "Timor oriental" },
  { value: "Togo", label: "Togo" },
  { value: "Tokelau", label: "Tokelau" },
  { value: "Tonga", label: "Tonga" },
  { value: "Trinidad and Tobago", label: "Trinité-et-Tobago" },
  { value: "Tunisia", label: "Tunisie" },
  { value: "Turkey", label: "Turquie" },
  { value: "Turkmenistan", label: "Turkménistan" },
  { value: "Turks and Caicos Islands", label: "Îles Turques-et-Caïques" },
  { value: "Tuvalu", label: "Tuvalu" },
  { value: "Uganda", label: "Ouganda" },
  { value: "Ukraine", label: "Ukraine" },
  { value: "United Arab Emirates", label: "Émirats arabes unis" },
  { value: "United Kingdom", label: "Royaume-Uni" },
  { value: "United States", label: "États-Unis" },
  {
    value: "US Minor Outlying Islands",
    label: "Îles mineures éloignées des États-Unis",
  },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Uzbekistan", label: "Ouzbékistan" },
  { value: "Vanuatu", label: "Vanuatu" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Vietnam", label: "Vietnam" },
  { value: "Virgin Islands (U.S.)", label: "Îles Vierges américaines" },
  { value: "Vatican City", label: "Cité du Vatican" },
  { value: "Wallis and Futuna Islands", label: "Wallis-et-Futuna" },
  { value: "Yemen", label: "Yémen" },
  { value: "Zambia", label: "Zambie" },
  { value: "Zimbabwe", label: "Zimbabwe" },
];

export const countryOptionsAr = [
  { value: "Afghanistan", label: "أفغانستان" },
  { value: "Albania", label: "ألبانيا" },
  { value: "Aland Islands", label: "جزر آلاند" },
  { value: "Algeria", label: "الجزائر" },
  { value: "American Samoa", label: "ساموا الأمريكية" },
  { value: "Andorra", label: "أندورا" },
  { value: "Angola", label: "أنغولا" },
  { value: "Anguilla", label: "أنغويلا" },
  { value: "Antarctica", label: "القارة القطبية الجنوبية" },
  { value: "Antigua and Barbuda", label: "أنتيغوا وبربودا" },
  { value: "Argentina", label: "الأرجنتين" },
  { value: "Armenia", label: "أرمينيا" },
  { value: "Aruba", label: "أروبا" },
  { value: "Australia", label: "أستراليا" },
  { value: "Austria", label: "النمسا" },
  { value: "Azerbaijan", label: "أذربيجان" },
  { value: "Bahamas", label: "الباهاما" },
  { value: "Bahrain", label: "البحرين" },
  { value: "Bangladesh", label: "بنغلاديش" },
  { value: "Barbados", label: "بربادوس" },
  { value: "Belarus", label: "بيلاروسيا" },
  { value: "Belgium", label: "بلجيكا" },
  { value: "Belize", label: "بليز" },
  { value: "Benin", label: "بنين" },
  { value: "Saint Barthelemy", label: "سانت بارتيليمي" },
  { value: "Bermuda", label: "برمودا" },
  { value: "Bhutan", label: "بوتان" },
  { value: "Bolivia", label: "بوليفيا" },
  { value: "Bosnia and Herzegovina", label: "البوسنة والهرسك" },
  { value: "Botswana", label: "بوتسوانا" },
  { value: "Bouvet Island", label: "جزيرة بوفيه" },
  { value: "Brazil", label: "البرازيل" },
  {
    value: "British Indian Ocean Territory",
    label: "إقليم المحيط الهندي البريطاني",
  },
  { value: "Brunei Darussalam", label: "بروناي دار السلام" },
  { value: "Bulgaria", label: "بلغاريا" },
  { value: "Burkina Faso", label: "بوركينا فاسو" },
  { value: "Burundi", label: "بوروندي" },
  { value: "Cambodia", label: "كمبوديا" },
  { value: "Cameroon", label: "الكاميرون" },
  { value: "Canada", label: "كندا" },
  { value: "Cape Verde", label: "الرأس الأخضر" },
  { value: "Cayman Islands", label: "جزر كايمان" },
  { value: "Central African Republic", label: "جمهورية أفريقيا الوسطى" },
  { value: "Chad", label: "تشاد" },
  { value: "Chile", label: "تشيلي" },
  { value: "China", label: "الصين" },
  { value: "Christmas Island", label: "جزيرة الكريسماس" },
  { value: "Cocos (Keeling) Islands", label: "جزر كوكوس (كيلينغ)" },
  { value: "Colombia", label: "كولومبيا" },
  { value: "Comoros", label: "جزر القمر" },
  { value: "Congo", label: "الكونغو" },
  { value: "Cook Islands", label: "جزر كوك" },
  { value: "Costa Rica", label: "كوستاريكا" },
  { value: "Croatia", label: "كرواتيا" },
  { value: "Cuba", label: "كوبا" },
  { value: "Cyprus", label: "قبرص" },
  { value: "Curaçao", label: "كوراساو" },
  { value: "Czech Republic", label: "جمهورية التشيك" },
  { value: "Denmark", label: "الدانمارك" },
  { value: "Djibouti", label: "جيبوتي" },
  { value: "Dominica", label: "دومينيكا" },
  { value: "Dominican Republic", label: "جمهورية الدومينيكان" },
  { value: "Ecuador", label: "الإكوادور" },
  { value: "Egypt", label: "مصر" },
  { value: "El Salvador", label: "السلفادور" },
  { value: "Equatorial Guinea", label: "غينيا الاستوائية" },
  { value: "Eritrea", label: "إريتريا" },
  { value: "Estonia", label: "إستونيا" },
  { value: "Ethiopia", label: "إثيوبيا" },
  { value: "Falkland Islands (Malvinas)", label: "جزر فوكلاند (مالفيناس)" },
  { value: "Faroe Islands", label: "جزر فارو" },
  { value: "Fiji", label: "فيجي" },
  { value: "Finland", label: "فنلندا" },
  { value: "France", label: "فرنسا" },
  { value: "French Guiana", label: "غويانا الفرنسية" },
  { value: "French Polynesia", label: "بولينيزيا الفرنسية" },
  {
    value: "French Southern and Antarctic Lands",
    label: "الأراضي الجنوبية الفرنسية وأنتارتيكا",
  },
  { value: "Gabon", label: "الغابون" },
  { value: "Gambia", label: "غامبيا" },
  { value: "Georgia", label: "جورجيا" },
  { value: "Germany", label: "ألمانيا" },
  { value: "Ghana", label: "غانا" },
  { value: "Gibraltar", label: "جبل طارق" },
  { value: "Guernsey", label: "غيرنزي" },
  { value: "Greece", label: "اليونان" },
  { value: "Greenland", label: "جرينلاند" },
  { value: "Grenada", label: "غرينادا" },
  { value: "Guadeloupe", label: "غواديلوب" },
  { value: "Guam", label: "غوام" },
  { value: "Guatemala", label: "غواتيمالا" },
  { value: "Guinea", label: "غينيا" },
  { value: "Guinea-Bissau", label: "غينيا بيساو" },
  { value: "Guyana", label: "غيانا" },
  { value: "Haiti", label: "هايتي" },
  { value: "Heard and Mc Donald Islands", label: "جزيرة هيرد وجزر ماكدونالد" },
  { value: "Honduras", label: "هندوراس" },
  { value: "Hong Kong", label: "هونغ كونغ" },
  { value: "Hungary", label: "هنغاريا" },
  { value: "Iceland", label: "آيسلندا" },
  { value: "India", label: "الهند" },
  { value: "Isle of Man", label: "جزيرة مان" },
  { value: "Indonesia", label: "إندونيسيا" },
  { value: "Iran", label: "إيران" },
  { value: "Iraq", label: "العراق" },
  { value: "Ireland", label: "أيرلندا" },
  { value: "Israel", label: "إسرائيل" },
  { value: "Malaysia", label: "ماليزيا" },
  { value: "Maldives", label: "جزر المالديف" },
  { value: "Mali", label: "مالي" },
  { value: "Malta", label: "مالطا" },
  { value: "Marshall Islands", label: "جزر مارشال" },
  { value: "Martinique", label: "مارتينيك" },
  { value: "Mauritania", label: "موريتانيا" },
  { value: "Mauritius", label: "موريشيوس" },
  { value: "Mayotte", label: "مايوت" },
  { value: "Mexico", label: "المكسيك" },
  { value: "Micronesia", label: "ميكرونيزيا" },
  { value: "Moldova", label: "مولدوفا" },
  { value: "Monaco", label: "موناكو" },
  { value: "Mongolia", label: "منغوليا" },
  { value: "Montenegro", label: "الجبل الأسود" },
  { value: "Montserrat", label: "مونتسيرات" },
  { value: "Morocco", label: "المغرب" },
  { value: "Mozambique", label: "موزمبيق" },
  { value: "Myanmar", label: "ميانمار" },
  { value: "Namibia", label: "ناميبيا" },
  { value: "Nauru", label: "ناورو" },
  { value: "Nepal", label: "نيبال" },
  { value: "Netherlands", label: "هولندا" },
  { value: "Netherlands Antilles", label: "جزر الأنتيل الهولندية" },
  { value: "New Caledonia", label: "كاليدونيا الجديدة" },
  { value: "New Zealand", label: "نيوزيلندا" },
  { value: "Nicaragua", label: "نيكاراغوا" },
  { value: "Niger", label: "النيجر" },
  { value: "Nigeria", label: "نيجيريا" },
  { value: "Niue", label: "نيوي" },
  { value: "Norfolk Island", label: "جزيرة نورفولك" },
  { value: "Northern Mariana Islands", label: "جزر ماريانا الشمالية" },
  { value: "Norway", label: "النرويج" },
  { value: "Oman", label: "عُمان" },
  { value: "Pakistan", label: "باكستان" },
  { value: "Palau", label: "بالاو" },
  { value: "Palestine", label: "فلسطين" },
  { value: "Panama", label: "بنما" },
  { value: "Papua New Guinea", label: "بابوا غينيا الجديدة" },
  { value: "Paraguay", label: "باراغواي" },
  { value: "Peru", label: "بيرو" },
  { value: "Philippines", label: "الفلبين" },
  { value: "Pitcairn", label: "بيتكيرن" },
  { value: "Poland", label: "بولندا" },
  { value: "Portugal", label: "البرتغال" },
  { value: "Puerto Rico", label: "بورتوريكو" },
  { value: "Qatar", label: "قطر" },
  { value: "Reunion Island", label: "جزيرة ريونيون" },
  { value: "Romania", label: "رومانيا" },
  { value: "Russian", label: "روسيا" },
  { value: "Rwanda", label: "رواندا" },
  { value: "Saint Kitts and Nevis", label: "سانت كيتس ونيفيس" },
  { value: "Saint Martin (French part)", label: "سانت مارتن (الجزء الفرنسي)" },
  { value: "Sint Maarten (Dutch part)", label: "سينت مارتن (الجزء الهولندي)" },
  { value: "Saint Pierre and Miquelon", label: "سان بيير وميكلون" },
  {
    value: "Saint Vincent and the Grenadines",
    label: "سانت فينسنت وجزر غرينادين",
  },
  { value: "Samoa", label: "ساموا" },
  { value: "San Marino", label: "سان مارينو" },
  { value: "Sao Tome and Principe", label: "ساو تومي وبرينسيبي" },
  { value: "Saudi Arabia", label: "المملكة العربية السعودية" },
  { value: "Senegal", label: "السنغال" },
  { value: "Serbia", label: "صربيا" },
  { value: "Seychelles", label: "سيشل" },
  { value: "Sierra Leone", label: "سيراليون" },
  { value: "Singapore", label: "سنغافورة" },
  { value: "Slovakia", label: "سلوفاكيا" },
  { value: "Slovenia", label: "سلوفينيا" },
  { value: "Solomon Islands", label: "جزر سليمان" },
  { value: "Somalia", label: "الصومال" },
  { value: "South Africa", label: "جنوب أفريقيا" },
  {
    value: "South Georgia and the South Sandwich",
    label: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
  },
  { value: "South Sudan", label: "جنوب السودان" },
  { value: "Spain", label: "إسبانيا" },
  { value: "Saint Helena", label: "سانت هيلينا" },
  { value: "Sudan", label: "السودان" },
  { value: "Suriname", label: "سورينام" },
  { value: "Svalbard and Jan Mayen", label: "سفالبارد ويان ماين" },
  { value: "Swaziland", label: "سوازيلاند" },
  { value: "Sweden", label: "السويد" },
  { value: "Switzerland", label: "سويسرا" },
  { value: "Syria", label: "سوريا" },
  { value: "Taiwan", label: "تايوان" },
  { value: "Tajikistan", label: "طاجيكستان" },
  { value: "Tanzania", label: "تانزانيا" },
  { value: "Thailand", label: "تايلاند" },
  { value: "Timor-Leste", label: "تيمور الشرقية" },
  { value: "Togo", label: "توغو" },
  { value: "Tokelau", label: "توكيلاو" },
  { value: "Tonga", label: "تونغا" },
  { value: "Trinidad and Tobago", label: "ترينيداد وتوباغو" },
  { value: "Tunisia", label: "تونس" },
  { value: "Turkey", label: "تركيا" },
  { value: "Turkmenistan", label: "تركمانستان" },
  { value: "Turks and Caicos Islands", label: "جزر تركس وكايكوس" },
  { value: "Tuvalu", label: "توفالو" },
  { value: "Uganda", label: "أوغندا" },
  { value: "Ukraine", label: "أوكرانيا" },
  { value: "United Arab Emirates", label: "الإمارات العربية المتحدة" },
  { value: "United Kingdom", label: "المملكة المتحدة" },
  { value: "United States", label: "الولايات المتحدة الأمريكية" },
  {
    value: "US Minor Outlying Islands",
    label: "جزر الولايات المتحدة الصغيرة النائية",
  },
  { value: "Uruguay", label: "أوروغواي" },
  { value: "Uzbekistan", label: "أوزبكستان" },
  { value: "Vanuatu", label: "فانواتو" },
  { value: "Venezuela", label: "فنزويلا" },
  { value: "Vietnam", label: "فيتنام" },
  { value: "Virgin Islands (U.S.)", label: "جزر فيرجن (الولايات المتحدة)" },
  { value: "Vatican City", label: "الفاتيكان" },
  { value: "Wallis and Futuna Islands", label: "جزر والس وفوتونا" },
  { value: "Yemen", label: "اليمن" },
  { value: "Zambia", label: "زامبيا" },
  { value: "Zimbabwe", label: "زيمبابوي" },
];

export const currencyOptions = [
  { value: "UAE Dirham - AED", label: "UAE Dirham - AED" },
  { value: "Egyptian Pound - EGP", label: "Egyptian Pound - EGP" },
  { value: "Nakfa - ERN", label: "Nakfa - ERN" },
  { value: "Ethiopian Birr - ETB", label: "Ethiopian Birr - ETB" },
  { value: "Euro - EUR", label: "Euro - EUR" },
  { value: "Kenyan Shilling - KES", label: "Kenyan Shilling - KES" },
  { value: "Moroccan Dirham - MAD", label: "Moroccan Dirham - MAD" },
  { value: "Sudanese Pound - SDG", label: "Sudanese Pound - SDG" },
  { value: "Tunisian Dinar - TND", label: "Tunisian Dinar - TND" },
  { value: "Uganda Shilling - UGX", label: "Uganda Shilling - UGX" },
  { value: "US Dollar - USD", label: "US Dollar - USD" },
  {
    value: "Central African CFA Franc - XAF",
    label: "Central African CFA Franc - XAF",
  },
  {
    value: "West African CFA Franc - XOF",
    label: "West African CFA Franc - XOF",
  },
];

export const currencyOptionsAr = [
  { value: "UAE Dirham - AED", label: "الدرهم الإماراتي - AED" },
  { value: "Egyptian Pound - EGP", label: "الجنيه المصري - EGP" },
  { value: "Nakfa - ERN", label: "الناكفا - ERN" },
  { value: "Ethiopian Birr - ETB", label: "البير الإثيوبي - ETB" },
  { value: "Euro - EUR", label: "اليورو - EUR" },
  { value: "Kenyan Shilling - KES", label: "الشلن الكيني - KES" },
  { value: "Moroccan Dirham - MAD", label: "الدرهم المغربي - MAD" },
  { value: "Sudanese Pound - SDG", label: "الجنيه السوداني - SDG" },
  { value: "Tunisian Dinar - TND", label: "الدينار التونسي - TND" },
  { value: "Uganda Shilling - UGX", label: "الشلن الأوغندي - UGX" },
  { value: "US Dollar - USD", label: "الدولار الأمريكي - USD" },
  {
    value: "Central African CFA Franc - XAF",
    label: "فرنك CFA وسط أفريقي - XAF",
  },
  {
    value: "West African CFA Franc - XOF",
    label: "فرنك CFA غرب أفريقيا - XOF",
  },
];

export const currencyOptionsFr = [
  { value: "UAE Dirham - AED", label: "Dirham des Émirats arabes unis - AED" },
  { value: "Egyptian Pound - EGP", label: "Livres égyptiennes - EGP" },
  { value: "Nakfa - ERN", label: "Nakfa érythréen - ERN" },
  { value: "Ethiopian Birr - ETB", label: "Birr éthiopien - ETB" },
  { value: "Euro - EUR", label: "Euro - EUR" },
  { value: "Kenyan Shilling - KES", label: "Shilling kényan - KES" },
  { value: "Moroccan Dirham - MAD", label: "Dirham marocain - MAD" },
  { value: "Sudanese Pound - SDG", label: "Livre soudanaise - SDG" },
  { value: "Tunisian Dinar - TND", label: "Dinar tunisien - TND" },
  { value: "Uganda Shilling - UGX", label: "Shilling ougandais - UGX" },
  { value: "US Dollar - USD", label: "Dollar américain - USD" },
  {
    value: "Central African CFA Franc - XAF",
    label: "Franc CFA de l'Afrique centrale - XAF",
  },
  {
    value: "West African CFA Franc - XOF",
    label: "Franc CFA de l'Afrique de l'Ouest - XOF",
  },
];

export const documentDeliveryMethodOptions = [
  { value: "Collection", label: "Collection" },
  { value: "Email", label: "Email" },
  {
    value: "Express Courier",
    label: "Express Courier",
  },
  {
    value: "Mail",
    label: "Mail",
  },
];

export const documentDeliveryMethodOptionsAr = [
  { value: "Collection", label: "جمع" },
  { value: "Email", label: "البريد الإلكتروني" },
  {
    value: "Express Courier",
    label: "خدمة البريد السريع",
  },
  {
    value: "Mail",
    label: "البريد",
  },
];

export const documentDeliveryMethodOptionsFr = [
  { value: "Collection", label: "Collection" },
  { value: "Email", label: "Courrier électronique" },
  {
    value: "Express Courier",
    label: "Courrier express",
  },
  {
    value: "Mail",
    label: "Courrier",
  },
];

export const preferredMethodOfPaymentOptions = [
  { value: "Bank Draft", label: "Bank Draft" },
  { value: "Bank Guarantee", label: "Bank Guarantee" },
  { value: "Bank Transfer", label: "Bank Transfer" },
  { value: "Cash", label: "Cash" },
  { value: "Credit Card", label: "Credit Card" },
  { value: "Cheque", label: "Cheque" },
  {
    value: "Certified Cheque",
    label: "Certified Cheque",
  },
];

export const preferredMethodOfPaymentOptionsAr = [
  { value: "Bank Draft", label: "صك مصرفي" },
  { value: "Bank Guarantee", label: "ضمان بنكي" },
  { value: "Bank Transfer", label: "تحويل بنكي" },
  { value: "Cash", label: "نقداً" },
  { value: "Credit Card", label: "بطاقة ائتمان" },
  { value: "Cheque", label: "شيك" },
  {
    value: "Certified Cheque",
    label: "شيك مصدق",
  },
];

export const preferredMethodOfPaymentOptionsFr = [
  { value: "Bank Draft", label: "Traite bancaire" },
  { value: "Bank Guarantee", label: "Garantie bancaire" },
  { value: "Bank Transfer", label: "Virement bancaire" },
  { value: "Cash", label: "Espèces" },
  { value: "Credit Card", label: "Carte de crédit" },
  { value: "Cheque", label: "Chèque" },
  {
    value: "Certified Cheque",
    label: "Chèque certifié",
  },
];
