import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LanguageContextWrapper } from "./context/languageContext";
import { themeSettings } from "./theme";
import { GlobalTheme } from "./theme/GlobalTheme";

import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import Dashboard3d from "./components/Menus/dashboard3d";
import StillPose from "./components/Menus/dashboard3d/still-pose";
import Merchandise from "./components/Menus/merchandise";
import MetaGameVR from "./components/Menus/metaGameVR";
import { LoaderProvider } from "./context/loaderContext";
import { Home } from "./pages";
import FAQ from "./pages/faq/faq";
import Features from "./pages/features/features";
import NftDetails from "./pages/nftDetails";
import Projects from "./pages/projects";
import store from "./store";
import Images from "./components/Menus/images";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "@rainbow-me/rainbowkit/styles.css";
import {
  connectorsForWallets,
  RainbowKitProvider,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import {
  injectedWallet,
  rainbowWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { jsonRpcProvider } from "@wagmi/core/providers/jsonRpc";
import { mainnet } from "wagmi/chains";
import { FooterHome } from "./components";
import Minting from "./pages/minting";

const useDevnet = process.env.REACT_APP_DEV;
const rpcUrl = useDevnet
  ? process.env.REACT_APP_DEV_RPC
  : process.env.REACT_APP_MAINNET_RPC;

const { chains, publicClient } = configureChains(
  [mainnet],
  [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: rpcUrl,
      }),
    }),
  ]
);
const projectId = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID;
const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      injectedWallet({ chains }),
      rainbowWallet({ projectId, chains }),
      walletConnectWallet({ projectId, chains }),
    ],
  },
]);

const wagmiConfig = createConfig({
  autoConnect: false,
  connectors: connectors,
  publicClient,
});

const queryClient = new QueryClient();

function App() {
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider
        chains={chains}
        theme={darkTheme({
          accentColor: "#B0F150",
          accentColorForeground: "#151012",
          borderRadius: "none",
          fontStack: "system",
        })}
      >
        <LoaderProvider>
          <ThemeProvider theme={themeSettings}>
            <GlobalTheme theme={themeSettings} />
            <SnackbarProvider
              autoHideDuration={3000}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <CssBaseline />

              <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                  <LanguageContextWrapper>
                    <BrowserRouter>
                      <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/projects" element={<Projects />} />
                        <Route path="/minting/:id" element={<Minting />} />
                        <Route path="/claim/:id" element={<Minting />} />
                        <Route path="/:id" element={<NftDetails />} />
                        <Route
                          path="/:id/animation"
                          element={<Dashboard3d />}
                        />
                        <Route path="/:id/stillPose" element={<StillPose />} />
                        <Route path="/:id/file" element={<MetaGameVR />} />
                        <Route path="/:id/library" element={<Merchandise />} />
                        <Route
                          path="/:id/library/images"
                          element={<Images />}
                        />

                        <Route path="/faq" element={<FAQ />} />
                        <Route path="/features" element={<Features />} />
                      </Routes>
                      <FooterHome />
                    </BrowserRouter>
                  </LanguageContextWrapper>
                </QueryClientProvider>
              </Provider>
            </SnackbarProvider>
          </ThemeProvider>
        </LoaderProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
