import { useTheme } from "@emotion/react";
import { Divider, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { footerLogo2, footerLogo1, footerLogo3 } from "../staticData/images";
import { AiFillYoutube, AiOutlineInstagram } from "react-icons/ai";
import { RiTwitterXFill } from "react-icons/ri";
import inceptiveLogo from "../assets/images/Logo-inceptive-white.png";
import { FaTiktok } from "react-icons/fa";
import { BsDiscord } from "react-icons/bs";
const FooterHome = ({ siderBar = false }) => {
  const { palette, ...theme } = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile = useMediaQuery("(max-width:599px)");
  return (
    <>
      {isMobile ? (
        <Box
          sx={{
            background: "#000",
            width: "100%",
            height: "180px",
            bottom: 0,
          }}
        >
          <Divider
            sx={{
              // marginTop: "20px",
              border: "1px solid #94E16140",
              width: "100%",
              opacity: 0.7,
            }}
          />

          <Box
            sx={{
              width: "100%",
              // width: onlySmallScreen ? "80%" : "30%",
              display: "flex",
              py: 1,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <img src="https://ishrostorage.blob.core.windows.net/mutant-3d/small-logo.png" />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                verticalAlign: "center",
                alignItems: "flex-start",
                width: "50%",
              }}
              justifyContent="space-between"
            >
              <RiTwitterXFill
                onClick={() =>
                  window.open(`https://twitter.com/mutant_cartel`, "_blank")
                }
                color={palette.font.default}
                style={{ cursor: "pointer", marginRight: "20px" }}
                size={30}
              />
              <BsDiscord
                onClick={() =>
                  window.open(
                    `https://discord.com/invite/mutantcartel`,
                    "_blank"
                  )
                }
                color={palette.font.default}
                style={{ cursor: "pointer", marginRight: "20px" }}
                size={30}
              />
            </Box>
          </Box>
          <Divider
            sx={{
              // marginTop: "20px",
              border: "1px solid #C4C4C4",
              opacity: 0.3,
            }}
          />
          <Box
            sx={{
              width: "70%",
              my: 1,
              marginInline: "auto",
              height: "25%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <img width="20%" src={footerLogo1} />

            <Divider
              sx={{
                // marginTop: "20px",
                width: "1px",
                height: "100%",
                border: "1px solid #C4C4C4",
                opacity: 0.2,
              }}
            />
            <img width="20%" src={footerLogo2} />
            <Divider
              sx={{
                // marginTop: "20px",
                width: "1px",
                height: "100%",
                border: "1px solid #C4C4C4",
                opacity: 0.2,
              }}
            />
            <img width="20%" src={footerLogo3} />
            <Divider
              sx={{
                // marginTop: "20px",
                width: "1px",
                height: "100%",
                border: "1px solid #C4C4C4",
                opacity: 0.2,
              }}
            />
            <img
              width="20%"
              src="https://ishrostorage.blob.core.windows.net/mutant-3d/dashboLogo.png"
            />
          </Box>
          <Divider
            sx={{
              // marginTop: "20px",
              border: "1px solid #C4C4C4",
              opacity: 0.3,
            }}
          />
          <Box
            sx={{
              width: "100%",
              mt: 1,
              display: "flex",
              height: "25%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "32%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  verticalAlign: "center",
                  cursor: "pointer",
                  fontFamily: "var(--font-i_r)",
                  fontSize: "10px",
                  color: "#C4C4C4",
                  ml: "4px",
                }}
              >
                FAQ
              </Typography>
              <Divider
                sx={{
                  // marginTop: "20px",
                  width: "1px",
                  height: "100%",
                  border: "1px solid #C4C4C4",
                  opacity: 0.2,
                }}
              />
              <Typography
                sx={{
                  verticalAlign: "center",
                  cursor: "pointer",
                  fontFamily: "var(--font-i_r)",
                  fontSize: "10px",
                  color: "#C4C4C4",
                }}
              >
                Terms
              </Typography>
              <Divider
                sx={{
                  // marginTop: "20px",
                  width: "1px",
                  height: "100%",
                  border: "1px solid #C4C4C4",
                  opacity: 0.2,
                }}
              />
              <Typography
                sx={{
                  verticalAlign: "center",
                  cursor: "pointer",
                  fontFamily: "var(--font-i_r)",
                  fontSize: "10px",
                  color: "#C4C4C4",
                }}
              >
                Privacy
              </Typography>
            </Box>
            <Typography
              sx={{
                verticalAlign: "center",
                cursor: "pointer",
                padding: "0px 12px",
                fontFamily: "var(--font-i_r)",
                fontSize: "10px",
                color: "#C4C4C4",
              }}
            >
              © 2023 Novel Labs All rights reserved.
            </Typography>
          </Box>
        </Box>
      ) : (
        <div
          style={{
            background: "#000",
            position: "fixed",
            width: "100%",
            height: "80px",
            bottom: 0,
          }}
        >
          <Divider
            sx={{
              // marginTop: "20px",
              border: "1px solid #C4C4C4",
              opacity: 0.3,
            }}
          />
          <Box
            sx={{
              // paddingBottom: "20px",
              // marginTop: "20px",

              "& .MuiPaper-root": {
                boxShadow: "none",
                backgroundColor: "transparent",
              },
              "& .MuiToolbar-root": {
                justifyContent: "flex-start",
              },
            }}
          >
            <Toolbar
              variant="dense"
              sx={{
                padding: "0 !important",
                margin: "0 !important",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  "& button": { color: palette.grey[200] },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    paddingLeft: "20px",
                    // width: onlySmallScreen ? "80%" : "30%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      paddingLeft: "4vw",

                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src="https://ishrostorage.blob.core.windows.net/mutant-3d/small-logo.png" />
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontFamily: "var(--font-i_r)",
                        fontSize: "0.6vw",
                        color: "#C4C4C4",
                        [theme.breakpoints.down("sm")]: {
                          fontSize: "12px",
                        },
                      }}
                    >
                      © 2023 Novel Labs All rights reserved.
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <img src={footerLogo1} />

                    <div
                      style={{
                        // margin: "0 15px 0 23px",
                        width: "1px",
                        background: "#C4C4C4",
                      }}
                    />
                    <img src={footerLogo2} />
                    <div
                      style={{
                        // margin: "0 15px 0 23px",
                        width: "1px",
                        background: "#C4C4C4",
                      }}
                    />
                    <img src={footerLogo3} />
                    <div
                      style={{
                        marginLeft: "15px",
                        width: "1px",
                        background: "#C4C4C4",
                      }}
                    />
                    <img src="https://ishrostorage.blob.core.windows.net/mutant-3d/dashboLogo.png" />
                  </div>

                  <Box
                    display="flex"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      verticalAlign: "center",
                      alignItems: "center",
                      width: onlySmallScreen ? "20%" : "25vw",
                      [theme.breakpoints.down("sm")]: {
                        width: 110,
                      },
                    }}
                    justifyContent="space-between"
                  >
                    <RiTwitterXFill
                      onClick={() =>
                        window.open(
                          `https://twitter.com/mutant_cartel`,
                          "_blank"
                        )
                      }
                      color={palette.font.default}
                      style={{ cursor: "pointer", marginRight: "20px" }}
                      size={onlySmallScreen ? 15 : 25}
                    />
                    <BsDiscord
                      onClick={() =>
                        window.open(
                          `https://discord.com/invite/mutantcartel`,
                          "_blank"
                        )
                      }
                      color={palette.font.default}
                      style={{ cursor: "pointer", marginRight: "20px" }}
                      size={onlySmallScreen ? 15 : 25}
                    />

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          verticalAlign: "center",
                          cursor: "pointer",
                          padding: "0px 12px",
                          fontFamily: "var(--font-i_r)",
                          fontSize: "0.6vw",
                          color: "#C4C4C4",
                          [theme.breakpoints.down("sm")]: {
                            fontSize: "12px",
                          },
                        }}
                      >
                        FAQ
                      </Typography>
                      <div
                        style={{
                          height: "25px",
                          width: "1px",
                          background: "#C4C4C4",
                          // border: "1px solid #fff",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          verticalAlign: "center",
                          cursor: "pointer",
                          padding: "0px 12px",
                          fontFamily: "var(--font-i_r)",
                          fontSize: "0.6vw",
                          color: "#C4C4C4",
                          [theme.breakpoints.down("sm")]: {
                            fontSize: "12px",
                          },
                        }}
                      >
                        Terms
                      </Typography>
                      <div
                        style={{
                          height: "25px",
                          width: "1px",
                          background: "#C4C4C4",
                          // border: "1px solid #fff",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          verticalAlign: "center",
                          cursor: "pointer",
                          padding: "0px 12px",
                          fontFamily: "var(--font-i_r)",
                          fontSize: "0.6vw",
                          color: "#C4C4C4",
                          [theme.breakpoints.down("sm")]: {
                            fontSize: "12px",
                          },
                        }}
                      >
                        Privacy
                      </Typography>
                      <div
                        style={{
                          height: "25px",
                          width: "1px",
                          background: "#000",
                          // border: "1px solid #fff",
                        }}
                      />
                    </div>
                  </Box>
                </Box>
              </Box>
            </Toolbar>
          </Box>
        </div>
      )}
    </>
  );
};

export default FooterHome;
