import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Controller } from "react-hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const BtStyle = {
  "& label.Mui-focused": {},
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      border: "0.5px solid #94E161",
      borderRadius: "10px",
    },
  },
};

function CustomInput({
  label,
  type,
  placeholder,
  name,
  error,
  control,
  width,
  multiline,
  rules,
  rows,
  value,
  required = false,
  onChange,
  handleClickShowPassword,
  showPassword,
  helperText,
  isPassword,
  disabled,
  endAdornment = null,
  style = {},
}) {
  const theme = useTheme();
  return (
    <Box sx={style}>
      <div>
        <Typography
          sx={{
            fontWeight: "700",
            fontFamily: "var(--font-f_r)",
            textTransform: "capitalize",
            color: "#ffffff",
            marginLeft: "10px",
            fontSize: "30px",
            marginBottom: "5px",
            [theme.breakpoints.down("sm")]: {
              fontSize: "16px",
            },
          }}
        >
          {label}
        </Typography>
        <FormControl
          variant="outlined"
          sx={{
            border: "0.5px solid #414141",
            borderRadius: "5px",
            width: width ? width : "30vw",
          }}
        >
          <OutlinedInput
            multiline={multiline ? true : false}
            rows={rows ? rows : multiline ? 6 : 1}
            value={value}
            sx={BtStyle}
            error={error}
            required={required}
            helperText={helperText}
            onChange={onChange}
            endAdornment={
              endAdornment && (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
              )
            }
            inputProps={{
              style: {
                color: "#fff",
                background: "#000",
                borderRadius: "10px",
              },
            }}
            fullWidth
            placeholder={placeholder ? placeholder : ""}
          />
        </FormControl>
      </div>
      {error && (
        <Typography
          sx={{
            textTransform: "capitalize",
            fontWeight: "100",
            color: "#ff6666",
            marginLeft: "10px",
            fontSize: "14px",
            [theme.breakpoints.down("sm")]: {
              fontSize: "10px",
            },
          }}
        >
          {helperText}
        </Typography>
      )}
    </Box>
  );
}

export default CustomInput;
