import { Header } from "../../components";
import Footer from "../../components/FooterHome";
import { useNavigate } from 'react-router-dom';

import { BackButton } from "../../components";
import "./faq.css";
import CollapsiblePanel from "./CollapsiblePanel";

const FAQ = () => {
  const navigate = useNavigate();

  const panels = [
    { title: "What is the Mutant Cartel Oath?", content: "What is the Mutant Cartel Oath?" },
    { title: "What is the Genesis Oath?", content: "What is the Genesis Oath?" },
    { title: "Why did the Genesis Oath get updated for the Mutant Cartel Oath?", content: "Why did the Genesis Oath get updated for the Mutant Cartel Oath?" },

    { title: "How can I get on the allowlist for the Mutant Cartel Oath mint?", content: "How can I get on the allowlist for the Mutant Cartel Oath mint?" },
    { title: "When was the Genesis Oath holder snapshot?", content: "When was the Genesis Oath holder snapshot?" },

    { title: "When did the Mutant Cartel Oath mint begin?", content: "When did the Mutant Cartel Oath mint begin?" },

    { title: "How do I mint a Mutant Cartel Oath if I am on the allowlist?", content: "How do I mint a Mutant Cartel Oath if I am on the allowlist?" },

    { title: "How much will the Mutant Cartel Oath mint cost?", content: "How much will the Mutant Cartel Oath mint cost?" },

    { title: "How many Mutant Cartel Oaths can I mint?", content: "How many Mutant Cartel Oaths can I mint?" },

    { title: "What are the T&Cs?", content: "What are the T&Cs?" },

    { title: "Is there a delegate wallet option?", content: "Is there a delegate wallet option?" },

    { title: "Will this mint have an effect on the total Genesis Oath supply?", content: "Will this mint have an effect on the total Genesis Oath supply?" },


    { title: "How long will the Mutant Cartel Oath mint be available?", content: "How long will the Mutant Cartel Oath mint be available?" },
    { title: "Where can I view the Mutant Cartel Oath collection?", content: "Where can I view the Mutant Cartel Oath collection?" },




    
  ];

  return (
    <div>
      <div className="header">
        <Header />
      </div>
      <div className="body">
        <div className="back-button">
          <BackButton backgroundColor="#94E161" onClick={() => navigate(-1)} />
        </div>
        <div className="faq-title">
          <h3>FAQ</h3>
        </div>
        <div className="mutant-collapse">
          <h3>MUTANT CARTEL OATH</h3>
        </div>
        <div className="container">
          {panels.map((panel, index) => (
              <CollapsiblePanel
                key={index}
                title={panel.title}
                content={panel.content}
              />
          ))}
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};
export default FAQ;
