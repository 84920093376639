import { Box, Typography } from "@mui/material";
import * as React from "react";
import { useTheme } from "@emotion/react";
import Header from "../Header";
import { useNavigate } from "react-router-dom";

import { TfiShiftLeftAlt } from "react-icons/tfi";
import { BiLeftArrowAlt } from "react-icons/bi";
import BackButton from "../BackButton";

function DashboardLayout({ layoutOptions, title }) {
  const { palette, ...theme } = useTheme();
  const [step, setStep] = React.useState(0);
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        backgroundPosition: "bottom",
        backgroundSize: "cover",
        backgroundImage: "#000",
      }}
    >
      <Header />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <Box
          sx={{
            width: "96%",
            marginTop: "4vw",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            height: "87%",
            // border: "1px solid #29401A",
            // borderRadius: "15px",
            backdropFilter: "blur(10px)",
            padding: 2,
          }}
        >
          <Box
            sx={{
              width: "15%",
              justifyItems: "flex-start",
              height: "100%",
              fontWeight: "800",
              borderRadius: "15px",
              background:
                "linear-gradient(113deg, rgba(30, 66, 6, 0.85) 10.38%, rgba(31, 31, 31, 0.75) 100%),linear-gradient(0deg, #94E161, #94E161)",
              border: "1px solid #94E161",
              padding: "1vw",
              alignItems: "flex-start",
            }}
          >
            <BackButton onClick={() => navigate(-1)} />
            {/* <Typography
              onClick={() => navigate(-1)}
              sx={{
                fontWeight: "800",
                fontFamily: "var(--font-f_r)",
                fontSize: "1.5vw",
                textAlign: "left",
                cursor: "pointer",
                textTransform: "capitalize",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "14px",
                  marginRight: "0px",
                },
                color: "#94E161",
                marginRight: "5px",
              }}
            >
              {title}
            </Typography> */}
            {layoutOptions?.map((option, i) => {
              return (
                <Box
                  onClick={() => {
                    option?.navigate ? navigate(option?.navigate) : setStep(i);
                  }}
                  sx={{
                    cursor: "pointer",
                    padding: 2,
                    marginBlock: 2,
                    borderRadius: "10px",
                    border: step === i ? "1px solid #6F6F6F" : "none",
                    background: step === i ? "#94E161" : "",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontFamily: "var(--font-f_r)",
                      fontSize: "1vw",
                      textTransform: "capitalize",
                      textAlign: "start",
                      color: step === i ? "#000" : "#fff",
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "14px",
                      },
                    }}
                  >
                    {option.name}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          <Box
            sx={{
              width: "85%",
              background: "#000",
              border: "1px solid #29401A",
              marginLeft: "20px",
              height: "100%",
              borderRadius: "15px",
            }}
          >
            {layoutOptions[step] && layoutOptions[step]?.children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default DashboardLayout;
