import React, { useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import Header from "../../Header";
import { useTheme } from "@emotion/react";
import ApeUnity from "../../3dModels/models/ApeUnity";
import BackButton from "../../BackButton";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

function Dashboard3d({ type = "3d" }) {
  const navigate = useNavigate();
  const UnityUI = () => {
    const { id } = useParams();
    const [envloaded, setEnvloaded] = useState(true);
    const [localEnvArray, setLocalEnvArray] = useState(["Studio"]);
    const [mode, setMode] = useState("animate");
    const [modelJson, setModelJson] = useState({});
    const cleanJsonValues = (singleObject) => {
      const cleanedObject = {};
      for (const key in singleObject) {
        if (singleObject.hasOwnProperty(key)) {
          cleanedObject[key] = singleObject[key].replace(/\s+/g, " ").trim();
        }
      }
      return cleanedObject;
    };
    const getModelJson = async () => {
      const response = await fetch(
        `https://storage.googleapis.com/mutant-honds/meta_data/mutant_hounds_meta_v3.json`
      );
      const metaArray = await response.json();
      const selectedJson = await metaArray.find((element) => element.ID == id);
      const cleanedObject = cleanJsonValues(selectedJson);
      if (cleanedObject) {
        delete cleanedObject.Backgrounds;
        delete cleanedObject.ID;
        setModelJson(cleanedObject);
      } else {
        alert(`NFT with This id ${id} Not Found `);
        setModelJson({});
      }
    };
    useEffect(() => {
      getModelJson();
    }, []);
    const [buttonType, setButtonType] = useState([
      {
        name: type === "2d" ? "Pose" : "Animation",
        selected: true,
        items:
          type === "2d"
            ? []
            : [
                {
                  style: "Idle",
                  image:
                    "https://storage.googleapis.com/3d-container/mutant-3d/animations/MutantHound_idle.gif",
                  selected: true,
                },
                {
                  style: "Attack",
                  image:
                    "https://storage.googleapis.com/3d-container/mutant-3d/animations/MutantHound_attack.gif",
                  selected: false,
                },
                {
                  style: "Jump",
                  image:
                    "https://storage.googleapis.com/3d-container/mutant-3d/animations/MutantHound_jump.gif",
                  selected: false,
                },
                {
                  style: "Run",
                  image:
                    "https://storage.googleapis.com/3d-container/mutant-3d/animations/MutantHound_run.gif",
                  selected: false,
                },
                {
                  style: "Walk",
                  image:
                    "https://storage.googleapis.com/3d-container/mutant-3d/animations/MutantHound_walk.gif",
                  selected: false,
                },
                {
                  style: "Sniffing",
                  image:
                    "https://storage.googleapis.com/3d-container/mutant-3d/animations/MutantHound_sniffing.gif",
                  selected: false,
                },
              ],
      },
      {
        name: "Environment",
        selected: false,
        items: [
          {
            style: "Studio",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/env/Studio.png",
            selected: false,
          },
          {
            style: "Docks",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/env/Docks.png",
            selected: false,
          },
          {
            style: "Hideout",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/env/Hideout.png",
            selected: false,
          },
          {
            style: "Jungle",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/env/Jungle.png",
            selected: false,
          },
          {
            style: "Lab",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/env/Lab.png",
            selected: false,
          },
          {
            style: "Shipwreck",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/env/Shipwreck.png",
            selected: false,
          },
          {
            style: "HoundLair",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/env/HoundLair.png",
            selected: false,
          },
          {
            style: "CaveEntrance",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/env/CaveEntrance.png",
            selected: false,
          },
          {
            style: "TempleRoom",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/env/TempleRoom.png",
            selected: false,
          },
          {
            style: "Warehouse",
            image:
              "https://ishrostorage.blob.core.windows.net/mutant-3d/env/Warehouse.png",
            selected: false,
          },
        ],
      },
    ]);

    const RenderItems = ({ items }) => {
      return (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            height: "80%",
            overflowY: "scroll",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          {items.map((item) => {
            return (
              <Box
                onClick={() => {
                  const updated = items.map((it) => {
                    if (it.style === item.style) {
                      return {
                        ...it,
                        selected: true,
                      };
                    }
                    return {
                      ...it,
                      selected: false,
                    };
                  });
                  let arr = buttonType;
                  if (mode === "animate") {
                    arr[0].items = updated;
                  } else {
                    arr[1].items = updated;
                  }
                  if (!envloaded) return; // Ensure envloaded is true before updating state
                  setButtonType([...arr]);
                }}
                sx={{
                  cursor: "pointer",
                  border: mode === "env" ? "" : "1px solid #474747",
                  padding: mode === "env" ? "" : "15px",
                  margin: "10px",
                  borderRadius: "15px",
                  position: "relative", // Add relative positioning to the box
                }}
              >
                {/* Render background image */}
                {mode === "env" && !localEnvArray.includes(item.style) && (
                  <div
                    style={{
                      position: "absolute",
                      top: "5%",
                      right: "6%",
                      background: "#2A1B3EF0",
                      width: "17%",
                      display: "flex",
                      borderRadius: "5px",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "3px",
                      height: "15%",
                    }}
                  >
                    {item.selected && !envloaded ? (
                      <CircularProgress color="secondary" size={18} />
                    ) : (
                      <img
                        style={{
                          width: "70%",
                          height: "70%",
                        }}
                        src="/downloadLogo.png"
                        alt="download-img"
                      />
                    )}
                  </div>
                )}
                {/* Render image inside the box */}
                <img
                  style={{
                    width: mode === "env" ? "7vw" : "6vw",
                    borderRadius: "10px",
                  }}
                  src={item.image}
                  alt={item.alt}
                />
              </Box>
            );
          })}
        </Box>
      );
    };
    return (
      <Box
        sx={{
          width: "100%",
          height: "90%",
          // padding: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <ApeUnity
          animation={buttonType[0].items.find((e) => e.selected)?.style}
          environment={buttonType[1].items.find((e) => e.selected)?.style}
          jsonObject={modelJson}
          envloaded={envloaded}
          localEnvArray={localEnvArray}
          setLocalEnvArray={setLocalEnvArray}
          setEnvloaded={setEnvloaded}
        />
        <Box
          sx={{
            width: "30%",
            position: "absolute",
            top: 30,
            right: 30,
            height: "90%",
            borderRadius: "1vw",
            display: "flex",
            flexDirection: "column",
            paddingInline: "20px",
            // overflowY: "scroll",
            border: "2px solid #53703F",
            alignItems: "center",
            // justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              paddingBlock: "20px",
              justifyContent: "space-around",
            }}
          >
            {buttonType.map((button, i) => {
              return (
                <ProjectButton project={button} mode={mode} setMode={setMode} />
              );
            })}
          </Box>
          <RenderItems
            items={
              mode === "animate" ? buttonType[0].items : buttonType[1].items
            }
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        padding: 3,
        height: "100%",

        overflow: "hidden",
      }}
    >
      <Header />

      <Box
        sx={{
          border: "1px solid #2C3B22",
          height: "77vh",
          position: "absolute",
          borderRadius: "0.5vw",
          marginTop: "10vh",
          // bottom: "20px",
          width: "96%",
          overflow: "hidden",
        }}
      >
        <div style={{ position: "absolute", padding: "20px" }}>
          <BackButton
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
        <UnityUI />
      </Box>
      {/* <FooterHome /> */}
    </Box>
  );
}

export default Dashboard3d;

const ProjectButton = ({ project, mode, setMode }) => {
  const theme = useTheme();
  const isSelected =
    project.name === "Environment"
      ? project.name === "Environment" && mode === "env"
      : project.name === "Animation" && mode === "animate";
  return (
    <Box
      sx={{
        cursor: "pointer",
      }}
      onClick={() => {
        setMode(project.name === "Environment" ? "env" : "animate");
      }}
    >
      <Box
        sx={{
          width: "12vw",
          border: "1px solid #7D1BEB",
          borderRadius: "10px",
          height: "3.5vw",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          background: isSelected ? "#7D1BEB" : "#301254",
          [theme.breakpoints.down("sm")]: {
            width: "90%",
            marginBottom: "25px",
            maxWidth: "180px",
            minWidth: "130px",
            height: "45px",
            borderRadius: "8px",
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: "700",
            fontFamily: "var(--font-f_r)",
            fontSize: "1vw",
            textTransform: "capitalize",
            textAlign: "center",
            color: "#fff",
            [theme.breakpoints.down("lg")]: {
              fontSize: "16px",
            },
            [theme.breakpoints.down("sm")]: {
              fontSize: "12px",
            },
          }}
        >
          {project.name}
        </Typography>
      </Box>
    </Box>
  );
};
