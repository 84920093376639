import { configureStore } from "@reduxjs/toolkit";
import {
  authReducer,
  userReducer,
  editorTextReducer,
  NftReducer,
} from "./slice";

// ***************************************************

const reducer = {
  auth: authReducer,
  user: userReducer,
  editorText: editorTextReducer,
  nft: NftReducer,
};

// ***************************************************

const store = configureStore({
  reducer: reducer,
  devTools: true,
});

export default store;
