import Slider from "react-slick";
import PROJECTS from "../staticData/projects.json";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useEffect, useState } from "react";
import { newActiveNft } from "../slice/nft.slice";
import { useDispatch } from "react-redux";
import "./HeroSlider.css";
import { useFetchNFTCollection } from "../hooks/useFetchNFTCollection";

const HeroSlider = ({ home = false, projects = [], heading }) => {
  const [slider, setSlider] = useState(null);
  const { palette, ...theme } = useTheme();
  const dispatch = useDispatch();
  const { ownedNFTs, isLoading } = useFetchNFTCollection();

  console.log(ownedNFTs, isLoading);
  const isMobile = useMediaQuery("(max-width:599px)");
  const next = () => {
    if (slider) {
      slider.slickNext();
    }
  };
  useEffect(() => {
    next();
  }, [projects]);
  const previous = () => {
    if (slider) {
      slider.slickPrev();
    }
  };
  const afterChange = (currentSlide) => {
    if (currentSlide === projects.length - 1) {
      previous();
    }
  };
  const getSlidesToShow = () => {
    if (projects.length > 7) {
      return home ? 7 : 4;
    } else if (projects.length === 7) {
      return home ? 6 : 4;
    } else if (projects.length === 6) {
      return home ? 6 : 3;
    } else if (projects.length === 5) {
      return 5;
    } else if (projects.length === 4) {
      return 4;
    } else if (projects.length === 3) {
      return 3;
    } else if (projects.length === 2) {
      return 2;
    } else {
      return 1;
    }
  };
  const getSlidesToScroll = () => {
    return 1;
  };
  const screen = window.innerWidth;
  const getlenght = () => {
    if (projects.length === 7) {
      return "100%";
    } else if (projects.length === 6) {
      return "100%";
    } else if (projects.length === 5) {
      return `${screen / 1.25}px`;
    } else if (projects.length === 4) {
      return `${screen / 1.5}px`;
    } else if (projects.length === 3) {
      return `${screen / 1.7}px`;
    } else if (projects.length === 2) {
      return `${screen / 2.8}px`;
    } else if (projects.length === 1) {
      return `${screen / 4}px`;
    } else {
      return "100%";
    }
  };
  const navigate = useNavigate();
  var settings = {
    adaptiveHeight: true,
    infinite: true,
    speed: 1000,
    slidesToShow: getSlidesToShow(),
    slidesToScroll: getSlidesToScroll(),
    arrows: false,
    className: "slick-slider",
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 3840,
        settings: {
          slidesToShow: getSlidesToShow(),
          slidesToScroll: getSlidesToScroll(),
        },
      },
      {
        breakpoint: 2200,
        settings: {
          slidesToShow: getSlidesToShow(),
          slidesToScroll: getSlidesToScroll(),
        },
      },
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: getSlidesToShow(),
          slidesToScroll: getSlidesToScroll(),
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
    ],
    afterChange: afterChange,
  };

  const Projects = projects.map((project, i) => (
    <Box
      key={i}
      onClick={() => {
        navigate(`${project.slug}`);
      }}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        // margin: "5px 0",
        cursor: "pointer",
        maxWidth: {
          md: "300px",
          xs: "300px",
          sm: "250px",
          lg: "250px",
          xl: "320px",
        }, // Fixed syntax here
        minHeight: "12vh",
        maxHeight: {
          xs: "200px",
          sm: "180px",
          md: "180px",
          lg: "250px",
          xl: "350px",
        }, // Fixed syntax here
        height: home ? "25vh" : "28vh",
        border: "2px solid #94E16140",
        overflow: "hidden",
        transition: "all .4s ease-in-out",
        borderRadius: "0.5vw",
        "&:hover": {
          transform: "scale(1.02)",
          ".text-banner-pro": {
            color: "#7D1BEB",
          },
        },
        "& img": {
          paddingTop: "0",
          marginTop: "0",
          width: "100%",
          height: "78%",
          objectFit: "cover",
          objectPosition: "top",
          // borderTopLeftRadius: "0.5vw",
          // borderTopRightRadius: "0.5vw",
        },
        margin: { xl: "3vh", md: "2vh" },
      }}
    >
      <img src={project.image_url} />
      <Box display="flex" p={1}>
        <Typography
          className="text-banner-pro"
          sx={{
            padding: "1px 8px",
            margin: 0,
            fontWeight: "700",
            textTransform: "capitalize",
            fontFamily: "var(--font-f_r)",
            fontSize: "1vw",
            color: palette.grey[200],
            [theme.breakpoints.down("sm")]: {
              fontSize: "22px",
            },
          }}
        >
          {project?.name || "# " + project?.identifier?.slice(0, 10)}
        </Typography>
      </Box>
    </Box>
  ));
  return (
    <Box>
      <Grid container>
        <Grid item xs={6}>
          <Box>
            <Typography
              sx={{
                fontWeight: "700",
                textTransform: "capitalize",
                fontFamily: "var(--font-f_r)",
                margin: "0 13px",
                // paddingLeft: "30px",
                color: palette.grey[200],
                fontSize: "1.5vw",
                color: palette.grey[200],
                [theme.breakpoints.down("sm")]: {
                  fontSize: "22px",
                },
              }}
            >
              {heading}
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "2em",
          }}
        >
          <Box>
            <IconButton onClick={previous} color="primary">
              <ChevronLeftIcon size={isMobile ? "12px" : "1vw"} />
            </IconButton>
            <IconButton onClick={next} color="primary">
              <ChevronRightIcon size={isMobile ? "12px" : "1vw"} />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Box width={getlenght()}>
        <Slider
          className="slick-slider"
          ref={(c) => setSlider(c)}
          {...settings}
        >
          {Projects}
        </Slider>
      </Box>
    </Box>
  );
};

export default HeroSlider;
