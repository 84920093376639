import { useTheme } from "@emotion/react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import * as React from "react";
import DashboardLayout from "../layout";
import BackButton from "../../BackButton";
import { useNavigate } from "react-router-dom";
import Header from "../../Header";
import {
  dimensionFD,
  dimensionFP,
  dimensionIP,
  dimensionIS,
  dimensionTC,
  dimensionTP,
} from "../../../staticData/images";
import { toPng } from "html-to-image";
import ShareButton from "../../ShareButton";
function Images({ style = {} }) {
  const { palette, ...theme } = useTheme();
  const [expanded, setExpanded] = React.useState(false);
  const [backgroundWidth, setBackgroundWidth] = React.useState("150px");
  const [backgroundHeight, setBackgroundHeight] = React.useState("150px");
  const elementRef = React.useRef(null);
  const [openShare, setOpenShare] = React.useState(false);
  const shareUrl = window.location.href;
  const title = "Mutant Hound";
  const htmlToImageConvert = async () => {
    const imgGenerator = document.getElementById("imgGenerator");
    const reactHandles = document.querySelectorAll(
      ".react-resizable-handle, .react-resizable-handle-nw, .react-resizable-handle-s, .react-resizable-handle-w, .react-resizable-handle-e, .react-resizable-handle-n, .react-resizable-handle-sw, .react-resizable-handle-se"
    );

    // Backup the original styles of imgGenerator and reactHandles
    const originalStyles = imgGenerator.getAttribute("style");
    const originalReactHandleStyles = {};

    reactHandles.forEach((handle) => {
      originalReactHandleStyles[handle.className] =
        handle.getAttribute("style");
      handle.style.display = "none";
    });

    // imgGenerator.style.left = "25vw";
    // imgGenerator.style.right = "30vw !important";

    try {
      const dataUrl = await toPng(elementRef.current, { cacheBust: false });

      imgGenerator.setAttribute("style", originalStyles);

      reactHandles.forEach((handle) => {
        const originalStyle = originalReactHandleStyles[handle.className];
        if (originalStyle) {
          handle.setAttribute("style", originalStyle);
          handle.style.display = "block";
        }
      });

      const link = document.createElement("a");
      link.download = "nft.png";
      link.href = dataUrl;
      link.click();
    } catch (err) {
      // Restore the original styles of imgGenerator on error
      imgGenerator.setAttribute("style", originalStyles);

      console.log(err);
    }
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleDimensions = (dim) => {
    const aspectRatio = dim; // 9:16 aspect ratio
    const screenWidth = window.innerWidth / 2;
    const screenHeight = window.innerHeight / 2;
    if (screenWidth * aspectRatio <= screenHeight) {
      // Width is the limiting factor
      setBackgroundWidth(screenWidth);
      setBackgroundHeight(screenWidth * aspectRatio);
    } else {
      // Height is the limiting factor
      setBackgroundHeight(screenHeight);
      setBackgroundWidth(screenHeight / aspectRatio);
    }
  };
  const handleTemplateSelect = (selectedDimension) => {
    handleDimensions(selectedDimension);
  };
  const navigate = useNavigate();
  const TemplateView = ({ img, title, dimension }) => {
    return (
      <Box
        sx={{
          borderRadius: "10px",
          width: "100%",
          padding: "10px",
          background: "#4d7532",
          alignItems: "center",
          marginBottom: "10px",
          cursor: "pointer",
        }}
      >
        <img style={{ width: "100%" }} src={img} />
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontFamily: "var(--font-i_r)",
              fontSize: "0.5vw",
              textTransform: "capitalize",
              color: "#fff",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontFamily: "var(--font-i_r)",
              fontSize: "0.5vw",
              textTransform: "capitalize",
              color: "#fff",
            }}
          >
            {dimension}
          </Typography>
        </Box>
      </Box>
    );
  };
  return (
    <Box
      sx={{
        backgroundPosition: "bottom",
        backgroundSize: "cover",
        backgroundImage: "#000",
      }}
    >
      <Header />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <Box
          sx={{
            width: "96%",
            marginTop: "4vw",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            height: "87%",

            backdropFilter: "blur(10px)",
            padding: 2,
          }}
        >
          <Box
            sx={{
              width: "15%",
              justifyItems: "flex-start",
              height: "100%",
              fontWeight: "800",
              borderRadius: "15px",
              background:
                "linear-gradient(113deg, rgba(30, 66, 6, 0.85) 10.38%, rgba(31, 31, 31, 0.75) 100%),linear-gradient(0deg, #94E161, #94E161)",
              border: "1px solid #94E161",
              padding: "1vw",
              alignItems: "flex-start",
            }}
          >
            <BackButton onClick={() => navigate(-1)} />
            <Box
              sx={{
                cursor: "pointer",
                border: "none",
              }}
            >
              <Accordion
                sx={{
                  background: "#94E161",
                  marginBottom: "10px",
                  borderRadius: "5px",
                }}
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={
                    <img
                      style={{ width: "0.5vw" }}
                      src="https://ishrostorage.blob.core.windows.net/mutant-3d/DownArrow.png"
                    />
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography
                    sx={{
                      marginLeft: "10px",
                      fontFamily: "var(--font-i_r)",
                      fontSize: "0.8vw",
                      fontWeight: "600",
                      textTransform: "capitalize",
                      color: "#fff",
                    }}
                  >
                    Twitter
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      borderRadius: "15px",
                      width: "100%",
                      background: "#94E161",
                      alignItems: "center",
                    }}
                  >
                    <div
                      onClick={() => handleTemplateSelect(1 / 3)} // Pass the dimension value as a string
                    >
                      <TemplateView
                        img={dimensionTC}
                        title="twitter cover"
                        dimension="1500 X 500"
                      />
                    </div>
                    <div
                      onClick={() => handleTemplateSelect(16 / 9)} // Pass the dimension value as a string
                    >
                      <TemplateView
                        img={dimensionTC}
                        title="twitter post"
                        dimension="1600 X 900"
                      />
                    </div>
                    <div
                      onClick={() => handleTemplateSelect(1 / 1)} // Pass the dimension value as a string
                    >
                      <TemplateView
                        img={dimensionTP}
                        title="twitter profile"
                        dimension="400 X 400"
                      />
                    </div>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  background: "#94E161",
                  marginBottom: "10px",
                  borderRadius: "5px",
                }}
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={
                    <img
                      style={{ width: "0.5vw" }}
                      src="https://ishrostorage.blob.core.windows.net/mutant-3d/DownArrow.png"
                    />
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography
                    sx={{
                      marginLeft: "10px",
                      fontFamily: "var(--font-i_r)",
                      fontSize: "0.8vw",
                      fontWeight: "600",
                      textTransform: "capitalize",
                      color: "#fff",
                    }}
                  >
                    Instagram
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      borderRadius: "15px",
                      width: "100%",
                      background: "#94E161",
                      alignItems: "center",
                    }}
                  >
                    <div
                      onClick={() => handleTemplateSelect(4 / 5)} // Pass the dimension value as a string
                    >
                      <TemplateView
                        img={dimensionIP}
                        title="instagram post"
                        dimension="1080 X 1350"
                      />
                    </div>
                    <div
                      onClick={() => handleTemplateSelect(1 / 1)} // Pass the dimension value as a string
                    >
                      <TemplateView
                        img={dimensionIS}
                        title="instagram square"
                        dimension="1080 X 1080"
                      />
                    </div>
                    <div
                      onClick={() => handleTemplateSelect(13 / 7)} // Pass the dimension value as a string
                    >
                      <TemplateView
                        img={dimensionIS}
                        title="instagram story"
                        dimension="438 X 780"
                      />
                    </div>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  background: "#94E161",
                  marginBottom: "10px",
                  borderRadius: "5px",
                }}
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={
                    <img
                      style={{ width: "0.5vw" }}
                      src="https://ishrostorage.blob.core.windows.net/mutant-3d/DownArrow.png"
                    />
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography
                    sx={{
                      marginLeft: "10px",
                      fontFamily: "var(--font-i_r)",
                      fontSize: "0.8vw",
                      fontWeight: "600",
                      textTransform: "capitalize",
                      color: "#fff",
                    }}
                  >
                    Facebook
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      borderRadius: "15px",
                      width: "100%",
                      background: "#94E161",
                      alignItems: "center",
                    }}
                  >
                    <div
                      onClick={() => handleTemplateSelect(40 / 21)} // Pass the dimension value as a string
                    >
                      <TemplateView
                        img={dimensionFP}
                        title="Facebook post"
                        dimension="1200 X 630"
                      />
                    </div>
                    <div
                      onClick={() => handleTemplateSelect(1 / 1)} // Pass the dimension value as a string
                    >
                      <TemplateView
                        img={dimensionFD}
                        title="Facebook profile"
                        dimension="850 X 850"
                      />
                    </div>
                    <div
                      onClick={() => handleTemplateSelect(9 / 16)} // Pass the dimension value as a string
                    >
                      <TemplateView
                        img={dimensionFD}
                        title="Facebook story"
                        dimension="1080 X 1920"
                      />
                    </div>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
          <Box
            sx={{
              width: "85%",
              background: "#000",
              border: "1px solid #29401A",
              marginLeft: "20px",
              height: "100%",
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              ref={elementRef}
              id="imgGenerator"
              style={{
                display: "flex",
                backgroundColor: "#171717",
                overflow: "hidden",
                margin: 0,
                alignItems: "center",
                justifyContent: "center",
                padding: 0,
                height: `${backgroundHeight}px`,
                width: `${backgroundWidth}px`,
              }}
            >
              <img
                src={
                  "https://ishrostorage.blob.core.windows.net/mutant-3d/Houndinlab.png"
                }
                style={{
                  userSelect: "none",
                  zIndex: 1,
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
                draggable={false}
              />
            </div>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {openShare && (
                <div
                  style={{
                    position: "absolute",
                    margin: "-3.5vw",
                    backgroundColor: "#1b1c1a",
                    padding: "8px",
                    boxShadow: "#1b1c1a",
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "4vw",
                    width: "14vw",
                    alignItems: "center",
                    height: "2.5vw",
                  }}
                >
                  <ShareButton
                    platform="twitter"
                    shareUrl={shareUrl}
                    title={title}
                  />
                  <ShareButton
                    platform="facebook"
                    shareUrl={shareUrl}
                    title={title}
                  />
                  <ShareButton
                    platform="instagram"
                    shareUrl={shareUrl}
                    title={title}
                  />
                  <ShareButton
                    platform="whatsapp"
                    shareUrl={shareUrl}
                    title={title}
                  />
                </div>
              )}
              <ProjectButton
                name="share"
                onClick={() => {
                  setOpenShare(!openShare);
                }}
              />
              <ProjectButton name="Download" onClick={htmlToImageConvert} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Images;

const ProjectButton = ({ name, onClick }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        cursor: "pointer",
        margin: "1vw",
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          width: "12vw",
          border: "1px solid #1e2e13",
          borderRadius: "10px",
          height: "65px",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          background: "",
          color: "white",
          "&:hover": {
            color: "#000",
            background: "#94E161",
          },
          [theme.breakpoints.down("sm")]: {
            width: "90%",
            marginBottom: "25px",
            maxWidth: "180px",
            minWidth: "130px",
            height: "45px",
            borderRadius: "8px",
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: "700",
            fontFamily: "var(--font-f_r)",
            fontSize: "1vw",
            textTransform: "capitalize",
            textAlign: "center",
            [theme.breakpoints.down("lg")]: {
              fontSize: "16px",
            },
            [theme.breakpoints.down("sm")]: {
              fontSize: "12px",
            },
          }}
        >
          {name}
        </Typography>
      </Box>
    </Box>
  );
};
