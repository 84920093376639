import axios from "./axios";

export const contactUs = (body) => {
  return new Promise((resolve, reject) => {
    axios
      .post("contacts", body)
      .then((response) => {
        if (response?.status === 201) {
          resolve(response?.data);
        }
      })
      .catch((error) => {
        reject(error.message);
      });
  });
};
