// color design tokens export
import { createTheme } from "@mui/material/styles";

export const tokens = {
  grey: {
    100: "#f6f6f6",
    200: "#ffffff",
    300: "#070c3c00",
    400: "#F9F9F9",
    500: "#1e1e1e80",
    600: "#a7a7a7",
    700: "#F3F3F3",
    800: "#48494e",
    900: "#242427",
  },
  primary: {
    // light blue
    100: "#7D1BEB",
    200: "#EAD30C6B",
    300: "#67aafc",
    400: "#348dfb",
    500: "#0171fa",
    600: "#015ac8",
    700: "#014496",
    800: "#002d64",
    900: "#001732",
  },
  secondary: {
    // Black
    100: "#94E161",
    200: "#1e1e1eab",
    300: "#1e1e1e99",
    400: "#1e1e1e8a",
    500: "#1e1e1ecc",
    600: "#1e1e1e82",
    700: "#1E1E1E",
    800: "#11154B",
    900: "#000000",
  },
  text: {
    default: "#C4C4C4",
    primary: "#94E161",
  },
};

const breakpoints = {
  values: {
    xs: 0,
    sm: 700,
    md: 960,
    lg: 1280,
    xl: 2500,
  },
};

// mui theme settings
export const themeSettings = createTheme({
  palette: {
    // palette values for dark mode
    grey: {
      ...tokens.grey,
      main: tokens.grey[200],
    },
    primary: {
      ...tokens.primary,
      main: tokens.primary[100],
    },
    secondary: {
      ...tokens.secondary,
      main: tokens.secondary[100],
    },
    neutral: {
      ...tokens.grey,
    },
    background: {
      default: tokens.grey[100],
    },
    color: {
      default: tokens.secondary[500],
    },
    font: {
      default: tokens.text.default,
      primary: tokens.text.primary,
      white: tokens.grey[200],
    },
  },
  // fontSize: 16,

  h1: {
    fontSize: "30px",

    [`@media (min-width:${breakpoints.values["sm"]}px)`]: {
      fontSize: "14px", // Example of responsive font size
    },
    [`@media (min-width:${breakpoints.values["md"]}px)`]: {
      fontSize: "16px", // Example of responsive font size
    },
    [`@media (min-width:${breakpoints.values["lg"]}px)`]: {
      fontSize: "20px", // Example of responsive font size
    },
    [`@media (min-width:${breakpoints.values["xl"]}px)`]: {
      fontSize: "26px", // Example of responsive font size
    },
  },
  // Define font sizes for other typography variants...

  breakpoints,
});
