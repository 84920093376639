import { useTheme } from "@emotion/react";
import { Avatar, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import { BackButton, Header } from "../components";

import { useNavigate, useParams } from "react-router-dom";
import FooterHome from "../components/FooterHome";
import "../components/shared.css";
import projectsData from "../staticData/projects.json";
import { animation, file, image, library, still } from "../staticData/images";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import PROJECTS from "../staticData/projects.json";
import { ThreeD, TwoD } from "../assets/images/icons/Icons";
import LookupTable from "../constants/lookup_table.json";
const NftDetails = () => {
  const { palette, ...theme } = useTheme();
  const { id } = useParams();
  const specificImageData = PROJECTS.find((project) => project.slug == id);
  const [nftAddress, setNftAddress] = useState({
    holder: "",
    id: "",
    contractAddress: "",
  });
  const [traits, setTraits] = useState([]);
  const [thred, set3d] = useState(false);

  const [tabButtons, setTabButtons] = useState([
    {
      name: "Traits",
      selected: true,
    },
    {
      name: "Delegations",
      selected: false,
    },
  ]);
  const isMobile = useMediaQuery("(max-width:599px)");
  const getNftMetaData = async () => {
    try {
      const res = await axios.get(
        `https://api.otherdex.io/hounds/${id}/traitStats`
      );
      if (res) {
        setNftAddress(res?.data[Object.keys(res?.data)[0]]);
        setTraits(res?.data?.traitStats);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const navigate = useNavigate();

  useEffect(() => {
    getNftMetaData();
  }, []);
  const RenderImage = ({ name, image, goto }) => {
    return (
      <Box
        onClick={() => navigate(goto)}
        sx={{
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
          width: "90%",
          height: "24%",
          border: "1px solid #1E4206D9",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "10px",
          padding: "0.5vw",
          "&:hover": {
            background: "#94E16140",
          },
          [theme.breakpoints.down("sm")]: {
            width: "23%",
            height: "50px",
            p: 1,
          },
        }}
      >
        <img style={{ width: isMobile ? "20px" : "2vw" }} src={image} />
        <Typography
          sx={{
            verticalAlign: "center",
            fontFamily: "var(--font-f_r)",
            textTransform: "capitalize",
            fontSize: "0.8vw",
            color: "#fff",
            [theme.breakpoints.down("sm")]: {
              fontSize: "10px",
            },
          }}
        >
          {name}
        </Typography>
      </Box>
    );
  };
  return (
    <Box
      sx={{
        zIndex: 1000,
        height: "100vh",
        overflow: "hidden",
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        backdropFilter: "blur(10px)",
        background:
          "url('https://ishrostorage.blob.core.windows.net/mutant-3d/home-banner.png')",
        [theme.breakpoints.down("sm")]: {
          height: "100%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        },
      }}
    >
      <Header hamburger={true} />
      {!isMobile && (
        <div
          style={{
            position: "absolute",
            top: "7vw",
            left: "2vw",
            [theme.breakpoints.down("sm")]: {
              top: "15vw",
            },
          }}
        >
          <BackButton
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          height: "70vh",
          marginInline: "auto",
          width: "95vw",
          mt: "20vh",
          [theme.breakpoints.down("sm")]: {
            mt: "10vh",
            height: "100%",
            flexDirection: "column",
          },
        }}
      >
        <Box
          sx={{
            border: "1px solid #1E4206D9",
            width: "10%",
            display: "flex",
            alignItems: "center",
            height: "100%",
            flexDirection: "column",
            margin: "auto",
            backdropFilter: "blur(10px)",
            justifyContent: "space-between",
            p: 1,
            borderRadius: "0.8vw",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              flexDirection: "row",
              border: "none",
              p: 0,
              mb: 1,
            },
          }}
        >
          <RenderImage image={animation} name="Animations" goto="animation" />
          <RenderImage image={still} name="Still Pose" goto="stillPose" />
          <RenderImage image={file} name="File" goto="file" />
          <RenderImage image={library} name="Claim" goto={`/claim/${id}`} />
        </Box>
        <Box
          sx={{
            border: "1px solid #1E4206D9",
            width: "85%",
            display: "flex",
            alignItems: "center",
            height: "100%",
            margin: "auto",
            backdropFilter: "blur(10px)",
            justifyContent: "space-between",
            minWidth: "450px",
            padding: 1,
            borderRadius: "0.8vw",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
              width: "100%",
              padding: 0,
              minWidth: "300px",
              border: "none",
            },
          }}
        >
          <Box
            sx={{
              width: "30%",
              height: "98%",
              borderRadius: "20px",
              padding: "20px",
              border: "1px solid #2b2b2b",
              [theme.breakpoints.down("sm")]: {
                width: "96%",
                padding: "6px",
                borderRadius: "8px",
              },
            }}
          >
            {isMobile && (
              <Typography
                className="menus-text"
                sx={{
                  fontWeight: "700",
                  textTransform: "capitalize",
                  fontFamily: "var(--font-f_r)",
                  fontSize: "1.5vw",
                  // marginTop: "0.9vw",
                  color: "#fff",
                  opacity: 0.7,
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "20px",
                  },
                }}
              >
                MUTANT HOUNDS
              </Typography>
            )}
            <Typography
              className="menus-text"
              sx={{
                fontWeight: "700",
                textTransform: "capitalize",
                fontFamily: "var(--font-f_r)",
                fontSize: "1.5vw",
                // marginTop: "0.9vw",
                color: "#fff",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "20px",
                  mb: 1,
                },
              }}
            >
              {specificImageData?.name}
            </Typography>

            <Box sx={{ borderRadius: "0.5vw", background: "#95959529" }}>
              {specificImageData?.image_url && (
                // <GlassMagnifier
                //   magnifierBorderSize={3}
                //   magnifierSize="60%"
                //   style={{
                //     width: "100%",
                //     objectFit: "contain",
                //     borderTopLeftRadius: "0.5vw",
                //     borderTopRightRadius: "0.5vw",
                //     overflow: "hidden",
                //   }}
                //   imageSrc={specificImageData?.nftImage?.image}
                //   imageAlt={specificImageData?.nftImage?.id}
                //   largeImageSrc={specificImageData?.nftImage?.image} // Optional
                // />
                <>
                  <Box sx={{ position: "absolute", m: 1 }}>
                    <Box
                      onClick={() => set3d(false)}
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      <TwoD color={thred ? "#fff" : "#94E161"} />
                    </Box>
                    <Box
                      onClick={() => set3d(true)}
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      <ThreeD color={thred ? "#94E161" : "#fff"} />
                    </Box>
                  </Box>
                  <img
                    style={{
                      width: "100%",
                      objectFit: "contain",
                      borderTopLeftRadius: "0.5vw",
                      borderTopRightRadius: "0.5vw",
                      overflow: "hidden",
                    }}
                    src={
                      thred
                        ? LookupTable[id]?.prerenderURL
                        : specificImageData?.image_url
                    }
                  />
                </>
              )}

              <Box
                display="flex"
                sx={{
                  flexWrap: "wrap",
                  flexDirection: "row",
                  my: 2,
                  mx: 1,
                  [theme.breakpoints.down("sm")]: {
                    flexDirection: "column",
                  },
                }}
                justifyContent="space-between"
              >
                <Box pb={2} display="flex" alignItems="center">
                  <Avatar
                    sx={{
                      width: "26px",
                      height: "26px",
                    }}
                    src={specificImageData?.image_url}
                  />
                  <Typography
                    sx={{
                      fontWeight: "700",
                      textTransform: "capitalize",
                      fontFamily: "var(--font-i_r)",
                      fontSize: "12px",
                      marginLeft: "10px",
                      color: palette.primary[100],
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "12px",
                      },
                    }}
                  >
                    {nftAddress?.holder &&
                      nftAddress?.holder.slice(0, 4) +
                        "....." +
                        nftAddress?.holder.slice(
                          nftAddress?.holder?.length - 4,
                          nftAddress?.holder?.length
                        )}
                  </Typography>
                </Box>
                <Box
                  pb={2}
                  justifyContent="space-between"
                  display="flex"
                  alignItems="center"
                  sx={{
                    cursor: "pointer",
                    width: "60%",
                    [theme.breakpoints.down("sm")]: {
                      width: "100%",
                    },
                  }}
                >
                  <img
                    style={{ marginInline: "4px" }}
                    onClick={() =>
                      window.open(
                        `https://blur.io/asset/${nftAddress?.contractAddress}/${id}`,
                        "_blank"
                      )
                    }
                    src="https://storage.googleapis.com/3d-container/NFTDetailPageIcons/blur.svg"
                  />
                  <img
                    style={{ marginInline: "4px" }}
                    onClick={() =>
                      window.open(
                        `https://looksrare.org/collections/${nftAddress?.contractAddress}/${id}`,
                        "_blank"
                      )
                    }
                    src="https://storage.googleapis.com/3d-container/NFTDetailPageIcons/looks%20rare.svg"
                  />
                  <img
                    style={{ marginInline: "4px" }}
                    onClick={() =>
                      window.open(
                        `https://opensea.io/assets/ethereum/${nftAddress?.contractAddress}/${id}`,
                        "_blank"
                      )
                    }
                    src="https://storage.googleapis.com/3d-container/NFTDetailPageIcons/opensea.svg"
                  />
                  <img
                    style={{ marginInline: "4px" }}
                    onClick={() =>
                      window.open(
                        `https://www.otherdex.io/mutant-hounds/${id}`,
                        "_blank"
                      )
                    }
                    src="https://storage.googleapis.com/3d-container/NFTDetailPageIcons/otherdeed.svg"
                  />
                  <img
                    style={{ marginInline: "4px" }}
                    onClick={() =>
                      window.open(
                        `https://x2y2.io/eth/${nftAddress?.contractAddress}/${id}`,
                        "_blank"
                      )
                    }
                    src="https://storage.googleapis.com/3d-container/NFTDetailPageIcons/x2y2.svg"
                  />
                  <img
                    style={{ marginInline: "4px" }}
                    onClick={() =>
                      window.open(
                        `https://app.other.page/${nftAddress?.contractAddress}/${id}?hideMarqueeNav=true/`,
                        "_blank"
                      )
                    }
                    src="https://storage.googleapis.com/3d-container/NFTDetailPageIcons/otherpage-dark.svg"
                  />
                </Box>
              </Box>
            </Box>
            <Box
              mt={1}
              display="flex"
              borderRadius="0.5vw"
              minHeight="50px"
              height="3vw"
              alignItems="center"
              justifyContent="center"
              sx={{ background: "#95959529", cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(window.location.href);
                enqueueSnackbar("Copied!", {
                  variant: "success",
                });
              }}
            >
              <Typography
                sx={{
                  fontFamily: "var(--font-i_r)",
                  fontSize: "1vw",
                  mr: 2,
                  color: "#fff",
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "14px",
                  },
                }}
              >
                Copy
              </Typography>
              <img src="https://ishrostorage.blob.core.windows.net/mutant-3d/Copy.svg" />
            </Box>
          </Box>
          <Box
            sx={{
              width: "68%",
              height: "98%",
              [theme.breakpoints.down("sm")]: {
                width: "96%",
                my: 1,
                height: "100%",
              },
            }}
          >
            <Box
              sx={{
                borderRadius: "10px",
                background: "#181818",
                padding: "14px",
                height: "13%",
                display: "flex",
                justifyContent: "space-between",
                border: "1px solid #2b2b2b",
                [theme.breakpoints.down("sm")]: {
                  height: "70px",
                  padding: "8px",
                  border: "none",
                  background: "none",
                },
              }}
            >
              {tabButtons.map((btn) => {
                return (
                  <Box
                    onClick={() => {
                      const updated = tabButtons.map((bt) => {
                        return bt.name === btn.name
                          ? {
                              ...bt,
                              selected: true,
                            }
                          : {
                              ...bt,
                              selected: false,
                            };
                      });
                      setTabButtons(updated);
                    }}
                    sx={{
                      width: "45%",
                      cursor: "pointer",
                      background: btn.selected ? "#95959529" : "",
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "700",
                        textTransform: "capitalize",
                        fontFamily: "var(--font-f_r)",
                        textAlign: "center",
                        fontSize: "1.1vw",
                        color: "#fff",
                        [theme.breakpoints.down("sm")]: {
                          fontSize: "14px",
                        },
                      }}
                    >
                      {btn.name}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
            <Box
              sx={{
                borderRadius: "10px",
                padding: "14px",
                mt: 2,
                height: "85%",
                background: "#181818",
                border: "1px solid #2b2b2b",
                [theme.breakpoints.down("sm")]: {
                  mt: 1,
                  height: "100%",
                },
              }}
            >
              {tabButtons.find((tab) => tab.selected)?.name === "Traits" ? (
                <Box sx={{ overflowY: "scroll", height: "90%" }}>
                  <Box
                    display="flex"
                    borderBottom="1px solid #95959529"
                    pb={1}
                    justifyContent="space-between"
                  >
                    {["Traits", "Floor", "Holders", "Listed", "Supply"].map(
                      (trait, i) => {
                        return (
                          <Typography
                            sx={{
                              width: i === 0 ? "20%" : "10%",
                              fontFamily: "var(--font-f_r)",
                              fontSize: "1vw",
                              color: "#fff",
                              [theme.breakpoints.down("sm")]: {
                                fontSize: "10px",
                              },
                            }}
                          >
                            {trait}
                          </Typography>
                        );
                      }
                    )}
                  </Box>
                  {traits?.length > 0 ? (
                    <>
                      {traits
                        .filter(
                          (trait) => !String(trait?.traitVal)?.includes("http")
                        )
                        ?.map((trait) => {
                          return (
                            <Box
                              display="flex"
                              my={2}
                              borderBottom="1px solid #95959529"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Box width="20%">
                                <Typography
                                  sx={{
                                    fontFamily: "var(--font-i_r)",
                                    fontSize: "0.6vw",
                                    color: "#C4C4C4",
                                    [theme.breakpoints.down("sm")]: {
                                      fontSize: "10px",
                                    },
                                  }}
                                >
                                  {trait?.traitType}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontFamily: "var(--font-i_r)",
                                    fontSize: "0.6vw",
                                    color: "#fff",
                                    [theme.breakpoints.down("sm")]: {
                                      fontSize: "10px",
                                    },
                                  }}
                                >
                                  {trait?.traitVal}
                                </Typography>
                              </Box>
                              <Typography
                                sx={{
                                  width: "10%",
                                  fontFamily: "var(--font-i_r)",
                                  fontSize: "0.8vw",
                                  color: palette.primary[100],
                                  [theme.breakpoints.down("sm")]: {
                                    fontSize: "10px",
                                  },
                                }}
                              >
                                {(
                                  trait?.floorPrice / 1000000000000000000
                                ).toFixed(3)}
                              </Typography>
                              <Typography
                                sx={{
                                  width: "10%",
                                  fontFamily: "var(--font-i_r)",
                                  fontSize: "0.8vw",
                                  color: "#fff",
                                  [theme.breakpoints.down("sm")]: {
                                    fontSize: "10px",
                                  },
                                }}
                              >
                                {trait?.holders}
                              </Typography>
                              <Typography
                                sx={{
                                  width: "10%",
                                  fontFamily: "var(--font-i_r)",
                                  fontSize: "0.8vw",
                                  color: "#fff",
                                  [theme.breakpoints.down("sm")]: {
                                    fontSize: "10px",
                                  },
                                }}
                              >
                                {trait?.listings}%
                              </Typography>
                              <Typography
                                sx={{
                                  width: "10%",
                                  fontFamily: "var(--font-i_r)",
                                  fontSize: "0.8vw",
                                  color: "#fff",
                                  [theme.breakpoints.down("sm")]: {
                                    fontSize: "10px",
                                  },
                                }}
                              >
                                {trait?.count}
                              </Typography>
                            </Box>
                          );
                        })}
                    </>
                  ) : (
                    <Typography
                      sx={{
                        fontFamily: "var(--font-f_r)",
                        fontSize: "1vw",
                        color: "#fff",
                        mt: 2,
                        textAlign: "center",
                        [theme.breakpoints.down("sm")]: {
                          fontSize: "14px",
                        },
                      }}
                    >
                      No Data Available
                    </Typography>
                  )}
                </Box>
              ) : (
                <Box sx={{ height: "90%" }}></Box>
              )}
              <Box
                sx={{
                  marginBottom: "10px",
                  display: "flex",
                  borderTop: "1px solid #1E4206D9",
                  pt: 1,
                  width: "100%",
                  paddingRight: "10px",
                  justifyContent: "flex-end",
                }}
              >
                <img
                  style={
                    {
                      // position: "absolute",
                      // bottom: 0,
                      // right: 0,
                      // float: "right",
                    }
                  }
                  width={isMobile ? "30%" : "13%"}
                  src="https://storage.googleapis.com/3d-container/Poweredbyotherdexlogo.png"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <FooterHome /> */}
    </Box>
  );
};

export default NftDetails;
