import { Box, Typography, useMediaQuery } from "@mui/material";
import { backSvg } from "../assets/images";
import { useTheme } from "@emotion/react";

const BackButton = ({ title = "Back", onClick, backgroundColor }) => {
  const { palette, ...theme } = useTheme();
  const isMobile = useMediaQuery("(max-width:599px)");

  return (
    <Box
      onClick={onClick}
      display="flex"
      sx={{
        cursor: "pointer",
        zIndex: 1000,
        transition: "all .4s ease-in-out",
        ".back-button-text": {},
        // "&:hover": {
        //   marginLeft: "2px",
        //   ".back-button-text": {
        //     color: palette.primary[100],
        //   },
        // },
      }}
      width="4.5vw"
      alignItems="center"
      justifyContent="flex-start"
    >
      <img
        style={{
          width: isMobile ? "20px" : "0.7vw",
          color: "#ffffff",
          userSelect: "none",
          MozUserSelect: "none",
          WebkitUserSelect: "none",
          msUserSelect: "none",
        }}
        src="https://ishrostorage.blob.core.windows.net/container-3d/Back Arrow.svg"
      />
      <Typography
        className="back-button-text"
        sx={{
          fontWeight: "700",
          textTransform: "capitalize",
          fontFamily: "var(--font-f_r)",
          marginLeft: "15px",
          fontSize: "1.3vw",
          color: backgroundColor ? backgroundColor : palette.grey[200],
          [theme.breakpoints.down("sm")]: {
            fontSize: "22px",
          },
          userSelect: "none",
          MozUserSelect: "none",
          WebkitUserSelect: "none",
          msUserSelect: "none",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default BackButton;
