import { useTheme } from "@emotion/react";
import { Button, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Header } from "../components";
import HeroSlider from "../components/HeroSlider";
import { homeBanner } from "../staticData/images";
import PROJECTS from "../staticData/projects.json";
import FooterHome from "../components/FooterHome";
import Slider from "react-slick";
import { useAccount } from "wagmi";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import "../index.css";
const Home = () => {
  const { palette, ...theme } = useTheme();
  const { openConnectModal } = useConnectModal();
  const { isConnected, isDisconnected } = useAccount();
  const navigate = useNavigate();
  const handleConnect = () => {
    openConnectModal();
  };
  const isMobile = useMediaQuery("(max-width:599px)");
  return (
    <Box
      sx={{
        minHeight: "100vh",
        width: "100vw",
        [theme.breakpoints.down("sm")]: {
          minHeight: "80vh",
        },
      }}
    >
      <Header hamburger={true} />
      <Box
        component="section"
        sx={{
          minHeight: "75vh",
          width: "100%",
          marginBottom: "-10vw",
          placeItems: "center",
          background:
            "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(55,55,55,0.6811099439775911) 8%, rgba(99,99,99,0) 23%, rgba(255,255,255,0) 50%, rgba(119,119,119,0) 77%, rgba(51,51,51,0.4455357142857143) 92%, rgba(0,0,0,0) 100%, rgba(0,212,255,0) 100%)",

          "& img": {
            position: "absolute",
            left: "0",
            top: "0",
            width: "100%",
            height: "95%",
            objectFit: "cover",

            zIndex: -1,
          },
          [theme.breakpoints.down("sm")]: {
            minHeight: "55vh",
          },
        }}
      >
        <img src={homeBanner} />
      </Box>
      {!isConnected && (
        <Box
          component="section"
          sx={{
            minHeight: "95vh",
            width: "100%",
            marginBottom: "",
            placeItems: "center",
            background: "rgba(0, 0, 0, 0.7)",
            position: "fixed",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backdropFilter: "blur(10px)",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontSize: "40px",
                fontWeight: 800,
                textAlign: "center",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "22px",
                },
              }}
            >
              WELCOME TO THE 3D HOUND STUDIO
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                marginBottom: "15px",
                fontSize: "1.5rem",
                fontWeight: "500 !important",
                textAlign: "center",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "14px",
                },
              }}
            >
              Claim or re-claim your 3D Mutant Hounds, connect wallet to start
            </Typography>

            <Button
              variant="contained"
              color="primary"
              onClick={handleConnect}
              sx={{
                borderRadius: "100px", // Adjust the value as needed
                fontWeight: "900",
                fontFamily: "F37-Regular",
                fontSize: "1.25rem",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingRight: "50px",
                paddingLeft: "50px",

                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#000",
                },
                [theme.breakpoints.down("sm")]: {
                  fontSize: "14px",
                },
              }}
            >
              CONNECT WALLET
            </Button>
          </div>
        </Box>
      )}
      {isConnected && (
        <Box>
          {isMobile ? (
            <Box
              sx={{
                position: "absolute",
                top: "17vw",
                overflowY: "scroll",
                height: "70vh",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  textTransform: "capitalize",
                  fontFamily: "var(--font-f_r)",
                  margin: "0 13px",
                  // paddingLeft: "30px",
                  color: palette.grey[200],
                  fontSize: "22px",
                }}
              >
                Your Hounds:
              </Typography>
              <Box
                sx={{
                  width: "100%",

                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {PROJECTS.map((project, i) => (
                  <Box
                    key={i}
                    onClick={() => {
                      navigate(`minting/${project.slug}`);
                    }}
                    sx={{
                      margin: "10px",
                      cursor: "pointer",
                      minWidth: "130px",
                      maxWidth: "170px",
                      width: "42%",
                      // maxWidth: "12vw",
                      backdropFilter: "blur(7px)",
                      backgroundColor: "rgba(0, 0, 0, 0.2)",
                      height: "220px",
                      border: "1px solid #94E161",
                      transition: "all .4s ease-in-out",
                      borderRadius: "10px",
                      ".text-banner-pro": {},
                      "&:hover": {
                        transform: "scale(1.02)",
                        ".text-banner-pro": {
                          color: "#94E161",
                        },
                      },
                      "& img": {
                        width: "100%",
                        height: "80%",
                        objectFit: "cover",
                        borderRadius: "8px",
                        objectPosition: "top", // Set objectPosition to "top"
                        // marginInline: "auto",
                        display: "flex",
                        // borderRadius: "10px",
                      },
                    }}
                  >
                    <img src={project.image_url} />
                    <Box display="flex" p={1}>
                      <Typography
                        className="text-banner-pro"
                        sx={{
                          padding: "1px 8px",
                          margin: 0,
                          fontWeight: "700",
                          textTransform: "capitalize",
                          fontFamily: "var(--font-f_r)",
                          fontSize: "1vw",
                          color: palette.grey[200],
                          [theme.breakpoints.down("sm")]: {
                            fontSize: "22px",
                          },
                        }}
                      >
                        {project?.name ||
                          "# " + project?.identifier?.slice(0, 10)}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          ) : (
            <HeroSlider
              home={true}
              projects={PROJECTS}
              heading="Your Hounds:"
            />
          )}
        </Box>
      )}
      {/* <FooterHome /> */}
    </Box>
  );
};

export default Home;

const HomeSlider = () => {
  const { palette, ...theme } = useTheme();
  var settings = {
    adaptiveHeight: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    style: {
      padding: "0px !important",
      margin: "0px !important",
    },
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <Slider className="slick-slidd" {...settings}>
      {PROJECTS.map((project) => {
        return (
          <Box
            sx={{
              padding: 0,
              margin: 0,
              // minHeight: "80vh",
              // placeItems: "center",
              // width: "100%",
              height: "53vh",
              background:
                "linear-gradient( to bottom,rgba(0, 0, 0, 1) 0%,rgba(0, 0, 0, 0.2) 10%, /* Smooth start at the top */rgba(0, 0, 0, 0) 20%,rgba(0, 0, 0, 0) 80%,rgba(0, 0, 0, 0.2) 90%, /* Smooth end at the bottom */rgba(0, 0, 0, 1) 100%)",
              "& img": {
                position: "relative",
                // left: "0",
                // top: "0",
                width: "100%",
                height: "100%",
                // marginTop: "2px",
                objectFit: "cover",
                objectPosition: "top", // Set objectPosition to "top"
                // marginInline: "auto",
                display: "flex",
                zIndex: -10000,
              },
            }}
          >
            <img src={project?.desktop_banner} />
            <Box
              sx={{
                marginTop: "-150px",
                marginLeft: "40px",
                paddingBottom: "11px",
                // marginTop: "-150px",
                // paddingBottom: "100px",
                // [theme.breakpoints.down("sm")]: {
                //   alignSelf: "baseline",
                // },
              }}
            >
              <Typography
                sx={{
                  padding: 0,
                  margin: 0,
                  fontWeight: "500",
                  textTransform: "capitalize",
                  fontFamily: "var(--font-f_r)",
                  fontSize: "3.5vw",
                  color: palette.grey[200],
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "30px",
                  },
                }}
              >
                {project.name}
              </Typography>
              <Button
                sx={{
                  color: "yellow !important",
                  backgroundColor: "#000000EB",
                  border: "2px solid #FFFFFFD4",
                  fontSize: "0.6vw",
                  height: "3em",
                  fontWeight: "700",
                  borderRadius: "8px",
                  marginBottom: "50px",
                  textTransform: "uppercase",
                  fontWeight: "Bold",
                  "&:hover": {
                    borderColor: "#FFFFFFD4",
                  },
                  [theme.breakpoints.down("sm")]: {
                    width: "12em",
                    height: "3em",
                    fontSize: "8px",
                  },
                }}
                variant="outlined"
              >
                {project.by}
              </Button>
            </Box>
          </Box>
        );
      })}
    </Slider>
  );
};
