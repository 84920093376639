import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "./faq.css";

import AddIcon from "@mui/icons-material/Add";

const CollapsiblePanel = ({ title, content }) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Accordion
      sx={{
        width: "100%",
        border: "none",
        backgroundColor: "black",
        color: "#ffffff",
        borderBottom: "1px solid #ffffff",
        borderRadius: "0 !important",
      }}
    >
      <AccordionSummary expandIcon={<AddIcon sx={{ color: "#ffffff" }} />}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Bebas Neue",
            fontSize: "22px",
            fontWeight: "400",
            lineHeight: "34px",
            letterSpacing: "0em",
            textAlign: "left",
            ...(isMobile && {
                fontSize: "15px",
            }),
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ display: "flex", justifyContent: "flex-start" }}>
        <Typography>{content}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default CollapsiblePanel;
