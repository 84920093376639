import { useTheme } from "@emotion/react";
import { Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import { BackButton, Header } from "../components";
import "../components/shared.css";
import LookupTable from "../constants/lookup_table.json";
import { NFTCollectionContext } from "../context/NFTCollectionContext";
import { useReclaimToken } from "../hooks/useReclaimToken";
const Minting = () => {
  const { palette, ...theme } = useTheme();
  const { id } = useParams();
  const isMobile = useMediaQuery("(max-width:599px)");
  const navigate = useNavigate();
  const { reclaim, isLoading, isError, error } = useReclaimToken();
  const { updateNFTStatus } = useContext(NFTCollectionContext);
  const handleMint = async () => {
    // const nft = ownedNFTs.find((n) => n.tokenId === id);
    // if (!nft) {
    //   console.error('NFT not found');
    //   return;
    // }

    // if (
    //   nft.canMintSoulboundToken == false &&
    //   nft.canReclaimSoulboundToken == false
    // ) {
    //   return;
    // }

    // // Determine if we are minting or reclaiming
    // const isMinting = nft.canMintSoulboundToken;
    const result = await reclaim(id, true);

    if (result.success) {
      // Update NFT status in context
      const newStatus = {
        canMintSoulboundToken: false,
        canReclaimSoulboundToken: false,
      };
      console.log(result);
    }
  };
  return (
    <Box
      sx={{
        zIndex: 1000,
        height: "100vh",
        overflow: "hidden",
        background:
          "url('https://ishrostorage.blob.core.windows.net/mutant-3d/home-banner.png')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        [theme.breakpoints.down("sm")]: {
          height: "100%",
        },
      }}
    >
      <Header hamburger={true} />
      {!isMobile && (
        <div
          style={{
            position: "absolute",
            top: "7vw",
            left: "2vw",
            [theme.breakpoints.down("sm")]: {
              top: "15vw",
            },
          }}
        >
          <BackButton
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          height: "70vh",
          marginInline: "auto",
          width: "95vw",
          mt: "13vh",
          flexDirection: "column",
          [theme.breakpoints.down("sm")]: {
            mt: "10vh",
            height: "70vh",
          },
        }}
      >
        {isMobile ? (
          <img
            width={"90%"}
            style={{ borderRadius: "20px", border: "1px solid #94E16140" }}
            src={LookupTable[id]?.prerenderURL}
          />
        ) : (
          <img
            height={"70%"}
            style={{ borderRadius: "20px", border: "1px solid #94E16140" }}
            src={LookupTable[id]?.prerenderURL}
          />
        )}
        <Box
          sx={{
            cursor: "pointer",
            margin: "0.5vw",
            [theme.breakpoints.down("sm")]: {
              margin: "16px",
            },
          }}
          onClick={() =>
            // handleMint()
            navigate(`/${id}`)
          }
        >
          <Box
            sx={{
              width: "16vw",
              border: "1px solid #1e2e13",
              borderRadius: "10px",
              height: "55px",
              alignItems: "center",
              backdropFilter: "blur(6px)",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              background: "",
              color: "white",
              "&:hover": {
                color: "#000",
                background: "#94E161",
              },
              [theme.breakpoints.down("sm")]: {
                width: "90%",
                marginBottom: "25px",
                maxWidth: "180px",
                minWidth: "130px",
                height: "45px",
                borderRadius: "8px",
              },
            }}
          >
            <Typography
              sx={{
                fontWeight: "700",
                fontFamily: "var(--font-f_r)",
                fontSize: "1vw",
                textTransform: "capitalize",
                textAlign: "center",
                [theme.breakpoints.down("lg")]: {
                  fontSize: "16px",
                },
                [theme.breakpoints.down("sm")]: {
                  fontSize: "12px",
                },
              }}
            >
              Claim your 3D hound
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Minting;
