import backSvg from "./icons/back.svg";
import etherScan from "./icons/etherScan.svg";
import ship from "./icons/ship.svg";
import dashboard from "./icons/dashboard/dashboard.svg";
import DTools from "./icons/dashboard/2DTools.svg";
import Analytics from "./icons/dashboard/Analytics.svg";
import ARService from "./icons/dashboard/ARService.svg";
import BrandMaker from "./icons/dashboard/BrandMaker.svg";
import Merchandise from "./icons/dashboard/Merchandise.svg";
import MetaGameVR from "./icons/dashboard/MetaGameVR.svg";
import NFTvoiceassistant from "./icons/dashboard/NFTvoiceassistant.svg";
import StoryBuilder from "./icons/dashboard/StoryBuilder.svg";
import StreamCompanion from "./icons/dashboard/StreamCompanion.svg";
import CustomArrowDown from "./icons/customArrowDown.jsx";

import EditorAdjust from "./icons/editor/EditorAdjust.jsx";
import EditorCrop from "./icons/editor/EditorCrop.jsx";
import EditorEffect from "./icons/editor/EditorEffect.jsx";
import EditorHome from "./icons/editor/EditorHome.jsx";
import EditorImage from "./icons/editor/EditorImage.jsx";
import EditorText from "./icons/editor/EditorText.jsx";
import EditorTrim from "./icons/editor/EditorTrim.jsx";

const imgNews1 = "";
const imgNews2 = "";
const imgNews3 = "";
const imgNews4 = "";
const imgNews5 = "";
const imgNews6 = "";
const imgNews7 = "";
const imgNews8 = "";
const imgNews9 = "";

export {
  Analytics,
  ARService,
  NFTvoiceassistant,
  CustomArrowDown,
  StoryBuilder,
  StreamCompanion,
  EditorAdjust,
  BrandMaker,
  EditorCrop,
  Merchandise,
  EditorEffect,
  MetaGameVR,
  EditorHome,
  backSvg,
  EditorImage,
  ship,
  EditorText,
  dashboard,
  EditorTrim,
  etherScan,
  imgNews1,
  imgNews2,
  DTools,
  imgNews3,
  imgNews4,
  imgNews5,
  imgNews6,
  imgNews7,
  imgNews8,
  imgNews9,
};
