import React from "react";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useLocation, Link, useNavigate } from "react-router-dom";

import CustomButton from "../Button";
import { RxCross2 } from "react-icons/rx";
import { useTheme } from "@emotion/react";
import { FaTiktok } from "react-icons/fa";
import { AiFillYoutube, AiOutlineInstagram } from "react-icons/ai";
import { RiTwitterXFill } from "react-icons/ri";
import { BsDiscord } from "react-icons/bs";
import Header from "../Header";

const Sidebar = ({ isOpen, onClose }) => {
  const location = useLocation();

  // Check if the URL contains "/projects"
  const isProjectsPage = location.pathname.includes("/projects");

  const { palette, ...theme } = useTheme();
  const navigate = useNavigate();

  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const NAVITEM = [
    // { name: "Product", link: "" },
    { name: "Home", link: "/" },
    { name: "News", link: "/" },
    { name: "Features", link: "/features" },
    // {
    //   name: isProjectsPage ? "" : "custom",
    //   link: isProjectsPage ? "" : "/projects",
    // },
    // { name: "Pricing", link: "" },
    { name: "FAQS", link: "/faq" },
    // { name: "Contact", link: "" },
  ];
  const collectionLink = [
    { name: "OATHS", link: "https://opensea.io/collection/mutant-cartel-oath" },
    {
      name: "MUTANT HOUNDS COLLARS",
      link: "https://opensea.io/collection/mutant-hound-collars",
    },
    {
      name: "MUTANT HOUNDS",
      link: "https://opensea.io/collection/mutant-hounds",
    },
    {
      name: "MUTANT HOUNDS FUSING MATERIALS",
      link: "https://opensea.io/collection/hound-materials",
    },
  ];

  const filteredNavItems = NAVITEM.filter((item) => item.name !== "");

  return (
    <Drawer
      anchor="top"
      open={isOpen}
      onClose={onClose}
      transitionDuration={1000}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      PaperProps={{
        sx: {
          backgroundColor: "#000",
          height: "100vh",
          width: "100%",
          [theme.breakpoints.down("sm")]: {
            width: "80%",
            padding: "5px",
          },
        },
      }}
    >
      <Box
        width="inherit"
        justifyItems="end"
        justifyContent="right"
        alignItems="end"
        display="flex"
        alignSelf="self-end"
        marginRight="30px"
        marginTop="30px"
      >
        <CustomButton
          sx={{
            marginInline: "20px",
            background: "#000",
            border: "1px solid #94E161",
          }}
          onClick={onClose}
          size="small"
          title="close"
          logo={<RxCross2 color={"#F85B03"} size={25} />}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "70%",
          marginInline: "auto",
          justifyContent: "space-around",
        }}
      >
        <Box
          alignSelf="self-start"
          justifyContent="left"
          display="flex"
          flexDirection="column"
          marginTop={18}
          sx={{
            width: "20%",
            [theme.breakpoints.down("sm")]: {
              marginLeft: "auto",
              marginRight: "30px",
              alignItems: "end",
              marginTop: "40px",
            },
          }}
        >
          <Typography
            sx={{
              [theme.breakpoints.down("sm")]: {
                marginBlock: "20px",
                fontSize: "22px",
              },

              fontWeight: "700",
              textAlignLast: "start",
              cursor: "pointer",
              transition: "0.3s",
              fontFamily: "var(--font-f_r)",
              fontSize: "1.5vw",
              textTransform: "capitalize",
              textAlign: "center",
              color: "#94E161",
            }}
          >
            Menu
          </Typography>
          {filteredNavItems.map((item, i) => {
            return item.link !== "" ? (
              <Link key={i} to={`${item.link}`}>
                <Typography
                  key={i}
                  sx={{
                    "&:hover": {
                      color: palette?.primary[100],
                      marginLeft: "10px",
                      textDecoration: "underline",
                    },
                    [theme.breakpoints.down("sm")]: {
                      marginBlock: "20px",
                      fontSize: "22px",
                    },

                    fontWeight: "700",
                    textAlignLast: "start",
                    cursor: "pointer",
                    marginBlock: "1vw",
                    transition: "0.3s",
                    fontFamily: "var(--font-f_r)",
                    fontSize: "2vw",
                    textTransform: "capitalize",
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  {item.name}
                </Typography>
              </Link>
            ) : (
              <Typography
                key={i}
                sx={{
                  "&:hover": {
                    color: palette?.primary[100],
                    marginLeft: "10px",
                    textDecoration: "underline",
                  },
                  [theme.breakpoints.down("sm")]: {
                    marginBlock: "20px",
                    fontSize: "22px",
                  },

                  fontWeight: "700",
                  textAlignLast: "start",
                  cursor: "pointer",
                  marginBlock: "1vw",
                  transition: "0.3s",
                  fontFamily: "var(--font-f_r)",
                  fontSize: "1.5vw",
                  textTransform: "capitalize",
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                {item.name}
              </Typography>
            );
          })}
          <CustomButton
            sx={{
              marginInline: "20px",
              display: "none",
              background:
                "linear-gradient(95deg, #B19513 -2.12%, #BF5C00 116.91%)",
              [theme.breakpoints.down("sm")]: {
                display: "flex",
                marginInline: "0px",
              },
            }}
            title="Login"
            logo={null}
          />
        </Box>
        <Box
          alignSelf="self-start"
          justifyContent="left"
          display="flex"
          flexDirection="column"
          marginTop={18}
          sx={{
            width: "80%",
            [theme.breakpoints.down("sm")]: {
              marginLeft: "auto",
              marginRight: "30px",
              alignItems: "end",
              marginTop: "40px",
            },
          }}
        >
          <Typography
            sx={{
              [theme.breakpoints.down("sm")]: {
                marginBlock: "20px",
                fontSize: "22px",
              },

              fontWeight: "700",
              textAlignLast: "start",
              cursor: "pointer",
              // marginBlock: "0.5vw",
              transition: "0.3s",
              fontFamily: "var(--font-f_r)",
              fontSize: "1.5vw",
              textTransform: "capitalize",
              textAlign: "center",
              color: "#94E161",
            }}
          >
            collections
          </Typography>
          {collectionLink.map((item, i) => {
            return item.link !== "" ? (
              <Box key={i} onClick={() => window.open(item.link, "_blank")}>
                <Typography
                  key={i}
                  sx={{
                    "&:hover": {
                      color: palette?.primary[100],
                      marginLeft: "10px",
                      textDecoration: "underline",
                    },
                    [theme.breakpoints.down("sm")]: {
                      marginBlock: "20px",
                      fontSize: "22px",
                    },

                    fontWeight: "700",
                    textAlignLast: "start",
                    cursor: "pointer",
                    marginBlock: "1vw",
                    transition: "0.3s",
                    fontFamily: "var(--font-f_r)",
                    fontSize: "2vw",
                    textTransform: "capitalize",
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  {item.name}
                </Typography>
              </Box>
            ) : (
              <Typography
                key={i}
                sx={{
                  "&:hover": {
                    color: palette?.primary[100],
                    marginLeft: "10px",
                    textDecoration: "underline",
                  },
                  [theme.breakpoints.down("sm")]: {
                    marginBlock: "20px",
                    fontSize: "22px",
                  },

                  fontWeight: "700",
                  textAlignLast: "start",
                  cursor: "pointer",
                  marginBlock: "1vw",
                  transition: "0.3s",
                  fontFamily: "var(--font-f_r)",
                  fontSize: "1.5vw",
                  textTransform: "capitalize",
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                {item.name}
              </Typography>
            );
          })}
          <CustomButton
            sx={{
              marginInline: "20px",
              display: "none",
              background:
                "linear-gradient(95deg, #B19513 -2.12%, #BF5C00 116.91%)",
              [theme.breakpoints.down("sm")]: {
                display: "flex",
                marginInline: "0px",
              },
            }}
            title="Login"
            logo={null}
          />
        </Box>
      </Box>

      <Box sx={{ width: "100%" }}>
        <Divider
          sx={{
            marginTop: "6vw",
            border: "1px solid #C4C4C4",
            [theme.breakpoints.down("sm")]: {
              display: "none",
              marginTop: "10px",
            },
          }}
        />
        <Typography
          sx={{
            fontFamily: "var(--font-i_r)",
            color: palette.font.default,
            fontSize: "1vw",
            fontWeight: "400",
            marginBlock: "10px",
            marginLeft: "3vw",
            [theme.breakpoints.down("sm")]: {
              fontSize: "12px",
            },
          }}
        >
          Follow Us
        </Typography>
        <Box
          display="flex"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            verticalAlign: "center",
            marginLeft: "3vw",
            alignItems: "flex-start",
            width: onlySmallScreen ? "20%" : "22vw",
            [theme.breakpoints.down("sm")]: {
              width: 110,
            },
          }}
          justifyContent="space-between"
        >
          <BsDiscord
            onClick={() =>
              window.open(`https://discord.com/invite/mutantcartel`, "_blank")
            }
            color={palette.font.default}
            style={{ marginRight: "20px" }}
            size={onlySmallScreen ? 15 : 25}
          />

          <RiTwitterXFill
            onClick={() =>
              window.open(`https://twitter.com/mutant_cartel`, "_blank")
            }
            color={palette.font.default}
            style={{ marginRight: "20px" }}
            size={onlySmallScreen ? 15 : 25}
          />
        </Box>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
