import React from "react";

function generateShareUrl(platform, shareUrl, title) {
  switch (platform) {
    case "twitter":
      return `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        shareUrl
      )}&text=${encodeURIComponent(title)}`;
    case "facebook":
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        shareUrl
      )}`;
    case "instagram":
      // Instagram doesn't support direct sharing via URLs.
      // You can provide a link to your Instagram profile or instruct users to share manually.
      return "https://www.instagram.com/yourprofile/";
    case "whatsapp":
      return `https://wa.me/?text=${encodeURIComponent(
        title
      )}%20${encodeURIComponent(shareUrl)}`;
    case "linkedin":
      // LinkedIn doesn't support direct sharing via URLs either.
      // You can provide a link to your LinkedIn profile or instruct users to share manually.
      return "https://www.linkedin.com/in/yourprofile/";
    default:
      return "";
  }
}

const ShareButton = ({ platform, shareUrl, title }) => {
  let imageSource;

  switch (platform) {
    case "twitter":
      imageSource =
        "https://ishrostorage.blob.core.windows.net/container-3d/socialtwitter.png"; // Replace with your Twitter image URL
      break;
    case "facebook":
      imageSource =
        "https://ishrostorage.blob.core.windows.net/container-3d/socialfacebook.png"; // Replace with your Facebook image URL
      break;
    case "whatsapp":
      imageSource =
        "https://ishrostorage.blob.core.windows.net/container-3d/socialwhatsapp.png"; // Replace with your WhatsApp image URL
      break;
    case "linkedin":
      imageSource =
        "https://ishrostorage.blob.core.windows.net/container-3d/sociallinkedin.png"; // Replace with your LinkedIn image URL
      break;
    default:
      imageSource =
        "https://ishrostorage.blob.core.windows.net/container-3d/socialinstagram.png"; // Replace with a default image URL
  }

  return (
    <a
      style={{
        padding: "5px 16px",
        display: "inline-block",
        position: "relative",
        transition: "transform 0.2s ease-in-out",
      }}
      href={generateShareUrl(platform, shareUrl, title)}
      // href={""}
      target="_blank"
      rel="noopener noreferrer"
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = "scale(1.05)";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = "scale(1)";
      }}
    >
      <img
        src={imageSource}
        alt={`Share on ${platform}`}
        style={{ display: "block", width: "1.3vw" }}
      />
    </a>
  );
};

export default ShareButton;
